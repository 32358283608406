import React, { useState, useRef } from "react";
import MainLayout from "./MainLayout";
import { Input, Button, Radio } from "semantic-ui-react";
import { AgGridReact } from "ag-grid-react";
import "./SubShippingSummary.css";
import "ag-grid-community/";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const Sub = (props: any) => {
  const width = 150;
  const fileRef = useRef<any>()
  const handleEvent = ({
    message,
    params
  }: {
    message: string;
    params: any;
  }) => {
    console.log("subshippingsummary", message, params);
    if (message === "clickMenu") {
      props.onEvent({ message, params });
    } else if (message === "clickButton" && params.name === "Upload") {
      props.onEvent({ message, params });
    } else if (message === "clickButton" && params.name === "chooseFile") {
      props.onEvent({ message, params });
    } else if (message === "clickButton" && params.name === "Clear") {
      props.onEvent({ message, params })
    }
  };

  const handleStyle = (params: any) => {
    // console.log("handleStyle params", params)
    if(params.data.upload_result === "มีแล้ว"){
      if(params.colDef.field === "upload_result" || params.colDef.field === "warning_note"){
        return {color: '#EB5757', background: '#FFDEDA'}
      }else{
        return {background: '#FFDEDA'}
      }
    }
    if(params.data.upload_result === "ยังไม่มี" && params.data.warning_note !== "-"){
      if(params.colDef.field === "upload_result"){
        return {color: '#EB5757', background: '#FFDEDA'}
      }else{
        return {background: '#FFDEDA'}
      }
    }

    // #44639
    if (params.data.warning_note === "ไม่พบ Invoice ในระบบ") {
      if(params.colDef.field === "warning_note" || params.colDef.field === "verified"){
        return {color: '#EB5757', background: '#FFDEDA'}
      }else{
        return {background: '#FFDEDA'}
      }
    }


    return null
  };

  console.log("render props.isData: ", props.isData);
  console.log("render props.esData: ", props.esData);

  return (
    <MainLayout
      onEvent={handleEvent}
      login_employee={props.login_employee}
      selectedCard="Shipping"
    >
      <div style={{ padding: "20px" }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <h1>นำเข้าไฟล์ขนส่ง(xls, xlsx, csv)</h1>
          <Button
            color="green"
            onClick={() => {
              handleEvent({
                message: "clickButton",
                params: {
                  name: "Upload"
                }
              });
            }}
          >
            ยืนยันการนำเข้าไฟล์
          </Button>
        </div>
        <div style={{ display: "flex", margin: "5px" }}>
          <Radio
            style={{ margin: "5px" }}
            label="ภายใน"
            checked={props.internal && !props.externalBack}
            onChange={() => {
              if(fileRef.current){
                fileRef.current.value = ""
              }
              props.onEvent({
                message: "toggle",
                params: { name: "internal" }
              });
            }}
          />
          <Radio
            style={{ margin: "5px" }}
            label="ภายนอก"
            checked={!props.internal && !props.externalBack}
            onChange={() => {
              if(fileRef.current){
                fileRef.current.value = ""
              }
              props.onEvent({
                message: "toggle",
                params: { name: "internal" }
              });
            }}
          />
          <Radio
            style={{ margin: "5px" }}
            label="หลักฐานขนส่งภายนอก"
            checked={props.externalBack}
            onChange={() => {
              if(fileRef.current){
                fileRef.current.value = ""
              }
              props.onEvent({
                message: "toggle",
                params: { name: "external_back" }
              });
            }}
          />
        </div>
        <br/>
        <div style={{ display: "flex" }}>
          <div></div>
          <input
            type="file"
            accept=".xlsx,.xls,.csv"
            ref={fileRef}
            onClick={(event: any) => {
              event.target.value = null
              handleEvent({ 
                message: "clickButton",
                params: { name: "Clear"}
              })
            }}
            onChange={(event: any) => {
              if (event.target.value) {
                handleEvent({
                  message: "clickButton",
                  params: {
                    name: "chooseFile",
                    value: event.target
                  }
                });
              }
            }}
          />
        </div>
      </div>
      {props.externalBack?
      (<div style={{ height: "100%", padding: "0px 20px" }}>
        <div
          className="ag-theme-alpine"
          style={{
            height: "calc(80vh - 50px)",
            width: "100%",
            marginTop: "20px"
          }}
          >
        {props.esData.invoices.length > 0 && (
          <AgGridReact
            defaultColDef={
              {resizable: true}
            }
            columnDefs={[
              { headerName: "การอัพโหลด", width: width, field: "upload_result", cellStyle: function(params){return handleStyle(params)} },
              { headerName: "แจ้งเตือน", width: width+ width/2, field: "warning_note", cellStyle: function(params){return handleStyle(params)}},
              { headerName: "No.", width: width/2, field: "number", cellStyle: function(params){return handleStyle(params)} },
              { headerName: "ตรงกับฐานข้อมูล",  width: width, field: "verified", cellStyle: function(params){return handleStyle(params)}},
              {
                headerName: "Invoice Number",
                width: width,
                field: "invoice",
                cellStyle: function(params){return handleStyle(params)}
              },
              { headerName: "Customer Name (ชื่อลูกค้า)", field: "customer", cellStyle: function(params){return handleStyle(params)} },
              { headerName: "Shipper", field: "delivery", cellStyle: function(params){return handleStyle(params)}},
              {
                headerName: "สถานที่จัดส่ง",
                field: "address",
                cellStyle: function(params){return handleStyle(params)}
              },
              { headerName: "สถานะการส่งสินค้า", field: "delivery_status", cellStyle: function(params){return handleStyle(params)}},
              { headerName: "วันที่ลูกค้าได้รับสินค้า", field: "delivery_time", cellStyle: function(params){return handleStyle(params)}},
              { headerName: "สถานะของ Invoice", field: "status_label", cellStyle: function(params){return handleStyle(params)} }, 
              { headerName: "ชนิดของ Invoice",field: "billing_type_label", cellStyle: function(params){return handleStyle(params)}},
              { headerName: "หมายเหตุ", field: "note", cellStyle: function(params){return handleStyle(params)} },
            ]}
            rowData={props.esData.invoices}
          ></AgGridReact>
        )}
      </div>
    </div>)
      : props.internal ? (
        <div style={{ height: "100%", padding: "0px 20px" }}>
          <div
            className="ag-theme-alpine"
            style={{
              height: "calc(80vh - 50px)",
              width: "100%",
              marginTop: "20px"
            }}
          >
            {props.isData.invoices.length > 0 && (
              <AgGridReact
                columnDefs={[
                  { headerName: "การอัพโหลด", width: width, field: "upload_result", cellStyle: function(params){ return handleStyle(params)} },
                  { headerName: "แจ้งเตือน", width: width+ width/2, field: "warning_note", cellStyle: function(params){ return handleStyle(params)}},
                  { headerName: "No.", width: width/2, field: "number", cellStyle: function(params){return handleStyle(params)}},
                  {
                    headerName: "ตรงกับฐานข้อมูล",
                    width: width,
                    field: "verified",
                    cellStyle: function(params){return handleStyle(params)}
                  },
                  { headerName: "Primary Key", field: "id", cellStyle: function(params){return handleStyle(params)} },
                  {
                    headerName: "Invoice Number",
                    width: width,
                    field: "invoice",
                    cellStyle: function(params){return handleStyle(params)}
                  },
                  {
                    headerName: "Customer Name",
                    width: width,
                    field: "customer",
                    cellStyle: function(params){return handleStyle(params)}
                  },
                  { headerName: "Billing Date", width: width, field: "date", cellStyle: function(params){return handleStyle(params)} },
                  { headerName: "Status", field: "status_label", cellStyle: function(params){return handleStyle(params)} },
                  {
                    headerName: "Type",
                    width: width,
                    field: "billing_type_label",
                    cellStyle: function(params){return handleStyle(params)}
                  },
                  {
                    headerName: "จำนวนกล่อง",
                    width: width,
                    field: "box_count",
                    cellStyle: function(params){return handleStyle(params)}
                  },
                  {
                    headerName: "Shipper",
                    width: width*2,
                    field: "transport_name",
                    cellStyle: function(params){return handleStyle(params)}
                  },
                  { headerName: "Cost",  width: width, field: "cost_per_box", cellStyle: function(params){return handleStyle(params)} },
                  { headerName: "หมายเหตุ",  width: width, field: "note", cellStyle: function(params){return handleStyle(params)} },
                ]}
                rowData={props.isData.invoices}
              ></AgGridReact>
            )}
          </div>
        </div>
      ) : (
        <div style={{ height: "100%", padding: "0px 20px" }}>
          <div><b style={{ fontSize: "20px" }}>Shipping Company :</b> {props.esData.shipping_company}</div>
          {/* <div><b style={{ fontSize: "20px" }}>Shipper :</b> {props.esData.shipping_company}</div> */}
          <div
            className="ag-theme-alpine"
            style={{
              height: "calc(80vh - 50px)",
              width: "100%",
              marginTop: "20px",
            }}
          >
            {props.esData.invoices.length > 0 && (
              <AgGridReact
                defaultColDef={
                  {resizable: true}
                }
                columnDefs={[
                  { headerName: "การอัพโหลด", width: width, field: "upload_result", cellStyle: function(params){return handleStyle(params)} },
                  { headerName: "แจ้งเตือน", width: width+ width/2, field: "warning_note", cellStyle: function(params){return handleStyle(params)}},
                  { headerName: "No.", width: width/2, field: "number", cellStyle: function(params){return handleStyle(params)} },
                  { headerName: "ตรงกับฐานข้อมูล",  width: width, field: "verified", cellStyle: function(params){return handleStyle(params)}},
                  {
                    headerName: "Invoice Number",
                    width: width,
                    field: "invoice",
                    cellStyle: function(params){return handleStyle(params)}
                  },
                  { headerName: "Customer Name (ชื่อลูกค้า)", field: "customer", cellStyle: function(params){return handleStyle(params)} },
                  { headerName: "Shipper", field: "shipper", cellStyle: function(params){return handleStyle(params)}},
                  { headerName: "Delivery No.", width: width, field: "delivery", cellStyle: function(params){return handleStyle(params)} },
                  {
                    headerName: "สถานที่จัดส่ง",
                    field: "address",
                    cellStyle: function(params){return handleStyle(params)}
                  },
                  { headerName: "วันที่ออกเดินทาง", field: "sending_date", cellStyle: function(params){return handleStyle(params)}},
                  { headerName: "Status", field: "status_label", cellStyle: function(params){return handleStyle(params)} }, 
                  { headerName: "Type",field: "billing_type_label", cellStyle: function(params){return handleStyle(params)}},
                  {
                    headerName: "จำนวนกล่อง",
                    field: "box_count",
                    cellStyle: function(params){return handleStyle(params)}
                  },
                  { headerName: "Cost",  width: width, field: "cost_per_box", cellStyle: function(params){return handleStyle(params)} },
                  { headerName: "หมายเหตุ", field: "note", cellStyle: function(params){return handleStyle(params)} },
                ]}
                rowData={props.esData.invoices}
              ></AgGridReact>
            )}
          </div>
        </div>
      )}
    </MainLayout>
  );
};

export default Sub;

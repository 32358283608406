import React from "react";
import { withCookies } from "react-cookie";
import { Input, Button, Icon } from "semantic-ui-react";

import config from "../../config/config";
import styles from "./INTVerifySuccess.module.css";

const MPC = "/static/images/logo_mpc.png";
const ANB ="/static/images/logo_anb.png";

export interface INTVerifySuccessViewDataType {
  Username: string;
  UsernameDisabled: boolean;
  EmployeeNumber: string;
  EmployeeNumberDisabled: boolean;
  Password: string;
  PasswordDisabled: boolean;
  hidePassword: boolean;
  ConfirmPassword: string;
  ConfirmPasswordDisabled: boolean;
  hideConfirmPassword: boolean;
  showPasswordValidationText: boolean;
}

export const INTVerifySuccessInitialViewData: INTVerifySuccessViewDataType = {
  Username: "",
  UsernameDisabled: false,
  EmployeeNumber: "",
  EmployeeNumberDisabled: false,
  Password: "",
  PasswordDisabled: false,
  hidePassword: true,
  ConfirmPassword: "",
  ConfirmPasswordDisabled: false,
  hideConfirmPassword: true,
  showPasswordValidationText: true,
};

const Sub = (props: any) => {
  const children = React.Children.toArray(props.children);
  return (
    <div className={styles.container}>
      <div className={styles.Item1}>
        <div style={{ height: "100%", width: "100%" }}>
          <img style={{ height: "100%" }} src={props.cookies.get('hostName') === "ANB"? `${process.env.PUBLIC_URL + ANB}` : `${process.env.PUBLIC_URL + MPC}`} />
        </div>
      </div>
      <div className={styles.Item2}>
        <div style={{ color: "#00529C", height: "100%", width: "100%" }}>
          <h4>
            <strong>ลงทะเบียนระบบ Invoice Tracking</strong>
          </h4>
        </div>
      </div>
      <div className={styles.Username}>
        <Input
          value={props.viewData.Username}
          disabled={props.viewData.UsernameDisabled}
          onChange={(e) => {
            props.onEvent({
              message: "inputChange",
              params: {
                view: "INTVerifySuccess",
                name: "Username",
                value: e.target.value,
              },
            });
          }}
          style={{ height: "100%", width: "100%" }}
        ></Input>
      </div>
      <div className={styles.Item7}>
        <div style={{ color: "#00529C", height: "100%", width: "100%" }}>
          <p>
            <strong>Username * :</strong>
          </p>
        </div>
      </div>
      <div className={styles.EmployeeNumber}>
        <Input
          value={props.viewData.EmployeeNumber}
          disabled={props.viewData.EmployeeNumberDisabled}
          onChange={(e) => {
            props.onEvent({
              message: "inputChange",
              params: {
                view: "INTVerifySuccess",
                name: "EmployeeNumber",
                value: e.target.value,
              },
            });
          }}
          style={{ height: "100%", width: "100%" }}
        ></Input>
      </div>
      <div className={styles.Item11}>
        <div style={{ color: "#00529C", height: "100%", width: "100%" }}>
          <p>
            <strong>รหัสพนักงาน * :</strong>
          </p>
        </div>
      </div>
      <div className={styles.Item13}>
        <Button
          disabled={props.viewData.Item13Disabled}
          onClick={(e) => {
            props.onEvent({
              message: "clickButton",
              params: {
                view: "INTVerifySuccess",
                name: "Item13",
              },
            });
          }}
          style={{
            backgroundColor: "#39B54A",
            color: "White",
            height: "100%",
            width: "100%",
          }}
        >
          ตรวจสอบข้อมูลสำเร็จ
        </Button>
      </div>
      <div className={styles.Item15}>
        <div
          style={{
            backgroundColor: "rgba(40,134,189,0.15)",
            height: "100%",
            width: "100%",
          }}
        >
          <p></p>
        </div>
      </div>
      <div className={styles.Item16}>
        <div style={{ color: "#00529C", height: "100%", width: "100%" }}>
          <h4>
            <strong>กรุณาตั้งรหัสผ่าน</strong>
          </h4>
        </div>
      </div>
      <div className={styles.Item17}>
        {props.showPasswordValidationText ? <div style={{ color: "#EB5757", height: "100%", width: "100%" }}>
          <p>
            กรุณาระบุรหัสผ่าน เป็นตัวอักษรภาษาอังกฤษตัวใหญ่ ตัวเล็ก และตัวเลข
            ความยาวไม่น้อยกว่า 8 ตัวอักษร
          </p>
        </div> : null}
      </div>
      <div className={styles.Password}>
        <Input
          value={props.viewData.Password}
          disabled={props.viewData.PasswordDisabled}
          onChange={(e) => {
            props.onEvent({
              message: "inputChange",
              params: {
                view: "INTVerifySuccess",
                name: "Password",
                value: e.target.value,
              },
            });
          }}
          style={{ backgroundColor: "White", height: "100%", width: "100%" }}
        >
          <input type={ props.viewData.hidePassword ? "password" : "input" } />
        </Input>
      </div>
      <div className={styles.Item25}>
        <div style={{ height: "100%", width: "100%" }}>
          <p>
            <strong>รหัสผ่าน<span style={{ color: "red" }}>*</span>:</strong>
          </p>
        </div>
      </div>
      <div className={styles.Item26}>
        <Button
          disabled={props.viewData.Item26Disabled}
          onClick={(e) => {
            props.onEvent({
              message: "clickButton",
              params: {
                view: "INTVerifySuccess",
                name: "toggleHidePassword",
              },
            });
          }}
          style={{
            backgroundColor: "rgba(40,134,189,0)",
            height: "100%",
            width: "100%",
            padding:0
          }}
        >
          <Icon name={ props.viewData.hidePassword ? "hide" : "unhide"} style={{padding:0}}/>
        </Button>
      </div>
      <div className={styles.ConfirmPassword}>
        <Input
          value={props.viewData.ConfirmPassword}
          disabled={props.viewData.ConfirmPasswordDisabled}
          onChange={(e) => {
            props.onEvent({
              message: "inputChange",
              params: {
                view: "INTVerifySuccess",
                name: "ConfirmPassword",
                value: e.target.value,
              },
            });
          }}
          style={{ backgroundColor: "White", height: "100%", width: "100%" }}
        >
          <input type={ props.viewData.hideConfirmPassword ? "password" : "input" }  />
        </Input>
      </div>
      <div className={styles.Item29}>
        <div style={{ height: "100%", width: "100%" }}>
          <p>
            <strong>ยืนยันรหัสผ่าน<span style={{ color: "red" }}>*</span>:</strong>
          </p>
        </div>
      </div>
      <div className={styles.Item30}>
        <Button
          disabled={props.viewData.Item30Disabled}
          onClick={(e) => {
            props.onEvent({
              message: "clickButton",
              params: {
                view: "INTVerifySuccess",
                name: "toggleHideConfirmPassword"
              },
            });
          }}
          style={{
            backgroundColor: "rgba(40,134,189,0)",
            height: "100%",
            width: "100%",
            padding:0,
          }}
        >
          <Icon name={ props.viewData.hideConfirmPassword ? "hide" : "unhide" }/>
        </Button>
      </div>
      <div className={styles.SetPassword}>
        <Button
          disabled={props.viewData.SetPasswordDisabled}
          onClick={(e) => {
            props.onEvent({
              message: "clickButton",
              params: {
                view: "INTVerifySuccess",
                name: "SetPassword",
              },
            });
          }}
          style={{
            backgroundColor: "#00529C",
            color: "White",
            height: "100%",
            width: "100%",
          }}
        >
          ยืนยันการลงทะเบียน
        </Button>
      </div>
    </div>
  );
};

export default withCookies(Sub);

const getTsName = (cssClass: string) => {
  return cssClass
    .split("-")
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join("");
};

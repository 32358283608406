import axios from 'axios'
import { to } from '../../../../utils'
import BaseService from  '../../../services/BaseService'
import invSettings from '../../../../../config/config';
import { Response, Error } from '../../common'

export interface coreEmployeeSerializerForLIS {
    id?: any,
    code?: any,
    first_name?: any,
    last_name?: any,
    position?: any,
    licence?: any,
    organization?: any,
    personnel_no?: any,
    citizen_no?: any,
    job_description?: any,
    flag_start_date?: any,
    flag_end_date?: any,
    status?: any,
    birth_date?: any,
    user_id?: any,
    title?: any,
}


/* ['generics.RetrieveAPIView'] */
/* params:  */
/* data:  */
export const coreEmployeeDetailByUsername : 
{
  retrieve: ({username, params, apiToken, extra}:
             { username: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({username, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${invSettings.API_HOST}/apis/core/get-employee/${username}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface EmployeeSerializer {
    id?: any,
    code?: any,
    first_name?: any,
    last_name?: any,
    position?: any,
    licence?: any,
    organization?: any,
    personnel_no?: any,
    citizen_no?: any,
    job_description?: any,
    flag_start_date?: any,
    flag_end_date?: any,
    status?: any,
    birth_date?: any,
    name_code?: any,
}


/* ['generics.RetrieveAPIView'] */
/* params:  */
/* data:  */
export const EmployeeDetail : 
{
  retrieve: ({username, params, apiToken, extra}:
             { username: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({username, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${invSettings.API_HOST}/apis/users/apis/users/employee/from-username/(?P<username>.+)/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/

export interface HRMEmployeeSerializer {
    id?: any,
    code?: any,
    first_name?: any,
    last_name?: any,
    position?: any,
    licence?: any,
    organization?: any,
    personnel_no?: any,
    citizen_no?: any,
    job_description?: any,
    flag_start_date?: any,
    flag_end_date?: any,
    status?: any,
    birth_date?: any,
    full_name?: any,
    full_birth_date?: any,
    division_name?: any,
    email?: any,
    client_id?: any,
}

/*----------------------------------------------------------------------------------------------------*/

export const EmployeeDetailFromCode : 
{
  retrieve: ({code, params, apiToken, extra}:
             { code: any, 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({code, params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${invSettings.API_HOST}/apis/TRK/employee/from-code/${code}/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Button,
  TextArea,
  Input,
  Dimmer,
  Loader
} from "semantic-ui-react";
import {
  EmployeeList,
  EmployeeSerializer,
  EmployeeCodeCheck
} from "../../react-lib/apis/issara/apps/TRK/INVEmployeeExtra";
import { EmployeeDetailFromCode } from "../../react-lib/apis/issara/apps/TRK/INVcommon";
import ErrorMessage from "../../react-lib/apps/common/ErrorMessage";
import * as CONSTANT from "../../react-lib/utils/constant";
import {
  InvoiceList,
  InvoiceDetail,
  InvoiceSerializer
} from "../../react-lib/apis/issara/apps/TRK/INVInvoice";
import "./ModEditSale.css";

const ModEditSale = (props: any) => {
  const [employeeCodeLoading, setEmployeeCodeLoading] = useState(false);
  const [employeeCode, setEmployeeCode] = useState("");
  const [employeeCodeError, setEmployeeCodeError] = useState(null);
  const [employeeName, setEmployeeName] = useState("");
  const [saleId, setSaleId] = useState(null);
  const [reason, setReason] = useState("");
  const [editUser, setEditUser] = useState(null);
  const [editUserName, setEditUserName] = useState("");
  const [editUserCode, setEditUserCode] = useState("");
  const [editUserCodeLoading, setEditUserCodeLoading] = useState(false);
  const [editUserCodeError, setEditUserCodeError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [typingTimeout, setTypingTimeOut] = useState(0);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    clear()
  }, [props.open])

  const clear = () => {
    setEmployeeCode("")
    setEmployeeCodeError(null)
    setEmployeeName("")
    setSaleId(null)
    setReason("")
    setEditUser(null)
    setEditUserName("")
    setEditUserCode("")
    setEditUserCodeError(null)
    setError(null)
  }

  const handleAutoGetEmployeeCode = (e:any) => {
    let code = e.target.value
    if (typingTimeout) {
      window.clearTimeout(typingTimeout);
    }
    setTypingTimeOut(
      window.setTimeout(() => {
        handleSearchEmployee(code)
      }, 1000)
    );
  };

  const handleSearchEmployee = async (code: string) => {
    setEmployeeCodeLoading(true);
    setEmployeeCodeError(null);
    const [res, err, network] = await EmployeeDetailFromCode.retrieve({
      code: code,
      apiToken: props.apiToken
    });
    if (isMounted.current) {
      setEmployeeCodeLoading(false);
      if (err) {
        let errText = err;
        if (err.length > 200) {
          errText = `${network.response.status}: ${network.response.statusText}`;
        }
        setEmployeeCodeError(errText);
      } else {
        setEmployeeName(`${res.first_name} ${res.last_name}`);
        setSaleId(res.id);
      }
    }
  };

  const handleAutoGetEditUserCode = (e:any) => {
    let code = e.target.value
    if (typingTimeout) {
      window.clearTimeout(typingTimeout);
    }
    setTypingTimeOut(
      window.setTimeout(() => {
        handleSearchEditUser(code)
      }, 1000)
    );
  };

  const handleSearchEditUser = async (code: string) => {
    setEditUserCodeLoading(true);
    setEditUserCodeError(null);
    const [res, err, network] = await EmployeeDetailFromCode.retrieve({
      code: code,
      apiToken: props.apiToken
    });
    if (isMounted.current) {
      setEditUserCodeLoading(false);
      if (err) {
        let errText = err;
        if (err.length > 200) {
          errText = `${network.response.status}: ${network.response.statusText}`;
        }
        setEditUserCodeError(errText);
      } else {
        setEditUserName(`${res.first_name} ${res.last_name}`);
        setEditUser(res.id);
      }
    }
  };

  const handleSaveEditSale = async () => {
    setIsLoading(true);
    const [res, err, network] = await InvoiceDetail.patch({
      pk: props.invoice.id,
      apiToken: props.apiToken,
      data: {
        sale: saleId,
        reason,
        edit_user: editUser,
        action: "INFO"
      }
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (err) {
        let errText = err;
        if (err.length > 200) {
          errText = `${network.response.status}: ${network.response.statusText}`;
        }
        setError(errText);
      } else {
        props.onSuccess();
      }
    }
  };

  return (
    <Modal
      className="mod-edit-sale"
      size="tiny"
      open={props.open}
      closeOnDimmerClick={false}
      onClose={props.onClose}
    >
      <Dimmer.Dimmable>
        <Dimmer active={isLoading} inverted>
          <Loader inverted />
        </Dimmer>
        <div>
          <h2>{`แก้ไข Sale Invoice No. ${props.invoice.serial}`}</h2>
          <ErrorMessage error={error} />
          <div className="row">
            <div className="key-field">
              Sale code<span style={{ color: "red" }}>*</span>:
            </div>
            <div className="input-field">
              <Input
                icon="search"
                error={!!employeeCodeError}
                loading={employeeCodeLoading}
                value={employeeName ? employeeName : employeeCode}
                onChange={e => {
                  setEmployeeName("");
                  setSaleId(null);
                  setEmployeeCode(e.target.value);
                  handleAutoGetEmployeeCode(e);
                }}
                onKeyDown={(e: any) => {
                  if (e.keyCode === CONSTANT.KEY_CODE.ENTER) {
                    handleSearchEmployee(e.target.value);
                  }
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="key-field reason">
              เหตุผล<span style={{ color: "red" }}>*</span>:
            </div>
            <div className="input-field">
              <TextArea
                rows={4}
                onChange={(e, { value }) => {
                  setReason(value as string);
                }}
                value={reason}
              />
            </div>
          </div>
          <div className="row">
            <div className="key-field">
              รหัสผู้แก้ไข<span style={{ color: "red" }}>*</span>:
            </div>
            <div className="input-field">
              <Input
                icon="search"
                error={!!editUserCodeError}
                loading={editUserCodeLoading}
                type={editUserName.length > 0 ? "" : "password"}
                value={editUserName ? editUserName : editUserCode}
                onChange={e => {
                  setEditUserName("");
                  setEditUser(null);
                  setEditUserCode(e.target.value);
                  handleAutoGetEditUserCode(e)
                }}
                onKeyDown={(e: any) => {
                  setEditUserName("");
                  if (e.keyCode === CONSTANT.KEY_CODE.ENTER) {
                    handleSearchEditUser(e.target.value);
                  }
                }}
              />
            </div>
          </div>
          <div className="last row">
            <Button color="red" onClick={props.onClose}>
              ยกเลิก
            </Button>
            <Button
              color="green"
              disabled={!saleId || !reason || !editUser}
              onClick={handleSaveEditSale}
            >
              บันทึก
            </Button>
          </div>
        </div>
      </Dimmer.Dimmable>
    </Modal>
  );
};

ModEditSale.defaultProps = {
  open: false,
  invoice: {},
  onClose: () => {}
};

export default React.memo(ModEditSale);

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Main from './Main/Main.tsx'
import * as serviceWorker from './serviceWorker';
import * as Sentry from "@sentry/react";


Sentry.init({ dsn: "http://39d7c3cc8c64418591d7b8832c9b3305@sentry.drjaysayhi.com/16" });

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={"An error has occurred"}>
      <Main />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

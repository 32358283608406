import React, { useState, useEffect, useRef } from "react";
import { Modal } from "semantic-ui-react";
import { InvoiceActionLogList } from "../../react-lib/apis/issara/apps/TRK/INVcommon";
import moment from "moment";
import "./ModInvoiceActionLog.css";

const ModInvoiceActionLogList = (props: any) => {
  const [actionLog, setActionLog] = useState([]);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    clear();
  }, [props.open]);

  useEffect(() => {
    if (props.invoice.id && props.open) {
      handleGetInvoiceActionLog();
    }
  }, [props.invoice, props.open]);

  const handleGetInvoiceActionLog = async () => {
    const [res, err, network] = await InvoiceActionLogList.retrieve({
      apiToken: props.apiToken,
      params: {
        invoice: props.invoice.id
      }
    });
    if (isMounted.current) {
      if (res && res.items) {
        setActionLog(res.items);
      } else {
        setActionLog([]);
      }
    }
  };

  const clear = () => {};

  const createActionLog = () => {
    return actionLog.map((item: any, index) => {
      return (
        <tr key={item.id}>
          <td>{moment(item.datetime).format("DD/MM/YYYY HH:mm")}</td>
          <td>{item.action_label}</td>
          <td>{item.user_full_name}</td>
          <td className="note">{item.note}</td>
        </tr>
      );
    });
  };

  return (
    <Modal
      className="mod-invoice-action-log"
      closeIcon
      open={props.open}
      onClose={props.onClose}
    >
      <div>
        <h1>ข้อมูลการแก้ไข</h1>
        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                <th>วัน/เวลาที่แก้ไข</th>
                <th>action</th>
                <th>ผู้แก้ไข</th>
                <th>Note</th>
              </tr>
            </thead>
            <tbody>{createActionLog()}</tbody>
          </table>
        </div>
      </div>
    </Modal>
  );
};

ModInvoiceActionLogList.defaultProps = {
  open: false,
  onClose: () => {}
};

export default React.memo(ModInvoiceActionLogList);

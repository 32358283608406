import React, { useState, useEffect, useRef, SyntheticEvent } from "react";
import {
  Modal,
  Grid,
  Image,
  Segment,
  Form,
  Dropdown,
  Dimmer,
  Loader,
  DropdownProps,
  Icon,
} from "semantic-ui-react";
import axios from "axios";
import {
  InvoiceDocumentImageList,
  invoiceRelatedDocumentTypeChoice,
} from "../../react-lib/apis/issara/apps/TRK/INVcommon";
import ErrorMessage from "../../react-lib/apps/common/ErrorMessage";
import "./ModInvoiceDocumentImageList.css";
import moment from "moment";
import { DOC_NAME_ALL, OTHER_DOC_NAME_ALL, MAPDOCTYPE } from "../../Common";
import Iframe from "react-lib/apps/common/CustomIframe";
import config from "../../config/config";

const styles = {
  docTable: {
    height: "250px",
  },
  segment: {
    height: "600px",
    overflowY: "auto",
    display: "flex",
    justifyContent: "center",
  },
  image: { cursor: "zoom-in", height: "500px" },
};

const ModInvoiceDocumentImageList = (props: any) => {
  // console.log(" ModInvoiceDocumentImageList props" , props)
  const [imageList, setImageList] = useState<any>([]);
  const [image, setImage] = useState("");
  const [activeIndex, setActiveIndex] = useState<any>(null);
  const [documentOptions, setDocumentOptions] = useState<any[]>([]);
  const [documentId, setDocumentId] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const iframeRef = useRef<any>();
  const isMounted = useRef(true);
  const [typeChoice, setTypeChoice] = useState<
    { key: number; text: string; value: number }[]
  >([]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const getInvoiceRelatedDocumentTypeChoice = async () => {
      const [r, e, n] = await invoiceRelatedDocumentTypeChoice.retrieve({
        apiToken: props.apiToken,
      });
      if (e) {
        console.log("e", e);
        console.log("n", n);
        // break
      } else {
        console.log("invoiceRelatedDocumentTypeChoice", r);
        setTypeChoice(r);
        //
      }
    };

    getInvoiceRelatedDocumentTypeChoice();
    handleCreateDocumentOptions();

    if (!props.open) {
      clear();
    }
  }, [props.open]);

  useEffect(() => {
    // console.log(" useEffect documentId: ", documentId)
    if (props.invoice.id && props.open && documentId) {
      handleGetInvoiceDocumentImageList();
    }
  }, [props.invoice, props.open, documentId]);

  const getTitleDocument = (seq: number, docType: string): string => {
    if (seq <= 10) {
      return DOC_NAME_ALL[seq.toString()];
    } else {
      return OTHER_DOC_NAME_ALL[docType];
    }
  };

  const getDocTypeIdFrom = (docTypeName: string): number => {
    if (docTypeName == "PAYMENT") {
      return 6;
    } else if (docTypeName == "BILL") {
      return 3;
    }
    return typeChoice.find((item) => item.text === docTypeName)?.value || 0;
  };

  const handleCreateDocumentOptions = () => {
    // console.log('handleCreateDocumentOptions:props.invoice.related_documents ', props.invoice.related_documents);
    let newArr = [];
    if (
      props.invoice &&
      props.invoice.related_documents &&
      props.invoice.related_documents.length > 0
    ) {
      newArr = props.invoice.related_documents.map((item: any) => {
        return {
          key: item.id,
          value: item.id,
          text: getTitleDocument(item.seq, item.doc_type),
        };
      });

      setDocumentId(newArr[0].key);
      // console.log(" newArr", newArr , "newArr[0].key : ", newArr[0].key)
    }
    setDocumentOptions(newArr);
  };

  const handleGetInvoiceDocumentImageList = async () => {
    if (props.invoice && props.invoice.related_documents) {
      setImage("");
      setIsLoading(true);
      setError(null);

      let doc = props.invoice.related_documents.find(
        (item: any) => item.id === documentId
      );
      if (doc) {
        const [res, err, network] = await InvoiceDocumentImageList.retrieve({
          apiToken: props.apiToken,
          params: {
            invoice: props.invoice.id,
            document_no: doc.seq,
            doc_type: getDocTypeIdFrom(doc.doc_type),
          },
        });
        if (isMounted.current) {
          setIsLoading(false);
          if (res && res.items) {
            setImageList(res.items);
          } else {
            if (err.length > 200) {
              setError(
                `${network.response.status}: ${network.response.statusText}`
              );
            } else {
              setError(err);
            }
            setImageList([]);
          }
        }
      }
    }
  };

  const clear = () => {
    setImage("");
    setImageList([]);
    setActiveIndex(null);
    setError(null);
  };

  const handleDownload = (doc_image: string) => {
    console.log(config, "config");
    let doc = doc_image.split("/");
    if (doc && doc.length > 0) {
      var canvas = document.createElement("canvas");
      // document.body.appendChild(canvas);
      var imgs = document.createElement("img");
      imgs.setAttribute('crossorigin', 'anonymous');
      // document.body.appendChild(imgs);
      imgs.onload = async() => {
        let ctx = canvas.getContext("2d");
        canvas.width = imgs.width;
        canvas.height = imgs.height;
        ctx?.drawImage(imgs, 0, 0);

        const url = canvas.toDataURL("image/jpeg");
        var link = document.createElement("a");
        link.download = doc[doc.length - 1];
        link.href = url;
        // document.body.appendChild(link);
        link.click();
      };
      imgs.src = image;

      // fetch(
      //   doc_image,
      //   {referrerPolicy: "origin"}
      //   )
      //   .then(response => {
      //     return response.blob();
      //   })
      //   .then(myBlob => {
      //     // console.log("response", doc_image)=
      //     const url = window.URL.createObjectURL(myBlob);
      //     // console.log("url", url)
      //     const link = document.createElement("a");
      //     // console.log("link", link)
      //     link.href = url;
      //     link.setAttribute("download", doc[doc.length - 1]);
      //     document.body.appendChild(link);
      //     link.click();
      //   })
      //   .catch(error => {
      //     // console.log('error', error)
      //     console.error("error.res", error.response);
      //     alert("Error download file see console.error");
      //   });
    }
  };

  const createTableBody = () => {
    if (imageList.length === 0) {
      return (
        <tr className="no-image">
          <td>ไม่มีรูปเอกสาร</td>
        </tr>
      );
    }
    return imageList.map((item: any, index: number) => {
      return (
        <tr className={`${activeIndex === index ? "active" : ""}`}>
          <td
            onClick={() => {
              setActiveIndex(index);
              setImage(item.document_image);
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{`${item.serial} ${item.user_full_name}, ${item.status_label}`}</div>
              <div>
                {`${
                  item.created
                    ? moment(item.created).format("DD/MM/YYYY, HH:mm")
                    : ""
                }`}
                <span style={{ paddingLeft: "10px" }}>
                  <Icon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleDownload(item.document_image);
                    }}
                    name="download"
                    color="blue"
                  />
                </span>
              </div>
            </div>
          </td>
        </tr>
      );
    });
  };

  const handleOnChangeDropdown = (
    e: SyntheticEvent,
    { value }: DropdownProps
  ) => {
    setDocumentId(value);
  };

  useEffect(() => {
    if (iframeRef.current && iframeRef.current.iframeRef) {
      let img = iframeRef.current.iframeRef.contentDocument.getElementsByTagName(
        "img"
      )[0];
      if (img) {
        img.style.width = "100%";
        img.style.height = "auto";
      }
    }
  }, [iframeRef.current]);

  console.log("Mod Image:", iframeRef.current);
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      style={{ width: "70%" }}
      closeIcon
      className="mod-invoice-document-image-list"
    >
      <div style={{ padding: "10px" }}>
        <h1>ดูรูปเอกสาร</h1>
        <ErrorMessage error={error} />
        <br />
        <Grid style={{ height: "650px" }}>
          <Grid.Column width={8}>
            <Grid columns={3}>
              <Form>
                <Form.Group inline>
                  <Form.Field>Invoice No. :</Form.Field>
                  <Form.Field className="serial">
                    {props.invoice.serial}
                  </Form.Field>
                  <Form.Field>Document No. :</Form.Field>
                  <Form.Field>
                    <Dropdown
                      disabled={isLoading}
                      search
                      selection
                      options={documentOptions}
                      value={documentId}
                      onChange={handleOnChangeDropdown}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
              <div className="table-wrapper">
                <Dimmer.Dimmable>
                  <Dimmer active={isLoading} inverted>
                    <Loader inverted />
                  </Dimmer>
                  <table>
                    <thead>
                      <tr>
                        <th>Document Image List</th>
                      </tr>
                    </thead>
                    <tbody>{createTableBody()} </tbody>
                  </table>
                </Dimmer.Dimmable>
              </div>
            </Grid>
          </Grid.Column>
          <Grid.Column width={8}>
            {" "}
            <Segment color="grey" inverted style={styles.segment}>
              {image && (
                <div className="zoom">
                  <Icon
                    className="icon-in"
                    name="plus"
                    size="small"
                    onClick={() => {
                      if (iframeRef.current && iframeRef.current.iframeRef) {
                        let img = iframeRef.current.iframeRef.contentDocument.getElementsByTagName(
                          "img"
                        )[0];
                        if (img) {
                          let size =
                            parseInt(img.style.width.replace("%", "")) + 10;
                          img.style.width = size.toString() + "%";
                          img.style.height = "auto";
                        }
                      }
                    }}
                  />
                  <Icon
                    className="icon-in"
                    name="minus"
                    size="small"
                    onClick={() => {
                      if (iframeRef.current && iframeRef.current.iframeRef) {
                        let img = iframeRef.current.iframeRef.contentDocument.getElementsByTagName(
                          "img"
                        )[0];
                        if (img) {
                          let size = parseInt(img.style.width.replace("%", ""));
                          if (size > 10) {
                            size = size - 10;
                          }
                          img.style.width = size.toString() + "%";
                          img.style.height = "auto";
                        }
                      }
                    }}
                  />
                </div>
              )}
              <Iframe style={{ width: "100%", height: "100%" }} ref={iframeRef}>
                {image && <img crossOrigin="anonymous" referrerPolicy="origin" src={image} />}
              </Iframe>
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
    </Modal>
  );
};

ModInvoiceDocumentImageList.defaultProps = {
  open: false,
  onClose: () => {},
  invoice: {},
};

export default React.memo(ModInvoiceDocumentImageList);

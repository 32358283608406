import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Modal,
  Dropdown,
  TextArea,
  Button,
  Input,
  Dimmer,
  Loader,
  Form,
  Icon
} from "semantic-ui-react";
import { createTrue } from "typescript";
import { STATUS_FROM_ACTION, NEED_REASON_ACTION } from "../../Common";
import {
  InvoiceList,
  InvoiceDetail,
  InvoiceSerializer
} from "../../react-lib/apis/issara/apps/TRK/INVInvoice";

import {
  InvoiceDocumentImageList, 
  invoiceRelatedDocumentTypeChoice,
  InvoiceRelatedDocumentDetail,
  InvoiceRelatedDocumentList,
} from "../../react-lib/apis/issara/apps/TRK/INVcommon"
import ErrorMessage from "../../react-lib/apps/common/ErrorMessage";
import "./ModUpdateInvoice.css";

const ModUpdateInvoice = (props: any) => {
  const [showReason, setShowReason] = useState(false);
  // const [invoiceActionOptions, setInvoiceActionOptions] = useState([]);
  const [action, setAction] = useState<string>("INFO");
  const [documentSeq, setDocumentSeq] = useState<number>(1)
  const [showImageUpload, setShowImageUpload] = useState(true)
  const [page, setPage] = useState<number>(1);
  const [remark, setRemark] = useState("");
  const [toStatus, setToStatus] = useState("SENDGOODS");
  const [pictureBase64, setPictureBase64] = useState<any>(null);

  const [typeChoice, setTypeChoice] = useState<{key:number, text: string, value: number}[]>([])
  const [documentLostOption, setDocumentLostOption] = useState(false)

  const [isLoading, setIsLoading] = useState(false);
  const [isComfirmed, setIsComfirmed] = useState(true);
  const [error, setError] = useState<string>("");

  const isMounted = useRef(true);
  const fileRef = useRef<any>(null);

  const invoiceActionValidOptions = useMemo(() => {
    return [
      { key: "INFO", value: "INFO", text: "นำเข้าเอกสาร" }, 
      { key: "LOST", value: "LOST", text: "เอกสารสูญหาย" },
      { key: "CHECK", value: "CHECK", text: "ตรวจรับเอกสาร (ไม่ต้องแนบรูป)"},
      { key: "UPDATE", value: "UPDATE", text: "อัพเดตสถานะ"}
    ]
  }, [])

  const invoiceActionLostOptions = useMemo(() => {
    return [
      { key: "FOUND", value: "FOUND", text: "พบเอกสารที่สูญหาย" },
      { key: "CHECK", value: "CHECK", text: "ตรวจรับเอกสาร (ไม่ต้องแนบรูป)"},
      { key: "UPDATE", value: "UPDATE", text: "อัพเดตสถานะ"}
    ]
  }, [])

  const invoiceStatus = useMemo(() => {
    return [
      {  key: "SENDGOODS", value: "SENDGOODS", text: " ส่งสินค้าแล้ว  "}
    ]
  }, [])

  const invoiceDocumentCheckOptions = useMemo(() => {
    return [
      { key: 1, value: 1, text: " เอกสาร Invoice ชุดที่ (1)  " },
      { key: 6, value: 6, text: " เอกสาร Invoice ชุดที่ (6)  " },
      { key: 7, value: 7, text: " เอกสาร Invoice ชุดที่ (7)  " },
      { key: 8, value: 8, text: " เอกสารการชำระเงิน  " },
      { key: 9, value: 9, text: " ใบวางบิล  " },
      { key: 11, value: 11, text: " ใบสั่งซื้อ  "},
      { key: 12, value: 12, text: " ใบรับสินค้า  "},
      { key: 13, value: 13, text: " ใบขนส่ง  "},
      { key: 14, value: 14, text: " ใบนำส่งสินค้า  "},
      { key: 15, value: 15, text: " อื่นๆ(ระบุใน Note)  "}
    ];
  }, []);

  const invoiceDocumentOptions = useMemo(() => {
    return [
      { key: 1, value: 1, text: " เอกสาร invoice ชุดที่ (1)  "},
      { key: 2, value: 2, text: " เอกสาร invoice ชุดที่ (2)  "},
      { key: 3, value: 3, text: " เอกสาร invoice ชุดที่ (3)  "},
      { key: 4, value: 4, text: " เอกสาร invoice ชุดที่ (4)  "},
      { key: 5, value: 5, text: " เอกสาร invoice ชุดที่ (5)  "},
      { key: 6, value: 6, text: " เอกสาร invoice ชุดที่ (6)  "},
      { key: 7, value: 7, text: " เอกสาร invoice ชุดที่ (7)  "},
      { key: 8, value: 8, text: " เอกสารการชำระเงิน  "},
      { key: 9, value: 9, text: " ใบวางบิล"},
      { key: 11, value: 11, text: " ใบสั่งซื้อ  "},
      { key: 12, value: 12, text: " ใบรับสินค้า  "},
      { key: 13, value: 13, text: " ใบขนส่ง  "},
      { key: 14, value: 14, text: " ใบนำส่งสินค้า  "},
      { key: 15, value: 15, text: " อื่นๆ(ระบุใน Note)  "} ]
  }, [])

  const invoicePageOptions = useMemo(() => {
    return [
      { key: 1, value: 1, text: "1"},
      { key: 2, value: 2, text: "2"},
      { key: 3, value: 3, text: "3"},
      { key: 4, value: 4, text: "4"},
      { key: 5, value: 5, text: "5"},
      { key: 6, value: 6, text: "6"},
      { key: 7, value: 7, text: "7"},
      { key: 8, value: 8, text: "8"},
      { key: 9, value: 9, text: "9"},
      { key: 10, value: 10, text: "10"},
      { key: 11, value: 11, text: "11"},
      { key: 12, value: 12, text: "12"}]
  }, [])

  const mapToDocType: {[id: number]: string} = useMemo(() => {
    return { 8:"เอกสารชำระเงิน", 9:"ใบวางบิล", 11: "ใบสั่งซื้อ" , 12: "ใบรับสินค้า", 13: "ใบขนส่ง", 14: "ใบนำส่งสินค้า", 15: "อื่นๆ" }
  }, [])
  
  const mapToDocTypeEng: {[id: number]: string} = useMemo(() => {
    return { 8:"PAYMENT", 9:"BILL", 11: "PURCHASE" , 12: "RECEIVE", 13: "OUTSOURCE", 14: "TRANSPORT", 15: "OTHER" }
  }, [])

  useEffect(() => {

    const getInvoiceRelatedDocumentTypeChoice = async () => {
      const [r, e, n] = await invoiceRelatedDocumentTypeChoice.retrieve({
        apiToken: props.apiToken,
      });
      if (e) {
        console.log("e", e);
        console.log("n", n);
        // break
      } else {
        console.log("invoiceRelatedDocumentTypeChoice", r);
        setTypeChoice(r)
        // 
      }
    }

    
    getInvoiceRelatedDocumentTypeChoice()
    checkLost(documentSeq)

    return () => {
      isMounted.current = false;
    };
  }, [props.open]);

  // useEffect(() => {
  //   handleSetActionOptions();
  // }, [props.invoice]);

  useEffect(() => {
    clear();
  }, [props.open]);

  const clear = () => {
    setShowReason(false);
    setRemark("");
    // setAction(null);
    // setReason("");
    setError("");
  };

  // const handleSetActionOptions = () => {
  //   if (!props.invoice.allowed_actions) {
  //     return setInvoiceActionOptions([]);
  //   }
  //   console.log(" props.invoice.allowed_actions ", props.invoice.allowed_actions)
  //   let newArr = props.invoice.allowed_actions
  //     .filter((item: string) => Object.keys(STATUS_FROM_ACTION).includes(item))
  //     .map((item: string) => ({
  //       key: item,
  //       value: item,
  //       text: STATUS_FROM_ACTION[item]
  //     }));
  //   newArr = [{ key: "INFO", value: "INFO", text: "คงสถานะเดิม" }, ...newArr];
  //   setInvoiceActionOptions(newArr);
  // };

  const toBase64 = (file: any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const checkLost = (value: number) => {
    console.log(" check Lost props: ", props)
    if (!props.invoice || !props.invoice.related_documents) {
      return 
    }
    if (value <= 7) {
      let doc = props.invoice.related_documents.find((item: any) => item.seq === value)
      if (doc && doc.status_label === "เอกสารสูญหาย") {
        setDocumentLostOption(true)
 
        setAction("FOUND")
      } else {
        setDocumentLostOption(false)
        setShowImageUpload(false)
        if(action === "INFO"){
          setShowImageUpload(true)
        }
      }
    } else {
      // Two case :
      // 1. Already have ID , find it from related_documents
      // 2. Not have Id , use Doctype

      let docTypeString = mapToDocType[value] 
      let docTypeEngString = mapToDocTypeEng[value]
    
      // Case 1 
      let doc = props.invoice.related_documents.find((item: any) => item.doc_type === docTypeString || item.doc_type === docTypeEngString)
      if (doc) {
        if (doc.status_label === "เอกสารสูญหาย") {
          setDocumentLostOption(true)
     
          setAction("FOUND")
        } 
        else {
          setDocumentLostOption(false)
          setShowImageUpload(false)
          if(action === "INFO"){
            setShowImageUpload(true)
          }
          // setAction("INFO")
        }
      } 
      else {
        // Case 2 
        setDocumentLostOption(false)

        setAction("INFO")
      }
    }

  }


  const handleSave = async () => {

    console.log("handleSave props", props)
    console.log("handleSave action", action)
    console.log("handleSave page", page)
    console.log("handleSave document", documentSeq)
    console.log("handleSave remark", remark)
    console.log("handleSave typeChoice", typeChoice)
   
    if (action === "LOST") {

      console.log (" call another API")
      // Two case :
      // 1. Already have ID , find it from related_documents
      // 2. Not have Id , use Doctype and use Another API 

      if (documentSeq <= 7) {
        setIsLoading(true);
        let doc = props.invoice.related_documents.find((item: any) => item.seq === documentSeq)
         // Case 0 
          const [res, err, net] = await InvoiceRelatedDocumentDetail.patch({
            pk: doc.id,
            apiToken: props.apiToken,
            data: {
              note: remark,
              action: "INFO",
              lost: true
            },
          });

          if (err) {
            setIsLoading(false);
            setError(err);
          } else if (res) {
            setRemark("")
            setIsLoading(false);
            setIsComfirmed(false);
          }
          console.log("InvoiceRelatedDocumentDetail Case 0 lost res:" ,res)
          console.log("InvoiceRelatedDocumentDetail Case 0 lost err:" ,err)
          console.log("InvoiceRelatedDocumentDetail Case 0 lost net:" ,net)
      } else {
        setIsLoading(true);
        let docTypeString = mapToDocType[documentSeq] 
        let docTypeEngString = mapToDocTypeEng[documentSeq]
    
        let doc= props.invoice.related_documents.find((item: any) => item.doc_type === docTypeString || item.doc_type === docTypeEngString)
        if (doc) {
          // Case 1 
          const [res, err, net] = await InvoiceRelatedDocumentDetail.patch({
            pk: doc.id,
            apiToken: props.apiToken,
            data: {
              note: remark,
              action: "INFO",
              lost: true
            },
          });
          if (err) {
            setIsLoading(false);
            setError(err)
          } else if (res) {
            setRemark("")
            setIsLoading(false);
            setIsComfirmed(false);
          }
          console.log("InvoiceRelatedDocumentDetail Case 1 lost res:" ,res)
          console.log("InvoiceRelatedDocumentDetail Case 1 lost err:" ,err)
          console.log("InvoiceRelatedDocumentDetail Case 1 lost net:" ,net)
        } else {
          setIsLoading(true);
          let docType = typeChoice.find( (item:any) => item.text === docTypeEngString || item.text === docTypeString)?.value
          // Case 2 
          const [res, err, net] = await InvoiceRelatedDocumentList.create({
        
            apiToken: props.apiToken,
            data: {
              note: remark,
              action: "INFO",
              lost: true,
              doc_type: docType,
            },
            
          });
          if (err) {
            setIsLoading(false);
            setError(err)
          } else if (res) {
            setRemark("")
            setIsLoading(false);
            setIsComfirmed(false);
          }
          console.log("InvoiceRelatedDocumentList Case2 lost res:" ,res)
          console.log("InvoiceRelatedDocumentList Case2 lost err:" ,err)
          console.log("InvoiceRelatedDocumentList Case2 lost net:" ,net)
        }
      }


      setIsLoading(false);
    }else if(action === "CHECK"){
      if (documentSeq <= 7) {
        setIsLoading(true);
        let doc = props.invoice.related_documents.find((item: any) => item.seq === documentSeq)
         // Case 0 
          const [res, err, net] = await InvoiceRelatedDocumentDetail.patch({
            pk: doc.id,
            apiToken: props.apiToken,
            params: {
              invoice_update:true,
              get_image: true
            },
            data: {
              note: remark,
              action: doc.status === "WITH_BILLING"? "INFO" :"BILLING"
            },
          });

          if (err) {
            setIsLoading(false);
            setError(err);
          } else if (res) {
            setRemark("")
            setIsLoading(false);
            setIsComfirmed(false);
          }
          console.log("InvoiceRelatedDocumentDetail Case 0 lost res:" ,res)
          console.log("InvoiceRelatedDocumentDetail Case 0 lost err:" ,err)
          console.log("InvoiceRelatedDocumentDetail Case 0 lost net:" ,net)
      }else{
        if (props.invoice.status !== "DOCUMENT_RETURN"){
          alert('Invoice ดังกล่าวไม่ได้อยู่ในสถานะผู้แทนส่งคืนเอกสารให้ Billing')
          return;
        }
        setIsLoading(true);
        let docTypeString = mapToDocType[documentSeq] 
        let docTypeEngString = mapToDocTypeEng[documentSeq]
    
        let doc= props.invoice.related_documents.find((item: any) => item.doc_type === docTypeString || item.doc_type === docTypeEngString)
        if (doc) {
          // Case 1 
          const [res, err, net] = await InvoiceRelatedDocumentDetail.patch({
            pk: doc.id,
            apiToken: props.apiToken,
            params: {
              invoice_update:true,
              get_image: true
            },
            data: {
              note: remark,
              action: doc.status === "WITH_BILLING"? "INFO" :"BILLING",
            },
          });
          if (err) {    
            setIsLoading(false);
            setError(err)
          } else if (res) {
            setRemark("")
            setIsLoading(false);
            setIsComfirmed(false);
          }
          console.log("InvoiceRelatedDocumentDetail Case 1 lost res:" ,res)
          console.log("InvoiceRelatedDocumentDetail Case 1 lost err:" ,err)
          console.log("InvoiceRelatedDocumentDetail Case 1 lost net:" ,net)

    
        } else {
          setError('ไม่มีภาพเอกสารที่ต้องการในระบบ กรุณานำเข้าภาพเอกสารดังกล่าว')
          return;
        }
      } 
    }else if(action === "UPDATE"){
      setIsLoading(true);
      if(!remark || remark === ""){
        setIsLoading(false);
        setError("กรุณาระบุหมายเหตุของการอัพเดทสถานะของ Invoice")
        return;
      }
      if(props.invoice.is_cndn && toStatus === "SENDGOODS"){
        setIsLoading(false);
        setError("ไม่สามารถอัพเดท Invoice ไปยังสถานะส่งสินค้าแล้วได้ เนื่องจาก Invoice ดังกล่าวเป็นชนิด CN หรือ DN")
        return;
      }
      const [r, e, n] = await InvoiceDetail.patch({
        apiToken: props.apiToken,
        pk: props.invoice.id,
        params: {
          manual_update_invoice: true
        },
        data: { 
          action: toStatus,
          reason: remark
        },
      });
      if (e) {
        setIsLoading(false);
        setError(e);
      } else if (r) {
        setRemark("")
        setIsLoading(false);
        setIsComfirmed(false);
      }
    }else {

      let data: any = {
        invoice: props.invoice.id,
        page: page,
        document_image: null,
      }

      if (pictureBase64) {
        setIsLoading(true);
        data.document_image = pictureBase64
      } else {
        alert(" กรุณาเลือกไฟล์ เพื่อนำเข้า")
        return null
      }

      if (remark) {
        data.note = remark
      }

      if ( action === "FOUND") {
        data.lost_found = true
      }
      console.log(documentSeq)
      if (documentSeq <= 7) {
        data.document = props.invoice.related_documents.find((item: any) => item.seq === documentSeq).id
      } else {
        let docTypeString = mapToDocType[documentSeq]
        let docTypeEngString = mapToDocTypeEng[documentSeq]
        let doc= props.invoice.related_documents.find((item: any) => item.doc_type === docTypeString || item.doc_type === docTypeEngString)
        if (doc) {
          data.document = doc.id
        } 
        data.doc_type = typeChoice.find( (item:any) => item.text === docTypeEngString || item.text === docTypeString)?.value
        
      }

      console.log("data", data)
      const [r, e, n] = await InvoiceDocumentImageList.create({
          apiToken: props.apiToken,
          data: data,
          params: {
            manual_uploaded: true
          }
        });

      if (e) {
        console.log("e", e);
        console.log("n", n);
        setError(e);
        // break
      } else {
        setIsLoading(false);
        setIsComfirmed(false);
        if (fileRef && fileRef.current) {
          fileRef.current.value = null
        }
        setRemark("")
        setPictureBase64(null)

        console.log(r);
      
      }
      setIsLoading(false);


    }

    
  

    // fun uploadImage(bmp: Bitmap, invoice_id: Int, doc_id: Int?, page_id: Int, doctype_id: Int?, lost: Boolean?, callback: (result:Boolean) -> Unit) {
    //   val base64 = bitmapToBase64(bmp)
    //   var gsonTemp : String = ""
    //   var paramsHash = hashMapOf("invoice" to invoice_id, "document_image" to base64, "page" to page_id)

    //   if (doc_id != null) {
    //       paramsHash.put("document", doc_id!!)
    //   } else if (doctype_id != null) {
    //       paramsHash.put("doc_type", doctype_id!!)
    //   }

    //   if (lost != null) {
    //       paramsHash.put("lost_found", lost!!)
    //   }


    // const [response, err, network] = await InvoiceDetail.patch({
    //   pk: props.invoice.id,
    //   apiToken: props.apiToken,
    //   data: {
    //     action,
    //     remark,
    //     // loss_reason: reason
    //   }
    // });
    // if (isMounted.current) {
    //   setIsLoading(false);
    //   if (err) {
    //     let errText = err;
    //     if (err.length > 200) {
    //       errText = `${network.response.status}: ${network.response.statusText}`;
    //     }
    //     setError(errText);
    //   } else {
    //     props.onSuccess();
    //   }
    // }
  };

  return (
    <Modal
      className="mod-update-invoice"
      size="tiny"
      open={props.open}
      closeOnDimmerClick={false}
      onClose={props.onClose}
    >
      <Dimmer.Dimmable>
        <Dimmer active={isLoading} inverted>
          <Loader inverted />
        </Dimmer>
        <Dimmer active={!isComfirmed} inverted>
            <div style={{display:"flex", flexDirection:"column", alignItems: "center"}}>
              <span style={{
                color: "#696969", 
                fontSize: "20px",
                fontWeight: "bold",
                paddingBottom: "30px"
                }}>บันทึกเสร็จสิ้น</span>
                <div style={{paddingBottom:"30px", fontSize: "50px"}}><Icon name="check circle" color="green" /></div>
              <Button color="green" onClick={()=>{
                setIsComfirmed(true)
                if(action !== "CHECK" && action !== "INFO"){
                  props.onSuccess();
                }
                }}>ตกลง</Button>
            </div>
        </Dimmer>
        <div>
          <h2>กรุณาระบุรายละเอียดเอกสาร</h2>
          <ErrorMessage error={error} />
          <div className="row">
            <div className="key-field">Invoice No.:</div>
            <div className="input-field">{props.invoice.serial}</div>
          </div>
          <div className="row">
            <div className="key-field" style={{ alignSelf: "center" }}>
              Action<span style={{ color: "red" }}>*</span>:
            </div>
            <div className="input-field">
              <Dropdown
                style={{ width: "100%" }}
                search
                selection
                value={action}
                options={documentLostOption ? invoiceActionLostOptions : invoiceActionValidOptions}
                onChange={(e, { value }) => {
                  setAction(value as string)
                  if (value === "INFO") {
                    setShowImageUpload(true)
                  } else {
                    setShowImageUpload(false)
                  }
                  
                }}
              />
            </div>
          </div>
          {action !== "UPDATE" && (<div className="row">
            <div className="key-field" style={{ alignSelf: "center" }}>
              เอกสารชุดที่<span style={{ color: "red" }}>*</span>:
            </div>
            <div className="input-field">
              <Dropdown
                style={{ width: "100%" }}
                value={documentSeq}
                search
                selection
                options={action === "CHECK"? invoiceDocumentCheckOptions: invoiceDocumentOptions}
                onChange={(e, { value }) => { 
                  setDocumentSeq(value as number)
                  if(action !== "CHECK"){
                  setShowImageUpload(true)
                  checkLost(value as number)
                  
                  }
                }}
              />
            </div>
          </div>)}
          {action === "INFO" && 
          (<div className="row">
            <div className="key-field" style={{ alignSelf: "center" }}>
              หน้าที่<span style={{ color: "red" }}>*</span>:
            </div>
            <div className="input-field">
              <Dropdown
                style={{ width: "100%" }}
                value={page}
                search
                selection
                options={invoicePageOptions}
                onChange={(e, { value }) => { setPage(value as number)
                }}
              />
            </div>
          </div>)}

          {action === "UPDATE" && 
          (<div className="row">
            <div className="key-field" style={{ alignSelf: "center" }}>
              สถานะ<span style={{ color: "red" }}>*</span>:
            </div>
            <div className="input-field">
              <Dropdown
                style={{ width: "100%" }}
                value={toStatus}
                search
                selection
                options={invoiceStatus}
                onChange={(e, { value }) => { 
                  setToStatus(value as string)
                }}
              />
            </div>
          </div>)}

          { showImageUpload && 
          <div className="row">
            <div className="key-field" style={{ alignSelf: "center" }}>
              รูปถ่าย/รูปสแกนเอกสาร :
            </div>
            <div className="input-field">
            <input
              type="file"
              accept=".jpg,.png"
              ref={fileRef}
              onClick={(event: any) => {
                console.log(" onClick")
                setPictureBase64(null)
                // event.target.value = null
                // handleEvent({ 
                //   message: "clickButton",
                //   params: { name: "Clear"}
                // })
              }}
              onChange={ async (event: any) => {
                if (event.target.files && event.target.files.length > 0) {
                  console.log(" event.target.files", event.target.files)
                  const a = await toBase64(event.target.files[0])
                  if (a) {
                    console.log("picture base64 image")
                    setPictureBase64(a)
                  } else {
                    console.log("picture base64 null")
                    setPictureBase64(null)
                  }

                  // handleEvent({
                  //   message: "clickButton",
                  //   params: {
                  //     name: "chooseFile",
                  //     value: event.target
                  //   }
                  // });
                }
              }}
            />
              {/* <Dropdown
                style={{ width: "100%" }}
                search
                selection
                options={invoiceActionOptions}
                onChange={(e, { value }) => {
                  if (NEED_REASON_ACTION.includes(value as string)) {
                    setShowReason(true);
                  }
                  setAction(value as string);
                }}
              /> */}
            </div>
          </div>
          }
         
          {/* <div className="row">
            <div className="key-field" style={{ alignSelf: "center" }}>
              หมายเหตุ<span style={{ color: "red" }}>*</span>:
            </div>
            <div className="input-field">
              <Input
                style={{ width: "100%" }}
                value={reason}
                onChange={e => setReason(e.target.value)}
              />
            </div>
          </div> */}
          
          <div className="row">
            <div className="key-field">หมายเหตุ:</div>
            <div className="input-field">
              <Form> 
              <TextArea
                style={{ width: "100%", resize: "none"}}
                rows={4}
                value={remark}
                onChange={(e, { value }) => {
                  setRemark(value as string);
                }}
              />
              </Form>
            </div>
          </div>
          <div className="last row">
            <Button color="red" onClick={props.onClose}>
              ยกเลิก
            </Button>
            <Button
              color="green"
              // disabled={!action || (showReason && !reason)}
              onClick={handleSave}
            >
              บันทึก
            </Button>
          </div>
        </div>
      </Dimmer.Dimmable>
    </Modal>
  );
};

ModUpdateInvoice.defaultProps = {
  invoice: {},
  onSuccess: () => {},
  open: false
};

export default React.memo(ModUpdateInvoice);

import React, { useState, useEffect } from "react";
import { Button, Dimmer, Loader, Modal, Icon, Input } from "semantic-ui-react";
import { InvoiceCancelAll } from "../../react-lib/apis/issara/apps/TRK/INVInvoice";
import { EmployeeDetailFromCode } from "../../react-lib/apis/issara/apps/TRK/INVEmployee";
import * as CONSTANT from "../../react-lib/utils/constant";
import './ModCancelAllInvoice.css'
import ErrorMessage from "../../react-lib/apps/common/ErrorMessage";

const ModCancelAllInvoice = (props: any) => {
  const [verifyFailed, setVerifyFailed] = useState(false);
  const [validate, setValidate] = useState(false);
  const [employeeName, setEmployeeName] = useState("");
  const [employeeCode, setEmployeeCode] = useState("");
  const [typingTimeout, setTypingTimeOut] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>("");
  const [isConfirmed, setIsConfirmed] = useState(true);

  const handleSave = async () => {
    setIsLoading(true);
    const [r, e, n] = await InvoiceCancelAll.patch({
      apiToken: props.apiToken,
      data: {
        invoice_list: props.invoiceList
      }
    })
    if(e){
      setIsLoading(false);
      setError(e)
    }
    if(r){
      setIsLoading(false);
      setIsConfirmed(false);
    }else{
      setIsLoading(false);
    }
  };

  const getEmployeeCode = async (code: String) => {
    const [r, e, n] = await EmployeeDetailFromCode.retrieve({
      code: code,
      apiToken: props.apiToken,
      params: {
        check_for: "all_invoice_cancel"
      }
    });
    if (r) {
      // if (
      //   r.position_code === "manager" ||
      //   r.position_code === "acc_fin" ||
      //   r.position_code === "mana_bill"
      // ) {
        setVerifyFailed(false);
        setValidate(true);
        setEmployeeName(r.first_name + " " + r.last_name);
      // } else {
      //   setVerifyFailed(true);
      //   setValidate(false);
      //   setEmployeeName("ผู้ใช้งานนี้ไม่มีสิทธิ์ในการดำเนินการ");
      // }
    } else {
      setValidate(false);
      setVerifyFailed(true);
      if(e && e.length < 80){
        setEmployeeName(e);
      }else{
        setEmployeeName("รหัสเจ้าหน้าที่ไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง");
      }
    }
  };

  const handleGetEmployeeCode = () => {
    getEmployeeCode(employeeCode);
  };

  const handleAutoGetEmployeeCode = (e: any) => {
    let code = e.target.value;
    setEmployeeCode(code);
    if (typingTimeout) {
      window.clearTimeout(typingTimeout);
    }
    setTypingTimeOut(
      window.setTimeout(() => {
        getEmployeeCode(code);
      }, 1000)
    );
  };

  const handleShowInvoiceList = () => {
      if (props.invoiceList.length > 0){
          return props.invoiceList.map((item: string) => {
              return <div className="invoice">{item}</div>
          })
      }
      return;
  }
  return (
    <Modal
        className="mod-cancel-invoice"
        size="tiny"
        open={props.open}
        closeOnDimmerClick={false}
        onClose={props.onClose}
    >
        <ErrorMessage error={error} />
      <Dimmer.Dimmable>
        <Dimmer active={isLoading} inverted>
          <Loader inverted />
        </Dimmer>
        <Dimmer active={!isConfirmed} inverted>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span
              style={{
                color: "#696969",
                fontSize: "20px",
                fontWeight: "bold",
                paddingBottom: "30px",
              }}
            >
              บันทึกเสร็จสิ้น
            </span>
            <div style={{ paddingBottom: "30px", fontSize: "50px" }}>
              <Icon name="check circle" color="green" />
            </div>
            <Button
              color="green"
              onClick={() => {
                setIsConfirmed(true);
                props.onSuccess();
              }}
            >
              ตกลง
            </Button>
          </div>
        </Dimmer>
        <div className="stack">
          <h2>
            กรุณากรอกรหัสพนักงาน
            <br/>
            เพื่อยกเลิก Invoice
          </h2>
          <div className="row">
              <div className="item" >Invoice No:</div>
                <div className="item-invoice">
                    {handleShowInvoiceList()}
                </div>
          </div>
          <div className="row" style={{color: verifyFailed ? "#DB4632" : "#00529C"}} >
            <div className='item' style={{ fontWeight: "bolder", paddingRight: "20px" }}>
              รหัสเจ้าหน้าที่
              <span style={{ color: "red" }}>*</span>:
            </div>
            <Input
              type="password"
              value={employeeCode}
              onKeyDown={(e: any) => {
                if (e.keyCode === CONSTANT.KEY_CODE.ENTER) {
                  handleGetEmployeeCode();
                }
              }}
              onChange={(e: any) => handleAutoGetEmployeeCode(e)}
            >
              <input
                style={{
                  color: validate ? "#00529C" : verifyFailed ? "#DB4632" : "",
                  border: verifyFailed ? "1px solid #DB4632" : "",
                  backgroundColor: verifyFailed ? "#FFDEDA" : "",
                }}
              />
            </Input>
            {validate && (
              <div className='item' style={{ paddingLeft: "10px", color: "#00529C"}}>{employeeName}</div>
            )}
          </div>
          <div>
            {verifyFailed && (
              <div
                className='item'
                style={{
                  paddingBottom: "20px",
                  color: "#DB4632",
                  fontSize: "16px",
                }}
              >
                {employeeName}
              </div>
            )}
          </div>
          <div>
            <Button
              content="ตกลง"
              color="green"
              disabled={!validate || verifyFailed}
              onClick={() => {
                handleSave();
              }}
            />
            <Button
              content="ยกเลิก"
              color="red"
              onClick={() => {
                setIsConfirmed(true);
                setError("");
                props.onClose();
              }}
            />
          </div>
        </div>
      </Dimmer.Dimmable>
    </Modal>
  );
};

ModCancelAllInvoice.defaultProps = {
  invoiceList: [],
  onClose: () => {},
  onSuccess: () => {},
  open: false,
};

export default React.memo(ModCancelAllInvoice);

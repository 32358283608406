import React from "react";
import { Input, Button, Dimmer, Loader } from "semantic-ui-react";

import styles from "./INTVerifyEmployee.module.css";

import ErrorMessage from "../../react-lib/apps/common/ErrorMessage";
import { withCookies } from "react-cookie";

const MPC = "/static/images/logo_mpc.png";
const ANB ="/static/images/logo_anb.png";

export interface INTVerifyEmployeeViewDataType {
  Username: string;
  UsernameDisabled: boolean;
  EmployeeNumber: string;
  EmployeeNumberDisabled: boolean;
}

export const INTVerifyEmployeeInitialViewData: INTVerifyEmployeeViewDataType = {
  Username: "",
  UsernameDisabled: false,
  EmployeeNumber: "",
  EmployeeNumberDisabled: false
};

const Sub = (props: any) => {
  const children = React.Children.toArray(props.children);
  return (
    <div style={{ padding: "20px" }} className="verify-employee">
      <Dimmer.Dimmable>
        <Dimmer active={props.loading} inverted>
          <Loader inverted/>
        </Dimmer>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <img style={{ height: "60px" }} src={props.cookies.get('hostName') === "ANB"? `${process.env.PUBLIC_URL + ANB}` : `${process.env.PUBLIC_URL + MPC}`} />
          <h4 style={{ color: "#00529C" }}>ลงทะเบียนระบบ Invoice Tracking</h4>
        </div>
        <div
          style={{
            margin: "auto",
            width: "70%",
            textAlign: "center",
            marginTop: "5%"
          }}
        >
          <ErrorMessage error={props.verifyError} />
          <div style={{ display: "flex" }}>
            <div style={{ alignSelf: "center", color: "#00529C" }}>
              <b>
                ชื่อผู้ใช้ / เบอร์มือถือ<span style={{ color: "red" }}>*</span>
              </b>
            </div>
            &ensp;
            <Input
              style={{ width: "100%" }}
              value={props.viewData.Username}
              disabled={props.viewData.UsernameDisabled}
              onChange={e => {
                props.onEvent({
                  message: "inputChange",
                  params: {
                    view: "INTVerifyEmployee",
                    name: "Username",
                    value: e.target.value
                  }
                });
              }}
            />
          </div>
          <br />
          <div style={{ display: "flex" }}>
            <div style={{ alignSelf: "center", color: "#00529C" }}>
              <b>
                รหัสพนักงาน<span style={{ color: "red" }}>*</span>
              </b>
            </div>
            &ensp;
            <Input
              style={{ width: "100%" }}
              value={props.viewData.EmployeeNumber}
              disabled={props.viewData.EmployeeNumberDisabled}
              onChange={e => {
                props.onEvent({
                  message: "inputChange",
                  params: {
                    view: "INTVerifyEmployee",
                    name: "EmployeeNumber",
                    value: e.target.value
                  }
                });
              }}
            />
          </div>
          <br />
          <Button
            disabled={props.viewData.VerifyDisabled}
            onClick={e => {
              props.onEvent({
                message: "clickButton",
                params: {
                  view: "INTVerifyEmployee",
                  name: "Verify"
                }
              });
            }}
            size="big"
            style={{
              backgroundColor: "#D61A1A",
              color: "White"
            }}
          >
            ตรวจสอบข้อมูล
          </Button>
        </div>
      </Dimmer.Dimmable>
    </div>
    // <div className={styles.container}>
    //   <div className={styles.Item1}>
    //     <div style={{ height: "100%", width: "100%" }}>
    //       <img style={{ height: "100%" }} src={logo} />
    //     </div>
    //   </div>
    //   <div className={styles.Item2}>
    //     <div style={{ color: "#00529C", height: "100%", width: "100%" }}>
    //       <h4>
    //         <strong>ลงทะเบียนระบบ Invoice Tracking</strong>
    //       </h4>
    //     </div>
    //   </div>
    //   <div className={styles.Username}>
    //     <Input
    //       value={props.viewData.Username}
    //       disabled={props.viewData.UsernameDisabled}
    //       onChange={(e) => {
    //         props.onEvent({
    //           message: "inputChange",
    //           params: {
    //             view: "INTVerifyEmployee",
    //             name: "Username",
    //             value: e.target.value,
    //           },
    //         });
    //       }}
    //       style={{ height: "100%", width: "100%" }}
    //     ></Input>
    //   </div>
    //   <div className={styles.Item6}>
    //     <div style={{ color: "#00529C", height: "100%", width: "100%" }}>
    //       <p>
    //         <strong>Username<span style={{ color: "red" }}>*</span> :</strong>
    //       </p>
    //     </div>
    //   </div>
    //   <div className={styles.EmployeeNumber}>
    //     <Input
    //       value={props.viewData.EmployeeNumber}
    //       disabled={props.viewData.EmployeeNumberDisabled}
    //       onChange={(e) => {
    //         props.onEvent({
    //           message: "inputChange",
    //           params: {
    //             view: "INTVerifyEmployee",
    //             name: "EmployeeNumber",
    //             value: e.target.value,
    //           },
    //         });
    //       }}
    //       style={{ height: "100%", width: "100%" }}
    //     ></Input>
    //   </div>
    //   <div className={styles.Item9}>
    //     <div style={{ color: "#00529C", height: "100%", width: "100%" }}>
    //       <p>
    //         <strong>รหัสพนักงาน <span style={{ color: "red" }}>*</span> :</strong>
    //       </p>
    //     </div>
    //   </div>
    //   <div className={styles.Verify}>
    //     <Button
    //       disabled={props.viewData.VerifyDisabled}
    //       onClick={(e) => {
    //         props.onEvent({
    //           message: "clickButton",
    //           params: {
    //             view: "INTVerifyEmployee",
    //             name: "Verify",
    //           },
    //         });
    //       }}
    //       size="big"
    //       style={{
    //         backgroundColor: "#D61A1A",
    //         color: "White",
    //         height: "100%",
    //         width: "100%",
    //       }}
    //     >
    //       ตรวจสอบข้อมูล
    //     </Button>
    //   </div>
    // </div>
  );
};

export default withCookies(Sub);

const getTsName = (cssClass: string) => {
  return cssClass
    .split("-")
    .map(s => s.charAt(0).toUpperCase() + s.slice(1))
    .join("");
};

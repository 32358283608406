import React, { CSSProperties } from 'react'
import styles from './MainLayout.module.css'
import { Icon } from 'semantic-ui-react'
import config from "../../config/config";
const DEBUG = `${config.DEBUG}`

const MainLayout = (props: any) => {

    return(
        <div className={styles.container}>
            <div className={styles.menu}>
                <div style={{display:"flex", justifyContent:"flex-start", alignItems: "center"}}> 


                <div className={styles.menuitem}
                    style={{ cursor: 'pointer'} as CSSProperties}
                >
                    <div style={{display: "flex", justifyContent: "flex-start", alignItems:"center"}}>
                        <Icon name='user' size="large"/>
                        <div style={{textAlign: "left"}}>
                            <div style={{padding: "2px 0px 2px 10px", fontWeight: "bolder", fontSize: "18px", maxWidth: "300px"}}>{props.login_employee.first_name} {props.login_employee.last_name}</div>
                            <div style={{padding: "2px 0px 2px 10px", fontSize: "14px"}}>({props.login_employee.position})</div>
                        </div>
                    </div>
                </div>

                <div className={styles.menuitem}
                    style={{ cursor: 'pointer'} as CSSProperties}
                    onClick={(e) => { props.onEvent({
                        message: 'clickMenu', 
                        params: { name: 'Noti' }
                    })}} 
                ><Icon name={props.selectedCard === "Noti" ? 'bell' : 'bell outline'} size="large"/>
                </div>

                <div className={styles.menuitem}
                    style={{ cursor: 'pointer'} as CSSProperties}
                    onClick={(e) => { props.onEvent({
                        message: 'clickMenu', 
                        params: { name: 'InvoiceList' }
                    })}}
                ><span style={{ fontSize:  props.selectedCard === "Invoice" ? "1.5em" : "1.17em", fontWeight: props.selectedCard === "Invoice" ? "bold" : 300 }}>จัดการเอกสาร</span>
                </div>

                <div className={styles.menuitem}
                    style={{ cursor: 'pointer'} as CSSProperties}
                    onClick={(e) => { props.onEvent({
                        message: 'clickMenu', 
                        params: { name: 'Shipping' }
                    })}}
                ><span style={{ fontSize:  props.selectedCard === "Shipping" ? "1.5em" : "1.17em", fontWeight: props.selectedCard === "Shipping" ? "bold" : 300 }}>นำเข้าไฟล์ขนส่ง</span>
                </div>

                <div className={styles.menuitem}
                    style={{ cursor: 'pointer'} as CSSProperties}
                    onClick={(e) => { props.onEvent({
                        message: 'clickMenu', 
                        params: { name: 'Analytics' }
                    })}}
                ><span style={{ fontSize:  props.selectedCard === "Analytics" ? "1.5em" : "1.17em", fontWeight: props.selectedCard === "Analytics" ? "bold" : 300 }}>สรุปรายงาน</span>
                </div>

                <div className={styles.menuitem}
                    style={{ cursor: 'pointer'} as CSSProperties}
                    onClick={(e) => { props.onEvent({
                        message: 'clickMenu', 
                        params: { name: 'Employee' }
                    })}}
                ><span style={{ fontSize:  props.selectedCard === "Employee" ? "1.5em" : "1.17em", fontWeight: props.selectedCard === "Employee" ? "bold" : 300 }}>รายชื่อพนักงาน</span>
                </div>

                {DEBUG &&
                <div className={styles.menuitem}
                    style={{ cursor: 'pointer'} as CSSProperties}
                    onClick={(e) => { props.onEvent({
                        message: 'clickMenu', 
                        params: { name: 'Debug' }
                    })}}
                ><span style={{ fontSize:  props.selectedCard === "Debug" ? "1.5em" : "1.17em", fontWeight: props.selectedCard === "Debug" ? "bold" : 300 }}>Debug</span>
                </div>
                }

            </div>  

            <div className={styles.menu}>
                <div className={styles.menuitem}
                    style={{ cursor: 'pointer'} as CSSProperties}
                    onClick={(e) => { props.onEvent({
                        message: 'clickMenu', 
                        params: { name: 'Logout' }
                    })}}
                ><span style={{ fontSize: "1.17em" }}>Sign out <Icon name="log out" /> </span>
                </div>
            </div>

            </div>

           
            <div className={styles.content}>
                {props.children}
            </div>
        </div>
    )

}

export default MainLayout
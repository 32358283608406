import React, {SyntheticEvent} from "react";
import AnalyticsLayout from "./AnalyticsLayout";
import {
  Input,
  Dropdown,
  Button,
  Icon,
  Dimmer,
  Loader,
  Pagination,
  PaginationProps
} from "semantic-ui-react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import formatComma from "../../react-lib/utils/formatComma";
import { DateUtils } from "react-day-picker";
import dateFnsFormat from "date-fns/format";
import dateFnsParse from "date-fns/parse";
import { Choices } from "../../react-lib/apis/issara/apps/TRK/INVChoices";

const FORMAT = "dd/MM/yyyy";
const FORMATEDITABLE = "dd/MM/y";

const extractValue = (column: any, row: any) => {
  if (!column.key.includes(".")) {
    if (column.key in row && row[column.key] !== null)
      if (column.number) {
        try {
          return Number(row[column.key]);
        } catch (e) {
          return row[column.key].toString();
        }
      } else return row[column.key].toString();
    else return "n.a.";
  } else {
    const keys = column.key.split(".");
    var obj = row;
    for (const key of keys) {
      obj = obj[key];
    }
    if (obj !== null)
      if (column.number) {
        try {
          return Number(obj);
        } catch {
          return obj.toString();
        }
      } else return obj.toString();
    else return "n.a.";
  }
};

// const Pagination = (props: any) => {
//   // console.log('Pagination props', props)

//   const len = Math.min(10, props.numPage - props.page + 1);
//   const style = (n: number) => ({
//     backgroundColor: props.page === n ? "#00529C" : "grey",
//     color: "white",
//     margin: "5px",
//     padding: "5px",
//     cursor: "pointer"
//   });
//   return (
//     <div style={{ display: "flex", marginLeft: "15px" }}>
//       <div style={style(0)} onClick={e => props.onSelectPage(1)}>
//         &lt;&lt;
//       </div>
//       <div
//         style={style(0)}
//         onClick={e => props.onSelectPage(Math.max(1, props.page - 10))}
//       >
//         &lt;
//       </div>
//       {props.numPage > 0 &&
//         Array.from({ length: len }, (x, i) => i + props.page).map(
//           (n, index) => (
//             <div
//               key={index}
//               style={style(n)}
//               onClick={e => props.onSelectPage(n)}
//             >
//               {n}
//             </div>
//           )
//         )}
//       <div
//         style={style(0)}
//         onClick={e =>
//           props.onSelectPage(Math.min(props.numPage, props.page + 10))
//         }
//       >
//         &gt;
//       </div>
//       <div style={style(0)} onClick={e => props.onSelectPage(props.numPage)}>
//         &gt;&gt;
//       </div>
//     </div>
//   );
// };

const InvoiceSummary = (props: any) => {
  const [invoiceStatusOptions, setInvoiceStatusOptions] = React.useState([]);
  const [billingTypeList, setBillingTypeList] = React.useState([]);

  React.useEffect(() => {
    if (props.apiToken) {
      handleGetInvoiceStatusList();
      handleGetBillingTypeList();
    }
  }, [props.apiToken]);

  const handleGetBillingTypeList = async () => {
    const [res, err, network] = await Choices.retrieve({
      params: {
        model: "TRK.Invoice",
        field: "billing_type"
      },
      apiToken: props.apiToken
    });
    if (res) {
      let newArr = res.items.map((item: any) => {
        return {
          key: item.id,
          text: item.label,
          value: item.value
        };
      });
      setBillingTypeList(newArr);
    } else {
      setBillingTypeList([])
    }
  };

  const handleGetInvoiceStatusList = async () => {
    const [res, err, network] = await Choices.retrieve({
      params: {
        model: "TRK.Invoice",
        field: "status"
      },
      apiToken: props.apiToken
    });
    if (res) {
      let newArr = res.items.map((item: any) => {
        return {
          key: item.id,
          text: item.label,
          value: item.value
        };
      });
      setInvoiceStatusOptions(newArr);
    } else {
      setInvoiceStatusOptions([])
    }
  };

  return (
    <Dimmer.Dimmable>
      <Dimmer active={props.loading} inverted>
        <Loader inverted />
      </Dimmer>
      <AnalyticsLayout
        sub={props.sub}
        onEvent={props.onEvent}
        login_employee={props.login_employee}
        exportAllLoading={props.exportAllLoading}
        exportExcelLoading={props.exportExcelLoading}
        onExportAll={() => {if(props.onExportAll) props.onExportAll({ exportAll: true })}}
      >
        <div style={{ padding: "20px" }}>
          <h2>หน้าสรุป Invoice</h2>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div>
              <b>Invoice No.</b>
              <Input
                style={{ margin: "10px" }}
                value={props.filterParams["serial"]}
                onChange={e => {
                  props.onEvent({
                    message: "filterChange",
                    params: {
                      ...props.filterParams,
                      ["serial"]: e.target.value
                    }
                  });
                }}
              />
            </div>
            <div>
              <b>Customer No.</b>
              <Input
                style={{ margin: "10px" }}
                value={props.filterParams["customer_code"]}
                onChange={e => {
                  props.onEvent({
                    message: "filterChange",
                    params: {
                      ...props.filterParams,
                      ["customer_code"]: e.target.value
                    }
                  });
                }}
              />
            </div>
            <div>
              <b>Customer Name</b>
              <Input
                style={{ margin: "10px" }}
                value={props.filterParams["customer_name"]}
                onChange={e => {
                  props.onEvent({
                    message: "filterChange",
                    params: {
                      ...props.filterParams,
                      ["customer_name"]: e.target.value
                    }
                  });
                }}
              />
            </div>
            <div>
              <b>Sale</b>
              <Input
                style={{ margin: "10px" }}
                value={props.filterParams["sale_name"]}
                onChange={e => {
                  props.onEvent({
                    message: "filterChange",
                    params: {
                      ...props.filterParams,
                      ["sale_name"]: e.target.value
                    }
                  });
                }}
              />
            </div>
            <div>
              <b>From</b>
              <DayPickerInput
                inputProps={{
                  style: {
                    height: "38px",
                    border: "1px solid lightgray",
                    borderRadius: "3px",
                    paddingLeft: "8px"
                  }
                }}
                style={{ margin: "10px" }}
                value={props.filterParams["from_date"]}
                format={FORMATEDITABLE}
                formatDate={(date, format) => dateFnsFormat(date, format)}
                parseDate={(str, format) => {
                  const parsed = dateFnsParse(str, format, new Date());
                  if (DateUtils.isDate(parsed)) {
                    return parsed;
                  }
                  return undefined;
                }}
                onDayChange={day => {
                  if(day){
                    console.log("InvoiceSummary Date", day)
                    const parsedDate = dateFnsFormat(day, FORMATEDITABLE);
                    props.onEvent({
                      message: "filterChange",
                      params: {
                        ...props.filterParams,
                        ["from_date"]: parsedDate
                      }
                    });
                  }
                  else{
                  props.onEvent({
                    message: "filterChange",
                    params: {
                      ...props.filterParams,
                      ["from_date"]: ""
                    }
                  });
                  }
                }}
              />
              <b>To</b>
              <DayPickerInput
                inputProps={{
                  style: {
                    height: "38px",
                    border: "1px solid lightgray",
                    borderRadius: "3px",
                    paddingLeft: "8px"
                  }
                }}
                dayPickerProps={{
                  modifiers: {
                    disabled: [
                      {
                        after: new Date()
                      }
                    ]
                  }
                }}
                // disabledDays={[{}]}
                style={{ margin: "10px" }}
                value={props.filterParams["to_date"]}
                format={FORMATEDITABLE}
                formatDate={(date, format) => dateFnsFormat(date, format)}
                parseDate={(str, format) => {
                  const parsed = dateFnsParse(str, format, new Date());
                  if (DateUtils.isDate(parsed)) {
                    return parsed;
                  }
                  return undefined;
                }}
                onDayChange={day => {
                  console.log("InvoiceSummary Date", day)
                  if(day){
                  const parsedDate = dateFnsFormat(day, FORMATEDITABLE);
                  console.log(parsedDate)
                  props.onEvent({
                    message: "filterChange",
                    params: {
                      ...props.filterParams,
                      ["to_date"]: parsedDate
                    }
                  });
                }
                else{
                props.onEvent({
                  message: "filterChange",
                  params: {
                    ...props.filterParams,
                    ["to_date"]: ""
                  }
                });
              }
                }}
              />
            </div>
            <div>
              <b>Status</b>
              <Dropdown
                search
                selection
                clearable
                options={invoiceStatusOptions}
                style={{ margin: "10px" }}
                value={props.filterParams["status"]}
                onChange={(e, { value }) => {
                  props.onEvent({
                    message: "filterChange",
                    params: {
                      ...props.filterParams,
                      ["status"]: value
                    }
                  });
                }}
              />
            </div>
            <div>
              <b>Type</b>
              <Dropdown
                search
                selection
                clearable
                options={billingTypeList}
                style={{ margin: "10px" }}
                value={props.filterParams["type"]}
                onChange={(e, { value }) => {
                  props.onEvent({
                    message: "filterChange",
                    params: {
                      ...props.filterParams,
                      ["type"]: value
                    }
                  });
                }}
              />
            </div>
            <div>
              <Button
                content="ค้นหา"
                color="blue"
                style={{ margin: "10px" }}
                onClick={e => {
                  props.onEvent({
                    message: "filterSubmit",
                    params: {
                      ...props.filterParams,
                      page: 1
                    }
                  });
                }}
              />
            </div>
          </div>
        </div>
        <table
          style={{ width: "100%", margin: "20px", borderCollapse: "collapse" }}
          className="invoice-summary"
        >
          <thead>
            <tr>
              {props.columns.map((column: any, column_index: number) => (
                <th
                  key={column_index}
                  style={{
                    textAlign: column.number ? "right" : "left",
                    whiteSpace: "nowrap",
                    borderBottom: "1px solid #4F4F4F",
                    paddingBottom: "10px",
                    cursor: "pointer"
                  }}
                  onClick={(e: any) => {
                    console.log(column.key);
                    props.onEvent({
                      message: "filterSubmit",
                      params: {
                        ...props.filterParams,
                        sortby: column.key,
                        page: 1,
                        ascending:
                          column.key === props.filterParams.sortby
                            ? !props.filterParams.ascending
                            : props.filterParams.ascending
                      }
                    });
                  }}
                >
                  <b>
                    {column.name}
                    <Icon name="sort" />
                  </b>
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {/* Table content */}
            {props.viewData
              .sort((a: any, b: any) => {
                if (
                  "sortby" in props.filterParams &&
                  props.filterParams.sortby !== ""
                ) {
                  const candidates = props.columns.filter(
                    (item: any) => item.key === props.filterParams.sortby
                  );
                  if (candidates.length > 0) {
                    const val_a = extractValue(candidates[0], a);
                    const val_b = extractValue(candidates[0], b);
                    if (
                      "ascending" in props.filterParams &&
                      props.filterParams.ascending === true
                    ) {
                      return val_a < val_b ? -1 : 1;
                    } else {
                      return val_a < val_b ? 1 : -1;
                    }
                  }
                }
                return 0;
              })
              .map((row: any, row_index: number) => (
                <tr
                  key={row_index}
                  onClick={e => {
                    props.onEvent({
                      message: "choose",
                      params: {
                        name: "item",
                        value: row
                      }
                    });
                  }}
                >
                  {props.columns.map((column: any, column_index: number) => {
                    // console.log(column);
                    return "detail" in column && column.detail ? (
                      <td
                        key={column_index}
                        style={{
                          textAlign: column.number ? "right" : "left"
                        }}
                      >
                        <Button
                          basic
                          color="teal"
                          style={{
                            backgroundColor: "white !important",
                            border: "1px solid",
                            fontWeight: "bold",
                            borderRadius: "20px"
                          }}
                          onClick={e => {
                            props.onEvent({
                              message: "detail",
                              params: {
                                name: "invoice",
                                value: row[column.key]
                              }
                            });
                          }}
                        >
                          Detail
                        </Button>
                      </td>
                    ) : (
                      <td
                        key={column_index}
                        style={{
                          color: column.key === "serial" ? "#2D9CDB" : "black",
                          textAlign: column.number ? "right" : "left",
                          padding: "20px 10px"
                        }}
                      >
                        {column.number
                          ? formatComma(extractValue(column, row))
                          : extractValue(column, row)}
                      </td>
                    );
                  })}
                </tr>
              ))}
          </tbody>
        </table>

        {/* Pagination */}
        <Pagination
          style={{ marginTop: "15px" }}
          activePage={props.filterParams.page}
          totalPages={props.numPage}
          defaultActivePage={1}
          boundaryRange={1}
          siblingRange={1}
          onPageChange={(e: SyntheticEvent, { activePage } : PaginationProps) => {
            e.persist()
            console.log("page:",activePage)
            props.onEvent({
              message: "filterSubmit",
              params: {
                ...props.filterParams,
                page: activePage,
              },
            });
          }}
        />
        {/* <Pagination
          numPage={props.numPage}
          page={props.filterParams.page}
          onSelectPage={(page: number) => {
            props.onEvent({
              message: "filterSubmit",
              params: {
                ...props.filterParams,
                page: page
              }
            });
          }}
        /> */}
      </AnalyticsLayout>
    </Dimmer.Dimmable>
  );
};

export default React.memo(InvoiceSummary);

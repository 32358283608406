import XLSX from "xlsx";
import {
  InvoiceList,
  InvoiceDetail,
  InvoiceExternalBack,
} from "../../react-lib/apis/issara/apps/TRK/INVInvoice";
import {
  EmployeeList,
  EmployeeSerializer,
  EmployeeCodeCheck,
} from "../../react-lib/apis/issara/apps/TRK/INVEmployeeExtra";
import { rejects } from "assert";

// Users
export const processEmployees = (arrayBuffer: ArrayBuffer) => {
  // const xlsx_data = XLSX.utils.sheet_to_json(data)
  const xl = XLSX.read(arrayBuffer, { type: "array" });
  const csv = XLSX.utils.sheet_to_csv(xl.Sheets[xl.SheetNames[0]]);
  const lines = csv.split("\n");
  const employees: EmployeeSerializer[] = lines
    .filter((item) => !isNaN(parseInt(item[0])))
    .map((item, index) => splitCSV(item))
    .map((item, index) =>
      item
        ? {
            number: item[0],
            employee_no: item[1],
            prename: item[2],
            first_name: item[3],
            last_name: item[4],
            email: item[5],
            username: item[6],
            phone_no: item[7],
            position: item[8],
            license_plate: item[9],
            car_type: item[10],
            route: item[11],
          }
        : {
            number: "",
            employee_no: "",
            prename: "",
            first_name: "",
            last_name: "",
            email: "",
            username: "",
            phone_no: "",
            position: "",
            license_plate: "",
            car_type: "",
            route: "",
          }
    );
  return employees;
};

// Internal Shipping
export interface InternalShipping {
  type: "internal";
  document_number: string;
  route: string;
  date: string;
  licence_plate: string;
  route_start_time: string;
  transport_id: number;
  transport_name: string;
  transport_employee_code: string;
  transport_assistant_name: string;
  transport_assistant_employee_code: string;
  invoices: any[];
}

export const initialInternalShipping: InternalShipping = {
  type: "internal",
  document_number: "",
  route: "",
  date: "",
  licence_plate: "",
  route_start_time: "",
  transport_id: 0,
  transport_name: "",
  transport_employee_code: "",
  transport_assistant_name: "",
  transport_assistant_employee_code: "",
  invoices: [],
};
export const processInternalShipping = (arrayBuffer: ArrayBuffer) => {
  // const xlsx_data = XLSX.utils.sheet_to_json(data)
  const xl = XLSX.read(arrayBuffer, { type: "array" });
  let transport_employee_code = ""
  let transport_name = ""
  const invSheet: any = [];
  var output: InternalShipping;
  xl.SheetNames.map((sheet_name, index) => {
  let csv = XLSX.utils.sheet_to_csv(xl.Sheets[xl.SheetNames[index]]);
  let lines = customSplit(csv)
  // let lines = csv.split(",\n");
  if (lines.length <= 2){
    return null;
  }
  if (!lines[0].includes("ใบรายงานการส่งมอบประจำวัน")) {
    alert("กรุณาเลือก ใบคุมขนส่ง ภายนอก")
    return null;
  }
  transport_employee_code = lines[3].split(",")[10];
  transport_name = lines[3].split(",")[8];
  // let transport_id = (await checkEmployeeIsValid(transport_employee_code, transport_name, apiToken))
  let invoices = lines
    .filter((item) => !isNaN(parseInt(item[0])))
    .map((item, index) => splitCSV(item))
    .map((item, index) => 
      item
        ?
          {
            number: item[0],
            verified: "B",
            invoice: item[1],
            customer: item[2],
            date: item[3],
            box_count: item[4],
            cost_per_box: item[5],
            note: item[11],
            transport_name: transport_name,
            transport_code: transport_employee_code,
            warning_note: "-",
          }
        : {
            number: "",
            verified: "",
            invoice: "",
            customer: "",
            date: "",
            box_count: "",
            cost_per_box: "",
            note: "",
            warning_note: "-",
          }
    );

  invoices.map((item, index) => {
    if (!item.transport_name || item.transport_name.trim() === "") {
      invoices[index].warning_note = "ไม่พบรายละเอียดพนักงานขนส่ง";
    }
    if (!item.transport_code || item.transport_code.trim() === ""){
      invoices[index].warning_note = "ไม่พบรหัสพนักงานขนส่ง";
    }
    invSheet.push(item)
  });
})
  invSheet.map((item :any, index: number) => {
    let existItem = invSheet.filter((o:any) => o.invoice === item.invoice);
    if (existItem.length > 1) {
      invSheet[index].warning_note = "Invoice ซ้ำ ภายในไฟล์";
    }
  });

  output = Object.assign({}, initialInternalShipping, {
    transport_employee_code: transport_employee_code,
    transport_name: transport_name,
    invoices: invSheet,
  });
  return output;
};

export const uploadInternalShipping = async (
  is: InternalShipping,
  apiToken: string,
  finishOneCallBack: (result: any) => void
) => {
  for (var invoice of is.invoices) {
    if (invoice.verified === true &&  invoice.transport_id) {
      if (invoice.status === "CREATED" || invoice.status === "TRANSPORTING") {
        const [r, e, n] = await InvoiceDetail.patch({
          pk: invoice.id,
          apiToken: apiToken,
          params: {
            import_file:true
          },
          data: {
            transport: invoice.transport_id,
            external: false,
            box_count: invoice.box_count,
            cost_per_box: invoice.cost_per_box,
            action: invoice.status === "CREATED" ? "TRANSPORT" : "INFO",
          },
        });
        if (e) {
          console.log(e);
          console.log(n);
          if (e.length > 0 && e[0].includes('ไม่มีสิทธิ์')){
            finishOneCallBack({ invoice: invoice, message: "เกิดข้อผิดพลาด", warning_note: e[0] });  
          }else{
            finishOneCallBack({ invoice: invoice, message: "เกิดข้อผิดพลาด", warning_note: "" });
          }
        } else {
          finishOneCallBack({ invoice: invoice, message: "สำเร็จ", warning_note: "" });
        }
      }else{
        finishOneCallBack({ invoice: invoice, message: "ไม่สำเร็จ", warning_note: "" });
      }
    }
  }
};

// External Shipping
export interface ExternalShipping {
  type: "external";
  shipping_company: string;
  invoices: any[];
}

export const initialExternalShipping: ExternalShipping = {
  type: "external",
  shipping_company: "",
  invoices: [],
};

export const processExternalShipping = (arrayBuffer: ArrayBuffer) => {
  // const xlsx_data = XLSX.utils.sheet_to_json(data)
  const xl = XLSX.read(arrayBuffer, { type: "array" });
  var output: ExternalShipping;
  let invSheet: any = [];
  let shipping_company: string = ""
  xl.SheetNames.map((sheet, index) => {
  let csv = XLSX.utils.sheet_to_csv(xl.Sheets[xl.SheetNames[index]]);
  let lines = customSplit(csv)

  // let lines = csv.split(",\n");
  console.log("processExternalShipping lines", lines)
  if (lines.length <= 2){
    return null;
  }
  if (lines[0].includes("ใบรายงานการส่งมอบประจำวัน")) {
    alert("กรุณาเลือก ใบคุมขนส่ง ภายใน")
    return null
  }
  if (lines.length > 0) {
    shipping_company = lines[0].split(",")[0];
  }
  console.log('Shipping Company', shipping_company)
  // console.log("lines", lines)
  if( lines.length > 1) { 
    var sending = lines[1]
    .split(",")
    .filter((item) => item.includes("วันที่ออกเดินทาง"));
    var sending_date =
      sending && sending.length > 0
        ? sending[0]
            .replace(/^(วันที่ออกเดินทาง)/, "")
            .replace(":", "")
            .replace(/  +/g, "")
        : "";

    const line1 = lines[1].split(",")
    var shipper = ""
    if (line1.length > 5) {
      shipper = line1[4]
    }
  } else {
    sending_date = ""
    shipper = ""
  }
 

  let invoices = lines
    .filter((item) => !isNaN(parseInt(item[0])))
    .map((item, index) => splitCSV(item))
    .map((item, index) =>
      item
        ? {
            number: item[0],
            invoice: item[1],
            shipper: shipper,
            delivery: item[2],
            customer: item[3],
            address: item[4],
            calc: item[5],
            box_count: item[6],
            cost_per_box: item[7],
            bundle_count: item[8],
            note: item[9],
            shipping_company: shipper,
            sending_date: sending_date,
            warning_note: "-",
          }
        : {
            number: "",
            invoice: "",
            shipper: "",
            delivery: "",
            customer: "",
            address: "",
            calc: "",
            box_count: null,
            cost_per_box: "",
            bundle_count: "",
            note: "",
            warning_note: "-",
          }
    ); 
  
  invoices.map((item, index) => {
    let existItem = invoices.filter((o) => o.invoice === item.invoice);
    if (existItem.length > 1) {
      invoices[index].warning_note = "Invoice ซ้ำ ภายในไฟล์";
    }
    invSheet.push(item)
  });
})

  output = Object.assign({}, initialExternalShipping, {
    shipping_company: shipping_company,
    invoices: invSheet,
    // shipper: shipper,
  });
  return output;
};

export const uploadExternalShipping = async (
  es: ExternalShipping,
  apiToken: string,
  finishOneCallBack: (result: any) => void
) => {
  const serial_list = es.invoices.map((item, index) => item.invoice);

  console.log("serial_list", serial_list);

  const [r, e, n] = await InvoiceList.list({
    params: {
      serial_list: serial_list + "",
      page_size: serial_list.length,
    },
    apiToken: apiToken,
  });
  if (e) {
    console.log(e);
    console.log(n);
    return null;
  }

  const matched_invoices = r.results;
  console.log("r", r);
  for (var invoice of matched_invoices) {
    const candidates = es.invoices.filter(
      (item) => item.invoice === invoice.serial
    );
    console.log("candidates", candidates);
    if (candidates.length > 0) {
      const item = candidates[0];
      console.log(item);
      if(invoice.status === "CREATED" || invoice.status === "TRANSPORTING"){
      const [r, e, n] = await InvoiceDetail.patch({
        pk: invoice.id,
        apiToken: apiToken,
        params: {
          import_file:true
        },
        data: {
          external: true,
          shipping_company: item.shipping_company,
          cost_per_box: item.cost_per_box,
          ...( !isNaN(parseInt(item.box_count)) && { box_count: parseInt(item.box_count)}),
          action: invoice.status === "CREATED"? "TRANSPORT": "INFO",
        },
      });
      if (e) {
        console.log(e);
        console.log(n);
        finishOneCallBack({ invoice: item, message: "เกิดข้อผิดพลาด" });
      } else {
        console.log(r);
        finishOneCallBack({ invoice: item, message: "สำเร็จ" });
      }
    }else{
      finishOneCallBack({ invoice: item, message: "ไม่สำเร็จ" });
    } 
  }else {
      console.log(
        "matched invoice count is not exactly one -> count = ",
        candidates.length
      );
    }
  }
};


export const customSplit = (csv: string):string[] => {


  let startIdx = 0 
  let foundDoubleQuote = false
  let result = []

  for (let index = 0; index < csv.length ; index++) {
    let letter = csv.charAt(index)

    if ( letter === '"' && foundDoubleQuote === false ) {
      // console.log("letter 1", letter)
      foundDoubleQuote = true
    } else  if ( letter === '"' && foundDoubleQuote === true ) {
      // console.log("letter 2", letter)
      foundDoubleQuote = false
    } else if ( letter === '\n' && foundDoubleQuote === false) { 
      // yes this is new line 
      let line =  csv.substring(startIdx, index)
      // console.log(" foundDoubleQuote", foundDoubleQuote, "startIdx,", startIdx, "index", index, "line", JSON.stringify(line))
      result.push(line )
      startIdx = index + 1 
    }

  }

  console.log("result", result)

  return result 

  // return [""]

}

// External Shipping Back
export const processExternalShippingBack = (arrayBuffer: ArrayBuffer) => {
  var output: ExternalShipping;
  let invSheet: any = [];
  const xl = XLSX.read(arrayBuffer, { type: "array" });
  xl.SheetNames.map((sheet, index) => {
    let csv = XLSX.utils.sheet_to_csv(xl.Sheets[xl.SheetNames[index]]);
    // let lines = csv.split("\n");
    let lines = customSplit(csv)
    if (lines.length <= 2){
      return null;
    }

    // if (lines[0].includes("ใบรายงานการส่งมอบประจำวัน")) {
    //   alert("กรุณาเลือก ใบคุมขนส่ง ภายใน")
    //   return null
    // }  
    let invoices = lines
      .filter((item) => !isNaN(parseInt(item[0])))
      .map((item, index) => splitCSV(item))
      .map((item, index) =>
        item
          ? {
              number: item[0],
              invoice: item[5].split("/")[0].trim(),
              delivery: item[18],
              customer: item[8],
              address: item[9],
              ref1: item[13],
              ref2: item[14],
              delivery_status: item[19],
              delivery_time: item[22],
              note: item[26],
              pic1: item[31],
              warning_note: "-",
            }
          : {
              number: "",
              invoice: "",
              delivery: "",
              customer: "",
              address: "",
              ref1: "",
              ref2: "",
              delivery_status: "",
              delivery_time: "",
              note: "",
              pic1: "",
              warning_note: "-",
            }
      );
    invoices.map((item, index) => {
      let existItem = invoices.filter((o) => o.invoice === item.invoice);
      if (existItem.length > 1) {
        invoices[index].warning_note = "Invoice ซ้ำ ภายในไฟล์";
      }
      invSheet.push(item)
    });
  })
  output = Object.assign({}, initialExternalShipping, {
    shipping_company: "",
    invoices: invSheet,
    // shipper: shipper,
  });
  return output;
};

export const uploadExternalShippingBack = async (
  es: ExternalShipping,
  apiToken: string,
  finishOneCallBack: (result: any) => void
) => {
  const serial_list = es.invoices.map((item, index) => item.invoice);

  console.log("serial_list", serial_list);

  const [r, e, n] = await InvoiceList.list({
    params: {
      serial_list: serial_list + "",
      page_size: serial_list.length,
    },
    apiToken: apiToken,
  });
  if (e) {
    console.log(e);
    console.log(n);
    return null;
  }

  const matched_invoices = r.results;
  console.log("r", r);
  for (var invoice of matched_invoices) {
    const candidates = es.invoices.filter(
      (item) => item.invoice === invoice.serial
    );
    console.log("candidates", candidates);
    if (candidates.length > 0) {
      const item = candidates[0];
      console.log(item);
      if(invoice.status === "TRANSPORTING"){
      const [r, e, n] = await InvoiceExternalBack.patch({
        apiToken: apiToken,
        data: {
          invoice: item.invoice,
          image_link: item.pic1,
        },
      });
      if (e) {
        console.log(e);
        console.log(n);
        finishOneCallBack({ invoice: item, message: "เกิดข้อผิดพลาด" });
      } else {
        console.log(r);
        finishOneCallBack({ invoice: item, message: "สำเร็จ" });
      }
    }else{
      finishOneCallBack({ invoice: item, message: "ไม่สำเร็จ" });
    } 
  }else {
      console.log(
        "matched invoice count is not exactly one -> count = ",
        candidates.length
      );
    }
  }
};

// Common
const splitCSV = (s: string) => {
  // console.log(" Check match ", s)
  var matches = s.match(/(\s*"[^"]+"\s*|\s*[^,]+|,)(?=,|$)/g);
  // console.log(" matches", matches)

  if (matches) {
    for (var n = 0; n < matches.length; ++n) {
      matches[n] = matches[n].trim();
      if (matches[n][0] === '"') {
        matches[n] = matches[n].replace('"', "");
      }
      if (matches[n] === ",") matches[n] = "";
    }
    if (s[0] === ",") matches.unshift("");
  }

  return matches;
};

export const readFileAsync = (file: File) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
};

export const checkBackend = async (
  shippingData: InternalShipping | ExternalShipping,
  isExternalBack: boolean,
  apiToken: string
) => {
  let employee: any = []
  if (shippingData.type === "internal") {
    
    shippingData.invoices.map(item => {
      if(employee.filter((o:any) => o.transport_code === item.transport_code).length === 0){
        employee.push({'transport_code': item.transport_code, 'transport_name': item.transport_name})
      }
    })
    employee.map(async(item:any) => {
      const [r, e, n] = await EmployeeCodeCheck.list({
        params: {
          code: item.transport_code,
          name: item.transport_name,
        },
        apiToken: apiToken,
      });
      if (e) {
        console.log("Error", e, n);
      } else {
        console.log(r);
        try {
          item.transport_id = r.items[0].employee;
        } catch {
          item.transport_id = 0;
        }
      }
    })
    
  }
  let invoiceSet:any = [];
  let invoiceSubSet:any[] = [];
  console.log("ALL Data:", shippingData.invoices)
  shippingData.invoices.forEach((item, index) => {
    console.log(item.invoice, index, shippingData.invoices.length-1)
    invoiceSubSet.push(item.invoice)
    if(index > 0 && index % 100 === 0){
      invoiceSet.push(invoiceSubSet)
      invoiceSubSet = []
    }
    if((shippingData.invoices.length-1) === index){
      invoiceSet.push(invoiceSubSet)
    }
  })
  // const serial_list = shippingData.invoices.map((item, index) => item.invoice);
  let itemBackend:any = [];
  const invoicePromise:any = await (
    Promise.all(
      invoiceSet.map(async(item:any)=> {
      const [r, e, n] = await InvoiceList.list({
        params: {
          serial_list: item + "",
          page_size: item.length,
        },
        apiToken: apiToken,
      });
      if (e) {
        console.log("Error", e, n);
      } else {
        console.log(r.results);
        r.results.forEach((item:any)=> {
          itemBackend.push(item)
        })
      }
    })
  ).then(() => {
    console.log("set:", itemBackend)
  for (var item of shippingData.invoices) {
    console.log("set:", itemBackend)
    const candidates = itemBackend.filter(
      (backend: any) => backend.serial === item.invoice
    );
    console.log("Invoice:", item, candidates)
    if (candidates.length > 0 && !isExternalBack) {
      item.verified = true;
      item.id = candidates[0].id;
      item.status = candidates[0].status;
      item.upload_result =
        candidates[0].status === "CREATED" ? "ยังไม่มี" : "มีแล้ว";
      item.warning_note =
        item.warning_note !== "-"
          ? item.warning_note
          : (candidates[0].status === "TRANSPORTING"
          ? "Invoice No. ซ้ำในระบบ"
          : (candidates[0].status === "CREATED"
          ? "-"
          : "Invoice ถูกดำเนินการแล้ว"));
      item.status_label = candidates[0].status_label;
      item.billing_type_label = candidates[0].billing_type_label;
      item.transport_id = employee.filter((e:any) => e.transport_name === item.transport_name).length > 0? 
      employee.filter((e:any) => e.transport_name === item.transport_name)[0].transport_id : null
      console.log(item.status_label)
    }else if(candidates.length > 0){
      item.verified = true;
      item.id = candidates[0].id;
      item.status = candidates[0].status;
      item.upload_result =
        candidates[0].status === "TRANSPORTING" ? "ยังไม่อัพเดท" : "กรุณาตรวจสอบหมายเหตุ";
      item.warning_note =
        item.warning_note !== "-"
          ? item.warning_note
          : candidates[0].status === "CREATED"
          ? "Invoice อยู่ในสถานะ รอส่งสินค้า"
          : candidates[0].status === "TRANSPORTING"
          ? "-"
          : "Invoice ถูกดำเนินการแล้ว";
      item.status_label = candidates[0].status_label;
      item.billing_type_label = candidates[0].billing_type_label;
    }
    else {
      item.verified = false;
      item.warning_note = "ไม่พบ Invoice ในระบบ"
      item.id = null;
      item.status = null;
      item.status_label = null;
    }
  }
})
  )
  return shippingData;
};

export const checkEmployees = async (
  employees_upload: any[],
  apiToken: string
) => {
  const employee_no_list = employees_upload.map(
    (item, index) => item.employee_no
  );
  const [r, e, n] = await EmployeeList.list({
    params: {
      employee_no_list: employee_no_list + "",
      page_size: employee_no_list.length,
    },
    apiToken: apiToken,
  });
  if (e) {
    console.log("Error", e, n);
  } else {
    console.log(r);
    for (var item of employees_upload) {
      const candidates = r.items.filter(
        (backend: any) => backend.employee_no === item.employee_no
      );
      if (candidates.length > 0) {
        item.verified = true;
        item.verified_label = "มีในฐานข้อมูล";
        item.id = candidates[0].id;
      } else {
        item.verified = false;
        item.verified_label = "ไม่มีในฐานข้อมูล";
        item.id = null;
      }
    }
  }
  return employees_upload;
};

// Export
export const exportExcel = (
  title: any,
  data: any,
  tableDom: React.RefObject<HTMLTableElement>,
  filename: string
) => {
  var output = [];
  output.push(title);
  for (const item of data) {
    output.push(Object.values(item));
  }
  var ws = XLSX.utils.aoa_to_sheet(output);
  var html_string = XLSX.utils.sheet_to_html(ws, {
    id: "data-table",
    editable: false,
  });
  if (tableDom && tableDom.current) {
    tableDom.current.innerHTML = html_string;
  }
  var wb = XLSX.utils.table_to_book(tableDom.current);
  XLSX.writeFile(wb, `${filename}.xlsx`);
};

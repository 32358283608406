import React, { useState } from 'react'
import MainLayout from './Sub/MainLayout';
import { SAPImport, SAPClearing, SAPOutstanding } from '../react-lib/apis/issara/apps/TRK/INVmanual'

export default (props: any) => {
    const [file, setFile] = useState("")

    const handleEvent = async ({message, params}:{message: string, params: any}) => {
        console.log(message, params)
        if (message === "clickButton" && params.name === "chooseFile") {
            try {
                const data = await readFileAsync(params.value.files[0])
                const [r, e, n] = await SAPImport.post({
                    data: {
                        sap_data: data
                    },
                    apiToken: props.apiToken
                })
                if (e) {
                    console.log(e, n)
                    return
                }
                console.log(r)
                setFile("")
            } catch {}
        }else if(message === "clickButton" && params.name === "chooseFileClearing"){
            try {
                const data = await readFileAsync(params.value.files[0])
                const [r, e, n] = await SAPClearing.post({
                    data: {
                        sap_data: data
                    },
                    apiToken: props.apiToken
                })
                if (e) {
                    console.log(e, n)
                    return
                }
                console.log(r)
                setFile("")
            } catch {}
        }else if(message === "clickButton" && params.name === "chooseFileOutStanding"){
            try {
                const data = await readFileAsync(params.value.files[0])
                const [r, e, n] = await SAPOutstanding.post({
                    data: {
                        sap_data: data
                    },
                    apiToken: props.apiToken
                })
                if (e) {
                    console.log(e, n)
                    return
                }
                console.log(r)
                setFile("")
            } catch {}
        }
    }

    const readFileAsync = (file: File) => {
        return new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.onload = () => {
            resolve(reader.result);
          };
          reader.onerror = reject;
          reader.readAsText(file, 'TIS-620');
        })
    }
    
    const itemStyle = { margin: "10px" }

    return(
    <MainLayout
        selectedCard="Debug"
        login_employee={props.login_employee}
        onEvent={props.onEvent}>
        <div style={{margin: "20px", cursor: "pointer", display: "flex", 
                     flexDirection: "column", justifyContent: "space-between"}}>
            
            <div>SAP Import
                <input type="file" value={file} style={itemStyle}
                onChange={(e) => {
                    handleEvent({
                        message: 'clickButton',
                        params: {
                            name: 'chooseFile',
                            value: e.target
                        }
                })}}
            />
            </div>
            <div>SAP Clearing
                <input type="file" value={file} style={itemStyle}
                onChange={(e) => {
                    handleEvent({
                        message: 'clickButton',
                        params: {
                            name: 'chooseFileClearing',
                            value: e.target
                        }
                })}}
            />
            </div>
            <div>SAP OutStanding
                <input type="file" value={file} style={itemStyle}
                onChange={(e) => {
                    handleEvent({
                        message: 'clickButton',
                        params: {
                            name: 'chooseFileOutStanding',
                            value: e.target
                        }
                })}}
            />
            </div>
            <div style={itemStyle}>ลบ Invoice ทั้งหมด</div>
        </div>
        
    </MainLayout>
    )
}


import React, {useState, useEffect, SyntheticEvent} from "react";
import AnalyticsLayout from "./AnalyticsLayout";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import dateFnsFormat from "date-fns/format";
import dateFnsParse from "date-fns/parse";
import {STATUS} from "../../Common"
import formatComma from "../../react-lib/utils/formatComma";

import { Input, Button, Select, Dropdown, DropdownProps, Dimmer, Loader, Icon, 
  Pagination, PaginationProps } from "semantic-ui-react";

const FORMAT = "dd/MM/yyyy";
const FORMATEDIABLE = "dd/MM/y";


const SubSummaryTable = (props: {
  sub: string;
  title: string;
  login_employee: any;
  viewData: any[];
  columns: any[];
  filters: any[];
  filterParams: any;
  numPage: number;
  onExportAll?: ({ exportAll }: { exportAll: boolean }) => {};
  onEvent: ({ message, params }: { message: string; params: any }) => void;
  exportAllLoading: boolean;
  exportExcelLoading: boolean;
}) => {
  return (
    <AnalyticsLayout
      sub={props.sub}
      onEvent={props.onEvent}
      login_employee={props.login_employee}
      exportAllLoading={props.exportAllLoading}
      exportExcelLoading={props.exportExcelLoading}
      onExportAll={() => {if(props.onExportAll) props.onExportAll({ exportAll: true })}}
    >
      <SummaryTable {...props} />
    </AnalyticsLayout>
  );
};

SubSummaryTable.defaultProps = {
  exportAllLoading: false,
  exportExcelLoading: false,
}

export default SubSummaryTable;

export const SummaryTable = (props: any) => { 

  const [statusOption, setStatusOptions] = useState<{key: string; value: string; text: string}[]>([])
  const [billingExpandInvoice, setBillingExpandInvoice] = useState<number[]>([])

  console.log(" SummaryTable props:", props) 

  useEffect(()=>{
    const prepare = Object.keys(STATUS).map((item) => {
      return {
        key: item as string,
        value: STATUS[item] as string,
        text: STATUS[item] as string,
      }
    })
    prepare.unshift({key: "ALL", value: "เลือกทั้งหมด", text: "เลือกทั้งหมด" })
    setStatusOptions(prepare)
  },[])

  return (
    <Dimmer.Dimmable>
  <div style={{ margin: "20px" }}>
    {/* Filter */}
    <Dimmer active={props.loading} inverted>
        <Loader inverted />
      </Dimmer>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <h2 style={{ marginRight: "50px", height: "20px" }}>{props.title}</h2>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          fontWeight: "bolder",
        }}
      >
        {props.filters.map((filter: any, filter_index: number) => (
          <div
            key={filter_index}
            // style={{ display: "flex", alignItems: "center", padding: "10px", whiteSpace: "nowrap" }}
            style={{ minWidth: filter.type !== "Dropdown" ?"2 0%" : "40%", whiteSpace: "nowrap", display: "flex", alignItems: "center", padding: "10px" }}
          >
            {filter.label && (
              <div
                style={{
                  marginRight: "4px",
                  fontSize: "16px",
                  width: filter.type !== "Dropdown" ? "100%" : "24%",
                }}
              >
                {filter.label}&ensp;
              </div>
            )}

            {filter.type === "Input" ? (
              <Input
                fluid
                style={{ minWidth: "200px" }}
                value={props.filterParams[filter.key]}
                onChange={(e, v) =>
                  props.onEvent({
                    message: "filterChange",
                    params: {
                      ...props.filterParams,
                      [filter.key]: v.value,
                    },
                  })
                }
                onKeyPress={(e: any) => {
                  if (e.key === "Enter") {
                    props.onEvent({
                      message: "filterSubmit",
                      params: props.filterParams,
                    });
                  }
                }}
              />
            ) : ["DayPickerInput", "DayPickerSubmit"].includes(filter.type) ? (
              <DayPickerInput
                inputProps={{ style: {height: "38px",
                  border: "1px solid lightgray",
                  borderRadius: "3px",
                  paddingLeft: "8px"}
                }}
                style={{ marginLeft: "15px", marginRight: "15px" }}
                value={props.filterParams[filter.key]}
                formatDate={(date, format) => dateFnsFormat(date, format)}
                format={FORMATEDIABLE}
                parseDate={(str, format) => {
                  const parsed = dateFnsParse(str, format, new Date());
                  if (DateUtils.isDate(parsed)) {
                    return parsed;
                  }
                  return undefined;
                }}
                placeholder={`${dateFnsFormat(new Date(), FORMAT)}`}
                onDayChange={(day) => {
                  let parsedDate: any = day;
                  if(day){
                    
                    parsedDate = dateFnsFormat(day, FORMATEDIABLE);
                  }
                  props.onEvent({
                    message:
                      filter.type === "DayPickerInput"
                        ? "filterChange"
                        : "filterSubmit",
                    params: {
                      ...props.filterParams,
                      [filter.key]: parsedDate,
                    },
                  });
                }}
              />
            ) : filter.type === "MonthPickerSubmit" ? (
              <Select
                options={getYMOptions()}
                value={props.filterParams[filter.key]}
                onChange={(e: any, v: any) => {
                  props.onEvent({
                    message: "filterSubmit",
                    params: {
                      ...props.filterParams,
                      [filter.key]: v.value,
                    },
                  });
                }}
              />
            ) : filter.type === "Dropdown" ? (
              <Dropdown
                placeholder="เลือก Status..."
                fluid
                search
                selection
                value={props.filterParams[filter.key] === "" ? "เลือกทั้งหมด" : STATUS[props.filterParams[filter.key]]}
                options={filter.key === "status" ? statusOption : []}
                onChange={(e: SyntheticEvent, v: DropdownProps) => {

                  let chooseStatus = statusOption.find(item => item.value === v.value )?.key
                  if (chooseStatus === "ALL") {
                    props.onEvent({
                      message: "filterChange",
                      params: {
                        ...props.filterParams,
                        [filter.key]: "",
                      }
                    })
                  } else {
                    props.onEvent({
                      message: "filterChange",
                      params: {
                        ...props.filterParams,
                        [filter.key]: chooseStatus,
                      }
                    })
                  }
                  
                }}
              />
            ) : filter.type === "Submit" ? (
              <Button
                style={{ marginLeft: "15px", marginRight: "15px" }}
                color={filter.color}
                onClick={(e) => {
                  props.onEvent({
                    message: "filterSubmit",
                    params: {
                      ...props.filterParams,
                      page: 1
                    }
                  });
                }}
              >
                {filter.buttonLabel}
              </Button>
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>
    </div>

    {/* Table head */}
    <div style={{ overflow: "auto" }}>
    <table className="sub-summary-table" style={{ width: "100%", borderCollapse: "collapse" }}>
      <thead>
        <tr>
        {/* <div style={{ display: "flex", cursor: "pointer", borderBottom: "1px solid #4F4F4F" }}> */}
          {props.columns.map((column: any, column_index: number) => (
            <th
              key={column_index}
              style={{
                // flex: 1,
                // fontSize: "15px",
                // marginRight: "15px",
                textAlign: column.number ? "right" : "left",
                whiteSpace: "nowrap",
                padding: "10px 10px",
                borderBottom: "1px solid"
                // padding: "10px 0px"
              }}
              onClick={(e: any) => {
                console.log(column.key);
                props.onEvent({
                  message: "filterSubmit",
                  params: {
                    ...props.filterParams,
                    sortby: column.key,
                    page: 1,
                    ascending:
                      column.key === props.filterParams.sortby
                        ? !props.filterParams.ascending
                        : props.filterParams.ascending,
                  },
                });
              }}
            >
              <b>{column.name}</b>
              { !("expand" in column && column.expand) && <Icon name="sort" /> }
            </th>
          ))}
        </tr>
        </thead>
        {/* </div> */}

        {/* Table content */}
        <tbody>
        {props.viewData
          .sort((a: any, b: any) => {
            if (
              "sortby" in props.filterParams &&
              props.filterParams.sortby !== ""
            ) {
              const candidates = props.columns.filter(
                (item: any) => item.key === props.filterParams.sortby
              );
              if (candidates.length > 0) {
                const val_a = extractValue(candidates[0], a);
                const val_b = extractValue(candidates[0], b);
                if (
                  "ascending" in props.filterParams &&
                  props.filterParams.ascending === true
                ) {
                  return val_a < val_b ? -1 : 1;
                } else {
                  return val_a < val_b ? 1 : -1;
                }
              }
            }
            return 0;
          })
          .map((row: any, row_index: number) => (
            <tr
              key={row_index}
              // style={{ display: "flex", padding: "20px 0px" }}
              onDoubleClick={(e) => {
                props.onEvent({
                  message: "choose",
                  params: {
                    name: "item",
                    value: row,
                  },
                });
              }}
            >
              {props.columns.map((column: any, column_index: number) =>
                "detail" in column && column.detail ? (
                  <td
                    key={column_index}
                    style={{
                      // flex: 1,
                      // marginRight: "15px",
                      // overflow: "hidden",
                      // fontSize: "15px",
                      color: column.key === "serial" ? "#2D9CDB" : "black",
                      padding: "20px 10px",
                      textAlign: column.number ? "right" : "left",
                    }}
                  >
                    <Button
                      basic
                      color="blue"
                      onClick={(e) => {
                        props.onEvent({
                          message: "detail",
                          params: {
                            name: "invoice",
                            value: row[column.key],
                          },
                        });
                      }}
                    >
                      Action
                    </Button>
                  </td>
                ) : "twoline" in column && column.twoline ? ( 
                  <td> 
                    { billingExpandInvoice.includes(row.id) ? 
                      (<> full Detail <br/> full detail <br/> full detail <br/>  full detail <br/> full detail <br/> full detail <br/>  full detail </>) : (<> only twoline <br/> only twoline </> ) }
                  </td>
                ) : "expand" in column && column.expand ? (
                  <td onClick={() => {
                    if (billingExpandInvoice.includes(row.id)) {
                      var remove = billingExpandInvoice.filter((item:number) => item !== row.id)
                      setBillingExpandInvoice(remove)
                    } else {
                      var add = [...billingExpandInvoice, row.id ]
                      setBillingExpandInvoice(add) 
                    }
                    
                  }}> 
 
                    <Icon name={ billingExpandInvoice.includes(row.id) ? "triangle up" : "triangle down" } />
             
                  </td> 
                )
                : "modal" in column && column.modal ? (
                <td key={column_index} onClick={(e) => {
                  props.onEvent({
                    message: "modalBillingNote",
                    params: {
                      name: "invoice",
                      value: row['id'],
                    },
                  });
                }}>
                  {extractValue(column, row) === 'ไม่ระบุ'? "-" : extractValue(column, row)}
                </td>):( 
                  <td
                    key={column_index}
                    style={{
                      // flex: 1,
                      // marginRight: "15px",
                      // overflow: "hidden",
                      // fontSize: "15px",
                      color: column.key === "serial" ? "#2D9CDB" : "black",
                      padding: "20px 10px",
                      whiteSpace: "nowrap",
                      textAlign: column.number ? "right" : "left",
                    }}
                  >
                    { column.number ? formatComma(extractValue(column, row)) : extractValue(column, row) }
                  </td>
                )
              )}
            </tr>
          ))}
          </tbody>
      </table>
      </div>

    {/* Pagination */}
    {/* <Pagination
      numPage={props.numPage}
      page={props.filterParams.page}
      onSelectPage={(page: number) => {
        props.onEvent({
          message: "filterSubmit",
          params: {
            ...props.filterParams,
            page: page,
          },
        });
      }}
    /> */}
    <Pagination
      style={{ marginTop: "15px" }}
      activePage={props.filterParams.page}
      totalPages={props.numPage}
      defaultActivePage={1}
      boundaryRange={1}
      siblingRange={1}
      onPageChange={(e: SyntheticEvent, { activePage } : PaginationProps) => {
        e.persist()
        console.log("page:",activePage)
        props.onEvent({
          message: "filterSubmit",
          params: {
            ...props.filterParams,
            page: activePage,
          },
        });
      }}
    />
  </div>
  </Dimmer.Dimmable>
)};

const extractValue = (column: any, row: any) => {
  if (!column.key.includes(".")) {
    if (column.key in row && row[column.key] !== null)
      if (column.number) {
        try {
          return Number(row[column.key]);
        } catch (e) {
          return row[column.key].toString();
        }
      } else return row[column.key].toString();
    else return "ไม่ระบุ";
  } else {
    var obj = row[column.key];
    if (obj === undefined){
      var obj = row
      const keys = column.key.split(".");
      for (const key of keys) {
        // obj = obj[key];
        obj = obj[key]
      }
  }
    if (obj !== null && obj !== undefined)  {
      if (column.number) {
        try {
          return Number(obj);
        } catch {
          return obj.toString();
        }
      } else { 
        return obj.toString(); 
      }
    }
    else { 
      return "ไม่ระบุ";
    }
  }
};



// const Pagination = (props: any) => {

//   // console.log('Pagination props', props)

//   const len = Math.min(10, props.numPage - props.page + 1);
//   const style = (n: number) => ({
//     backgroundColor: props.page === n ? "#00529C" : "grey",
//     color: "white",
//     margin: "5px",
//     padding: "5px",
//     cursor: "pointer",
//   });
//   return (
//     <div style={{ display: "flex", marginLeft: "15px" }}>
//       <div style={style(0)} onClick={(e) => props.onSelectPage(1)}>
//         &lt;&lt;
//       </div>
//       <div
//         style={style(0)}
//         onClick={(e) => props.onSelectPage(Math.max(1, props.page - 10))}
//       >
//         &lt;
//       </div>
//       {props.numPage > 0 &&
//         Array.from({ length: len }, (x, i) => i + props.page).map(
//           (n, index) => (
//             <div
//               key={index}
//               style={style(n)}
//               onClick={(e) => props.onSelectPage(n)}
//             >
//               {n}
//             </div>
//           )
//         )}
//       <div
//         style={style(0)}
//         onClick={(e) =>
//           props.onSelectPage(Math.min(props.numPage, props.page + 10))
//         }
//       >
//         &gt;
//       </div>
//       <div style={style(0)} onClick={(e) => props.onSelectPage(props.numPage)}>
//         &gt;&gt;
//       </div>
//     </div>
//   );
// };

const getYMOptions = () => {
  const d = new Date();
  const ycurrent = d.getFullYear();
  const mcurrent = d.getMonth() + 1;
  console.log(ycurrent, mcurrent);
  var YMOptions = [];
  for (var y = 2020; y <= ycurrent; y++) {
    for (
      var m = 1;
      (y < ycurrent && m <= 12) || (y === ycurrent && m <= mcurrent);
      m++
    ) {
      YMOptions.push(y.toString() + "-" + m.toString().padStart(2, "0"));
    }
  }
  console.log(YMOptions);
  const output = YMOptions.sort((a: any, b: any) => (a < b ? 1 : -1)).map(
    (item: any, index) => ({
      key: index,
      value: item,
      text: item,
    })
  );
  console.log(output);
  return output;
};

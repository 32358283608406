import React from 'react'
import { withCookies } from 'react-cookie';
import {
   Button,
} from 'semantic-ui-react'

import config from "../../config/config";
import styles from './INTRegisterDone.module.css'

const MPC = "/static/images/logo_mpc.png";
const ANB ="/static/images/logo_anb.png";

const correctMark = 
  config.COMPANY === "MPC"
    ? "/static/images/check-Medic-Pharma.png"
    : "/static/images/check-Medic-Pharma.png"
  

export interface INTRegisterDoneViewDataType {
}

export const INTRegisterDoneInitialViewData: INTRegisterDoneViewDataType = {
}

const Sub = (props: any) => {
    const children = React.Children.toArray(props.children)
    return (
        <div className={styles.container}>
            <div className={styles.Item1}>
                <div
                    style={{textAlign : "center", height: "100%", width: "100%"}}
                >
                    <img style={{ height: "100%" }} src={props.cookies.get('hostName') === "ANB"? `${process.env.PUBLIC_URL +ANB}` : `${process.env.PUBLIC_URL +MPC}`} />
                </div>
            </div>
            <div className={styles.Item4}>
                <div
                    style={{textAlign : "center", color: "#00529C", height: "100%", width: "100%"}}
                >
                    <h1><strong>ระบบ Invoice Tracking</strong></h1>
                </div>
            </div>
            <div className={styles.Item5}>
                <div
                    style={{textAlign : "center", color: "#00529C", height: "100%", width: "100%"}}
                >
                    <img style={{ height: "100%" }} src={correctMark} />
                </div>
            </div>
            <div className={styles.Item8}>
                <div
                    style={{textAlign : "center", height: "100%", width: "100%"}}
                >
                    <h4><strong>ลงทะเบียนสำเร็จ</strong></h4>
                </div>
            </div>
            <div className={styles.Home}>
                <Button
                    disabled={ props.viewData.HomeDisabled }
                    onClick={(e) => { props.onEvent({
                        message: 'clickButton',
                        params: {
                            view: 'INTRegisterDone',
                            name: 'Home'
                        }
                    })}}
                    style={{textAlign : "center", backgroundColor:"#00529C", color: "White", height: "50%" , width: "100%"}}
                >
                    กลับสู่หน้าหลัก
                </Button>
            </div>
        </div>
    )
}

export default withCookies(Sub)

const getTsName = (cssClass: string) => {
    return cssClass.split('-').map((s) => (
        s.charAt(0).toUpperCase() + s.slice(1)
    )).join('')
}

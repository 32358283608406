import React, { useEffect, useState } from "react";
import "./INTLoginPage.css"
import { Input, Button, Icon, Label } from "semantic-ui-react";
import { withCookies } from "react-cookie";
import styles from "./INTLoginPage.module.css";

const MPC = "/static/images/logo_mpc.png";
const ANB ="/static/images/logo_anb.png";

const circle = '/static/images/Ellipse.png'
const circleCheck = '/static/images/EllipseChecked.png'

export interface INTLoginPageViewDataType {
  Username: string;
  UsernameDisabled: boolean;
  Password: string;
  PasswordDisabled: boolean;
}

export const INTLoginPageInitialViewData: INTLoginPageViewDataType = {
  Username: "",
  UsernameDisabled: false,
  Password: "",
  PasswordDisabled: false
};

const Sub = (props: any) => {
  const [showPassword, setShowPassword] = useState(false);
  const [companyCheck, setCompanyCheck] = useState("");

  const children = React.Children.toArray(props.children);

  return (
    <div className={styles.container}>
      <div className={styles.Item1}>
        <div style={{ textAlign: "center", height: "100%", width: "100%" }}>
        <h2 style={{color: "#00529C"}}>กรุณาเลือกบริษัทก่อนลงชื่อเข้าใช้งาน</h2>
        <div style={{display:"flex", flexDirection:'row', justifyContent: "center"}}>
          <Button className="ImageButton" 
          onClick={() => {
            if(companyCheck !== "MP"){
              setCompanyCheck("MP")
              props.cookies.set("hostName", "MPC")
              props.onHostnameChange("MPC")
              // window.location.hostname = hostName.MPC;
              // config.changeDomain("MPC")
            }else{
              setCompanyCheck("")
            }
            }}>
            <img style={{paddingRight: "5px"}}
            src={companyCheck === "MP"? process.env.PUBLIC_URL + circleCheck:process.env.PUBLIC_URL + circle}/>
            <img style={{ height: "75px" }}src={process.env.PUBLIC_URL + MPC} />
            </Button>
          <Button className="ImageButton" 
          onClick={() => {
            if(companyCheck !== "ANB"){
              setCompanyCheck("ANB")
              props.cookies.set("hostName", "ANB")
              props.onHostnameChange("ANB")
              // config.changeDomain("ANB")
              // window.location.hostname = hostName.ANB;
            }else{
              setCompanyCheck("")
            }
          }}>
            <img style={{paddingRight: "5px"}} 
            src={companyCheck === "ANB"? process.env.PUBLIC_URL + circleCheck:process.env.PUBLIC_URL + circle} />
            <img style={{ height: "100px" }} src={process.env.PUBLIC_URL + ANB} />
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.Item4} style={{paddingTop: "20px"}}>
        <div
          style={{
            textAlign: "center",
            color: "#00529C",
            height: "100%",
            width: "100%"
          }}
        >
          <h1>ระบบ Invoice Tracking</h1>
        </div>
      </div>
      <div className={styles.Username}>
        <Input
          value={props.viewData.Username}
          icon
          iconPosition="left"
          disabled={props.viewData.UsernameDisabled || companyCheck === ""}
          placeholder="ชื่อผู้ใช้ / เบอร์มือถือ"
          onChange={e => {
            props.onEvent({
              message: "inputChange",
              params: {
                view: "INTLoginPage",
                name: "Username",
                value: e.target.value
              }
            });
          }}
          style={{ height: "90%", width: "100%" }}
        >
          <input />
          <Icon name="user" color="blue" />
        </Input>
      </div>
      <div className={styles.Password}>
        <Input
          value={props.viewData.Password}
          icon
          iconPosition="left"
          disabled={props.viewData.PasswordDisabled  || companyCheck === ""}
          placeholder="รหัสผ่าน"
          onChange={e => {
            props.onEvent({
              message: "inputChange",
              params: {
                view: "INTLoginPage",
                name: "Password",
                value: e.target.value
              }
            });
          }}
          onKeyPress={(e: any) => {
            if (e.key === "Enter") {
              props.onEvent({
                message: "clickButton",
                params: {
                  view: "INTLoginPage",
                  name: "Login"
                }
              });
            }
          }}
          style={{ height: "300%", width: "100%" }}
        >
          <input type={showPassword ? "text" : "password"} />
          <Icon name="lock" color="blue" />
          <Icon
            style={{
              right: "0px",
              left: "auto",
              cursor: "pointer",
              zIndex: 100
            }}
            link
            name={showPassword ? "eye" : "eye slash"}
            color="grey"
            onClick={() => setShowPassword(!showPassword)}
          />
        </Input>
      </div>
      <div className={styles.Login}>
        <Button
          disabled={props.viewData.LoginDisabled || companyCheck === ""}
          onClick={e => {
            props.onEvent({
              message: "clickButton",
              params: {
                view: "INTLoginPage",
                name: "Login"
              }
            });
          }}
          style={{
            backgroundColor: "#00529C ",
            color: "white",
            border: "solid #247DAF 2px",
            height: "100%",
            width: "100%"
          }}
        >
          เข้าสู่ระบบ
        </Button>
      </div>
      <div className={styles.Register}>
        <Button
          disabled={props.viewData.RegisterDisabled || companyCheck === ""}
          onClick={e => {
            props.onEvent({
              message: "clickButton",
              params: {
                view: "INTLoginPage",
                name: "Register"
              }
            });
          }}
          style={{
            backgroundColor: "White ",
            color: "#247DAF",
            border: "solid #247DAF 2px",
            height: "100%",
            width: "100%"
          }}
        >
          ลงทะเบียน
        </Button>
      </div>
      <div className={styles.Item17}>
        <Button
          disabled={props.viewData.Item17Disabled || companyCheck === ""}
          onClick={props.onForgetPassword}
          // onClick={(e) => { props.onEvent({
          //     message: 'clickButton',
          //     params: {
          //         view: 'INTLoginPage',
          //         name: 'Item17'
          //     }
          // })}}
          style={{
            backgroundColor: "White",
            color: "#00529C",
            height: "100%",
            width: "100%"
          }}
        >
          ลืมรหัสผ่าน
        </Button>
      </div>
    </div>
  );
};

export default withCookies(Sub);

const getTsName = (cssClass: string) => {
  return cssClass
    .split("-")
    .map(s => s.charAt(0).toUpperCase() + s.slice(1))
    .join("");
};

import React, { useState } from "react";
import SubShippingSummary from "./Sub/SubShippingSummary";
// import SubDuplicateInvoice from './draft/SubDuplicateInvoice'
// import SubImportSuccess from './draft/SubImportSuccess'
import {
  InternalShipping,
  initialInternalShipping,
  processInternalShipping,
  uploadInternalShipping,
  ExternalShipping,
  initialExternalShipping,
  processExternalShipping,
  uploadExternalShipping,
  readFileAsync,
  checkBackend,
  processExternalShippingBack,
  uploadExternalShippingBack,
} from "./Sub/Excel";
import { Modal, Button, Input } from "semantic-ui-react";

import * as CONSTANT from "../react-lib/utils/constant";
import { EmployeeDetailFromCode } from "../react-lib/apis/issara/apps/TRK/INVEmployee";
import {
  InvoiceList,
  InvoiceDetail,
} from "../react-lib/apis/issara/apps/TRK/INVInvoice";

interface ModalMessage {
  title: string;
  messages: string;
}
class Card extends React.Component<
  {
    onEvent: ({ message, params }: { message: string; params: any }) => void;
    getApiToken: () => string;
    login_employee: any;
  },
  {
    internal: boolean;
    externalBack: boolean;
    uploadResultType: string;
    openModal: boolean;
    modalMessages: ModalMessage;
    sub: string;
    esData: ExternalShipping;
    isData: InternalShipping;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      internal: true,
      externalBack: false,
      openModal: false,
      uploadResultType: "",
      modalMessages: {
        title: "",
        messages: "",
      },
      sub: "SubShippingSummary",
      esData: Object.assign({}, initialExternalShipping),
      isData: Object.assign({}, initialInternalShipping),
    };
  }
  handleModalClose = () => {
    if (
      this.state.uploadResultType === "data_exist" ||
      this.state.uploadResultType === "on_proceed"
    ) {
      if (this.state.externalBack){
        uploadExternalShippingBack(
          this.state.esData,
          this.props.getApiToken(),
          (result) => {
            const newEsData = Object.assign({}, { ...this.state.esData });
            newEsData.invoices = newEsData.invoices.map((item) => {
              if (item.id === result.invoice.id) {
                return { ...item, upload_result: result.message };
              } else {
                return item;
              }
            });
            this.setState({ esData: newEsData });
          }
        );
      }else if (this.state.internal) {
        uploadInternalShipping(
          this.state.isData,
          this.props.getApiToken(),
          (result) => {
            const newIsData = Object.assign({}, { ...this.state.isData });
            newIsData.invoices = newIsData.invoices.map((item) => {
              if (item.id === result.invoice.id) {
                return { ...item, upload_result: result.message, warning_note: result.warning_note };
              } else {
                return item;
              }
            });
            this.setState({ isData: newIsData });
          }
        );
      } else {
        uploadExternalShipping(
          this.state.esData,
          this.props.getApiToken(),
          (result) => {
            const newEsData = Object.assign({}, { ...this.state.esData });
            newEsData.invoices = newEsData.invoices.map((item) => {
              if (item.id === result.invoice.id) {
                return { ...item, upload_result: result.message };
              } else {
                return item;
              }
            });
            this.setState({ esData: newEsData });
          }
        );
      }
    }
    this.setState({
      openModal: false,
      uploadResultType: "",
      modalMessages: {
        title: "",
        messages: "",
      },
    });
  };

  handleCheckData = ({ data }: { data: any }) => {
    const newIsData = Object.assign({}, { ...data });
    let checkData = newIsData.invoices.filter(
      (item: any) => item.warning_note !== "-"
    );
    let allData = checkData.filter(
      (item: any) => item.warning_note === "ไม่พบรายละเอียดพนักงานขนส่ง"
    );
    if (allData.length !== 0) {
      return {
        result: false,
        type: "no_transporter",
        message: "ไม่พบรายละเอียดพนักงานขนส่ง",
      };
    }
    allData = checkData.filter(
      (item: any) => item.warning_note === "ไม่พบรหัสพนักงานขนส่ง"
    );
    if (allData.length !== 0) {
      return {
        result: false,
        type: "no_transporter",
        message: "ไม่พบรหัสพนักงานขนส่ง",
      };
    }
    allData = checkData.filter(
      (item: any) => item.warning_note === "Invoice ซ้ำ ภายในไฟล์"
    );
    if (allData.length !== 0) {
      let text = "";
      let existItem: any[] = [];
      allData.map((item: any, index: any) => {
        if (existItem.indexOf(item.invoice) < 0) {
          existItem.push(item.invoice);
          if (allData.length - 1 !== index) {
            return (text = text + item.invoice + ", ");
          } else {
            return (text = text + item.invoice);
          }
        }
      });
      return {
        result: false,
        type: "file_exist",
        message:
          "Invoice No. ซ้ำใน File : " + text.substring(0, text.length - 2),
      };
    }
    allData = checkData.filter(
      (item: any) => item.warning_note === "Invoice No. ซ้ำในระบบ"
    );
    if (allData.length !== 0) {
      return { result: false, type: "data_exist", message: "" };
    }
    allData = checkData.filter(
      (item: any) => item.warning_note === "Invoice ถูกดำเนินการแล้ว"
    );
    if (allData.length !== 0) {
      let text = "";
      let existItem: any[] = [];
      allData.map((item: any, index: any) => {
        if (existItem.indexOf(item.invoice) < 0) {
          existItem.push(item.invoice);
          if (allData.length - 1 !== index) {
            return (text = text + item.invoice + ", ");
          } else {
            return (text = text + item.invoice);
          }
        }
      });
      return {
        result: true,
        type: "on_proceed",
        message:
          "Invoice No." + text + "ไม่สามารถแก้ไข เนื่องจาก ถูกดำเนินการแล้ว",
      };
    }
    return { result: true, type: "success", message: "" };
  };

  handleUnsuccessResult = (result: any) => {
    if (!result.result && result.type === "no_transporter") {
      this.setState({
        openModal: true,
        uploadResultType: result.type,
        modalMessages: {
          title: "ตรวจพบ Invoice No. ที่ไม่มีรายละเอียดพนักงานขนส่ง",
          messages: result.message,
        },
      });
    } else if (!result.result && result.type === "file_exist") {
      this.setState({
        openModal: true,
        uploadResultType: result.type,
        modalMessages: {
          title: "ไม่สามารถอัพโหลดได้เนื่องจาก ตรวจพบ Invoice No. ซ้ำในไฟล์",
          messages: result.message,
        },
      });
    } else if (!result.result && result.type === "data_exist") {
      this.setState({
        openModal: true,
        uploadResultType: result.type,
        modalMessages: {
          title: "ตรวจพบ Invoice No. ซ้ำซ้อน",
          messages:
            "หากคุณต้องการอัพโหลดข้อมูลซ้ำ กรุณากรอกรหัสผู้นำเข้าเพื่อดำเนินการต่อ",
        },
      });
    } else if (result.result && result.type === "on_proceed") {
      this.setState({
        openModal: true,
        uploadResultType: result.type,
        modalMessages: {
          title: "ตรวจพบ Invoice No. ที่ดำเนินการแล้ว",
          messages: result.message,
        },
      });
    }
  };

  handleEvent = async ({
    message,
    params,
  }: {
    message: string;
    params: any;
  }) => {
    console.log(message);
    if (message === "clickButton" && params.name === "chooseFile") {
      const arrayBuffer = (await readFileAsync(
        params.value.files[0]
      )) as ArrayBuffer;
      console.log('arrayBuffer', arrayBuffer)
      console.log('this.state.internal', this.state.internal)
      if (this.state.externalBack){
        var es: ExternalShipping = processExternalShippingBack(arrayBuffer);
        es = (await checkBackend(
          es,
          true,
          this.props.getApiToken()
        )) as ExternalShipping;
        console.log("External back data", es)
        this.setState({ esData: es });
      }else if (this.state.internal) {
        var is: InternalShipping = processInternalShipping(arrayBuffer);
        is = (await checkBackend(
          is,
          false,
          this.props.getApiToken()
        )) as InternalShipping;

        this.setState({ isData: is });
      } else {
        var es: ExternalShipping = processExternalShipping(arrayBuffer);
        es = (await checkBackend(
          es,
          false,
          this.props.getApiToken()
        )) as ExternalShipping;
        this.setState({ esData: es });
      }
    } else if (message === "clickButton" && params.name === "Clear") {
      this.setState({
        esData: Object.assign({}, initialExternalShipping),
        isData: Object.assign({}, initialInternalShipping)
      })
    } else if (message === "clickButton" && params.name === "Upload") {
      if (this.state.externalBack){
        const data = Object.assign({}, { ...this.state.esData });
        const result = this.handleCheckData({ data });
        if (result.result && result.type === "success") {
          uploadExternalShippingBack(
            this.state.esData,
            this.props.getApiToken(),
            (result) => {
              const newEsData = Object.assign({}, { ...this.state.esData });
              newEsData.invoices = newEsData.invoices.map((item) => {
                if (item.id === result.invoice.id) {
                  return { ...item, upload_result: result.message };
                } else {
                  return item;
                }
              });
              this.setState({ esData: newEsData });
            }
          );
        } else {
          this.handleUnsuccessResult(result);
        }
      }else if (this.state.internal) {
        const data = Object.assign({}, { ...this.state.isData });
        const result = this.handleCheckData({ data });
        if (result.result && result.type === "success") {
          uploadInternalShipping(
            this.state.isData,
            this.props.getApiToken(),
            (result) => {
              const newIsData = Object.assign({}, { ...this.state.isData });
              newIsData.invoices = newIsData.invoices.map((item) => {
                if (item.id === result.invoice.id) {
                  return { ...item, upload_result: result.message, warning_note: result.warning_note };
                } else {
                  return item;
                }
              });
              this.setState({ isData: newIsData });
            }
          );
        } else {
          this.handleUnsuccessResult(result);
        }
      } else {
        console.log("Upload");
        const data = Object.assign({}, { ...this.state.esData });
        const result = this.handleCheckData({ data });
        if (result.result && result.type === "success") {
          uploadExternalShipping(
            this.state.esData,
            this.props.getApiToken(),
            (result) => {
              const newEsData = Object.assign({}, { ...this.state.esData });
              newEsData.invoices = newEsData.invoices.map((item) => {
                if (item.id === result.invoice.id) {
                  return { ...item, upload_result: result.message };
                } else {
                  return item;
                }
              });
              this.setState({ esData: newEsData });
            }
          );
        } else {
          this.handleUnsuccessResult(result);
        }
      }
    } else if (message == "toggle"){
        if(params.name === "internal") {
          this.setState({
            internal: !this.state.internal,
            externalBack: false,
            esData: Object.assign({}, initialExternalShipping),
            isData: Object.assign({}, initialInternalShipping),
          });
        }else if(params.name === "external_back"){
          this.setState({
            externalBack: true,
            esData: Object.assign({}, initialExternalShipping),
          });
        }
    } else {
      this.props.onEvent({ message, params });
    }
  };

  render() {
    console.log(this.state.externalBack, this.state.internal)
    return (
      <div style={{ height: "100%" }}>
        {this.state.sub === "SubShippingSummary" ? (
          <SubShippingSummary
            login_employee={this.props.login_employee}
            esData={this.state.esData}
            isData={this.state.isData}
            internal={this.state.internal}
            externalBack={this.state.externalBack}
            onEvent={this.handleEvent}
          />
        ) : (
          // sub === "SubDuplicateInvoice" ?
          // <SubDuplicateInvoice data={data} onEvent={handleEvent} />:

          // sub === "SubImportSuccess" ?
          // <SubImportSuccess data={data} onEvent={handleEvent} />:

          <></>
        )}
        <ModalShipping
          open={this.state.openModal}
          type={this.state.uploadResultType}
          messages={this.state.modalMessages}
          apiToken={this.props.getApiToken()}
          onClose={() => {
            this.setState({ openModal: false });
          }}
          onConfirm={() => {
            this.handleModalClose();
          }}
        />
      </div>
    );
  }
}

const ModalShipping = (props: any) => {
  const [typingTimeout, setTypingTimeOut] = useState(0);
  const [validate, setValidate] = useState<boolean>(false);
  const [verifyFailed, setVerifyFailed] = useState<boolean>(false);
  const [employeeCode, setEmployeeCode] = useState<String>("");
  const [employeeName, setEmployeeName] = useState<String>("");

  React.useEffect(() => {
    setEmployeeCode("");
    setEmployeeName("");
    setValidate(false);
    setVerifyFailed(false);
  }, [props.open]);

  React.useEffect(() => {
    setEmployeeName("");
    setValidate(false);
    setVerifyFailed(false);
  }, [employeeCode]);


  const getEmployeeCode = async (code: String) => {
    const [r, e, n] = await EmployeeDetailFromCode.retrieve({
      code: code,
      apiToken: props.apiToken,
      params: {
        check_for: "invoice_shipping_data"
      }
    });
    if (r) {
      // if (r.position_code === "manager" || r.position_code === "acc_fin" || r.position_code === "mana_trans" ||
      // r.position_code === "officetran" || r.position_code === 'head_trans') {
        setVerifyFailed(false);
        setValidate(true);
        setEmployeeName(r.first_name + " " + r.last_name);
      // } else {
      //   setVerifyFailed(true);
      //   setValidate(false);
      //   setEmployeeName("ผู้ใช้งานนี้ไม่สามารถอัพโหลดไฟล์ได้ กรุณาติดต่อหัวหน้าแผนก ผู้จัดการฝ่ายขนส่ง หรือ ธุรการแผนกขนส่ง");
      // }
    } else {
      setValidate(false);
      setVerifyFailed(true);
      if(e && e.length < 80){
        setEmployeeName(e);
      }else{
        setEmployeeName("รหัสเจ้าหน้าที่ไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง");
      }
    }
  }

  const handleGetEmployeeCode = () => {
    getEmployeeCode(employeeCode);
  };

  const handleAutoGetEmployeeCode = (e:any) => {
    let code = e.target.value
    setEmployeeCode(code)
    if (typingTimeout) {
      window.clearTimeout(typingTimeout);
    }
    setTypingTimeOut(
      window.setTimeout(() => {
        getEmployeeCode(code)
      }, 1000)
    );

  };

  return (
    <Modal
      size={props.type === "data_exist" ? "small" : "tiny"}
      open={props.open}
      closeIcon
      onClose={() => {
        props.onClose();
      }}
      style={{ padding: "50px", textAlign: "center" }}
    >
      {(props.type === "file_exist" || props.type === "no_transporter") && (
        <div>
          <h2 style={{ color: "#333333" }}>{props.messages.title}</h2>
          <div
            style={{
              fontSize: "15px",
              paddingBottom: "20px",
              color: "#828282",
            }}
          >
            {props.messages.messages}
          </div>
          <div
            style={{
              color: "#00529C",
              fontSize: "15px",
              paddingBottom: "20px",
            }}
          >
            กรุณาแก้ไขข้อมูลและอัพโหลดใหม่อีกครั้ง
          </div>
          <div>
            <Button
              content="ตกลง"
              color="green"
              onClick={() => {
                props.onClose();
              }}
            />
          </div>
        </div>
      )}
      {props.type === "data_exist" && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h2 style={{ color: "#333333" }}>{props.messages.title}</h2>
          <div
            style={{
              fontSize: "15px",
              paddingBottom: "20px",
              color: "#828282",
            }}
          >
            {props.messages.messages}
          </div>
          <div
            style={{
              color: verifyFailed ? "#DB4632" : "#00529C",
              fontSize: "15px",
              paddingBottom: "20px",
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <b style={{ fontWeight: "bolder", paddingRight: "20px" }}>
              รหัสเจ้าหน้าที่<span style={{ color: "red" }}>*</span>:
            </b>
            <Input
              type="password"
              value={employeeCode}
              onKeyDown={(e: any) => {
                if (e.keyCode === CONSTANT.KEY_CODE.ENTER) {
                  handleGetEmployeeCode();
                }
              }}
              onChange={(e: any) => handleAutoGetEmployeeCode(e)}
            >
              <input
                style={{
                  color: validate ? "#00529C" : verifyFailed ? "#DB4632" : "",
                  border: verifyFailed ? "1px solid #DB4632" : "",
                  backgroundColor: verifyFailed ? "#FFDEDA" : "",
                }}
              />
            </Input>
            {validate && (
              <div style={{ paddingLeft: "10px" }}>{employeeName}</div>
            )}
          </div>
          <div>
          {verifyFailed && (
              <div style={{ paddingBottom: "20px", color: "#DB4632", fontSize: "16px" }}>{employeeName}</div>
            )}
          </div>
          <div>
            <Button
              content="ตกลง"
              color="green"
              disabled={!validate || verifyFailed}
              onClick={() => {
                props.onConfirm();
              }}
            />
          </div>
        </div>
      )}
      {props.type === "on_proceed" && (
        <div>
          <h2 style={{ color: "#333333" }}>{props.messages.title}</h2>
          <div
            style={{
              fontSize: "15px",
              paddingBottom: "20px",
              color: "#828282",
            }}
          >
            {props.messages.messages}
          </div>
          <div
            style={{
              color: "#00529C",
              fontSize: "15px",
              paddingBottom: "20px",
            }}
          >
            คุณไม่สามารถแก้ไขข้อมูลที่ถูกดำเนินการไปแล้วได้
          </div>
          <div>
            <Button
              content="ตกลง"
              color="green"
              onClick={() => {
                props.onConfirm();
              }}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default Card;

import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  useCallback,
  SyntheticEvent,
} from "react";
import "./CardInvoiceList.css";
import MainLayout from "./Sub/MainLayout";
import ModUpdateInvoice from "./Sub/ModUpdateInvoice";
import ModUpdateDocNote from "./Sub/ModUpdateDocNote";
import ModEditSale from "./Sub/ModEditSale";
import ModUpdateAllInvoice from "./Sub/ModUpdateAllInvoice";
import ModCancelAllInvoice from "./Sub/ModCancelAllInvoice";
import ModInvoiceActionLogList from "./Sub/ModInvoiceActionLogList";
import ModInvoiceDocumentImageList from "./Sub/ModInvoiceDocumentImageList";
import {
  Input,
  Dropdown,
  Button,
  Popup,
  Checkbox,
  Icon,
  Dimmer,
  Loader,
  DropdownProps,
  Pagination,
  PaginationProps,
  Item,
  Divider
} from "semantic-ui-react";
import { Choices } from "../react-lib/apis/issara/apps/TRK/INVcommon";
import { InvoiceList, InvoiceDetail } from "../react-lib/apis/issara/apps/TRK/INVInvoice";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { DateUtils } from "react-day-picker";
import dateFnsFormat from "date-fns/format";
import dateFnsParse from "date-fns/parse";
import { formatComma, parseDate } from "../react-lib/utils";
import ErrorMessage from "../react-lib/apps/common/ErrorMessage";
import {
  optionsReducer,
  optionsReducerInitial,
} from "../react-lib/utils/reducer";
import { DOC_TYPE_TEXT } from "../Common";


const FORMAT = "dd/MM/yyyy";
const FORMATEDITABLE = "dd/MM/y"

const filterReducer = (state: any, action: any) => {
  switch (action.type) {
    case "set":
      return { ...state, [action.key]: action.value };
    case "startCallApi":
      return { ...state, loading: true, error: null };
    case "finishLoading":
      return { ...state, loading: false };
    case "error":
      return {
        ...state,
        loading: false,
        error: action.value,
        invoiceList: [],
        totalPage: 1,
      };
    default:
      return state;
  }
};

const initialState = {
  fromDate: dateFnsFormat(new Date(), FORMAT),
  toDate: dateFnsFormat(new Date(), FORMAT),
  fromDateStyle: {},
  toDateStyle: {},
  serial: "",
  customer: "",
  shipper: "",
  saleName: "",
  status: "",
  deliveryAddress: "",
  ascending: true,
  ascendingKey: "serial",
  currentPage: 1,
  totalPage: 1,

  loading: false,
  error: null,

  invoiceList: [],
};

const CardInvoiceList = (props: any) => {
  const [invStatusOptions, dispatchInvStatusOptions] = useReducer(
    optionsReducer,
    optionsReducerInitial
  );

  const [state, dispatch] = useReducer(filterReducer, initialState);

  const [openRowDetailPopup, setOpenRowDetailPopup] = useState(null);
  const [showInvoiceTable, setShowInvoiceTable] = useState<any>(false);
  const [showOthersTable, setShowOthersTable] = useState<any>(false);
  const [activeRow, setActiveRow] = useState<number | null>(null);

  //ModDetail
  const [openModDetail, setOpenModDetail] = useState(false);
  const [openModEditSale, setOpenModEditSale] = useState(false);
  const [openModActionLog, setOpenModActionLog] = useState(false);
  const [openModImageList, setOpenModImageList] = useState(false);
  const [openModUpdateInvoiceList, setOpenModUpdateInvoiceList] = useState(false);
  const [openModCancelInvoiceList, setOpenModCancelInvoiceList] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<any>({});

  const [openModUpdateDocNote, setOpenModUpdateDocNote] = useState(false);
  const [checkedInvoices, setCheckedInvoices] = useState<any>([]);
  const [isCheckedAll, setIsCheckedAll] = useState<boolean>(false);

  const isMounted = useRef(true);
  const pageSize = 20;

  const handleSetState = useCallback( ({ key, value }: { key: string; value: any }) => {
    dispatch({ type: "set", key, value });
  },[]);

  const handleGetInvoiceList = useCallback(async () => {
    // console.log("handleGetInvoiceList Called" , state)
    const {
      fromDate,
      toDate,
      serial,
      customer,
      shipper,
      saleName,
      deliveryAddress,
      ascending,
      ascendingKey,
      currentPage,
      status,
    } = state;
    let params = {
      from_date: fromDate,
      to_date: toDate,
      serial,
      customer: customer,
      shipper: shipper,
      sale_name: saleName,
      status,
      delivery_address: deliveryAddress,
      ascending,
      sortby: ascendingKey,
      page: currentPage,
      page_size: pageSize,
    };
    dispatch({ type: "startCallApi" });
    const [response, error, network] = await InvoiceList.list({
      apiToken: props.apiToken,
      params,
    });
    if (isMounted.current) {
      dispatch({ type: "finishLoading" });
      if (response) {
        if (response.count) {
          handleSetState({
            key: "totalPage",
            value: Math.ceil(response.count / pageSize),
          });
        }
        handleSetState({ key: "invoiceList", value: response.results });
        return response.results;
      } else {
        let err = error;
        if (error.length > 200) {
          err = `${network.response.status}: ${network.response.statusText}`;
        }
        if(currentPage !== 1){
          handleSetState({ key: "currentPage", value: 1 });
        }else{
          dispatch({ type: "error", value: err });
        }
        return { error: err };
      }
    }
  }, [props.apiToken, state, handleSetState])

  // useEffect( () => {
  //   console.log(" invoiceList", state.invoiceList)

    // const patchManualAction = async (invoice:any) => {
    //   if (invoice.allowed_actions.length === 0) {
    //     return;
    //   }

    //   const [r, e, n] = await InvoiceDetail.patch({
    //     pk: invoice.id,
    //     apiToken: props.apiToken,
    //     data: {
    //       action:invoice.allowed_actions[0],
    //     },
    //   });
    //   if (e) {
    //     console.log(Error, e);
    //     console.log(n);
    //   } else {
    //     console.log("r", r);
    //     console.log("n", n);
    //     if (n.status === 200) {
    //       handleGetInvoiceList()
    //     }
    //   }
    // };

  //   state.invoiceList.forEach( (item: any) => {
  //     if (item.all_document_scanned) {
  //       // automatic send 
  //       patchManualAction( item)
  //     }
  //   })
  // }, [state.invoiceList, props.apiToken])

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (props.apiToken) {
      handleGetInvoiceStatusList();
      handleGetInvoiceList();
    }
  }, [props.apiToken]);

  useEffect(()=>{handleGetInvoiceList()} , [state.ascending, state.ascendingKey, state.currentPage]);

  const handleGetInvoiceStatusList = async () => {
    dispatchInvStatusOptions({ type: "get" });
    const [res, err, network] = await Choices.retrieve({
      params: {
        model: "TRK.Invoice",
        field: "status",
      },
      apiToken: props.apiToken,
    });
    if (!isMounted.current) {
      return;
    }
    if (res) {
      let newArr = res.items.filter((item: any) => !["RE_COLLECTING", "GOODS_RETURN"].includes(item.value)).map((item: any) => {
        return {
          key: item.id,
          text: item.label,
          value: item.value,
        };
      });
      dispatchInvStatusOptions({ type: "success", value: newArr });
    } else {
      console.log(err, "err");
      dispatchInvStatusOptions({ type: "error", value: err });
    }
  };

  const handleSetCurrentPage = (e: SyntheticEvent, { activePage } : PaginationProps) => {
    handleSetState({ key: "currentPage", value: activePage });
  };

  const handleClickColumnHeader = ({ key } = { key: "" }) => {
    if (key === state.ascendingKey) {
      handleSetState({ key: "ascending", value: !state.ascending });
    }
    handleSetState({ key: "ascendingKey", value: key });
  };

  const handleOpenMod = () => {
    let selectedItem = state.invoiceList.find(
      (item: any) => item.id === openRowDetailPopup
    );
    if (selectedItem) {
      setSelectedInvoice(selectedItem);
    }
    setOpenRowDetailPopup(null);
  };

  const handleOpenModActionLog = () => {
    handleOpenMod();
    setOpenModActionLog(true);
  };

  const handleOpenModImageList = () => {
    handleOpenMod();
    setOpenModImageList(true);
  };

  const handleOpenModDetail = () => {
    handleOpenMod();
    setOpenModDetail(true);
  };

  const handleOpenModEditSale = () => {
    handleOpenMod();
    setOpenModEditSale(true);
  };

  const handleUpdateInvoiceSuccess = () => {
    setOpenModDetail(false);
    setOpenModEditSale(false);
    handleGetInvoiceList();
  };

  const handleOnCheckedAllInvoices = (isChecked: any) => {
    console.log(isChecked)
    if(isChecked){
      let serialData = [...checkedInvoices];
      console.log(state.invoiceList)
      state.invoiceList.filter((item:any) => 
      serialData.indexOf(item.serial) === -1).map((item:any) => {
        serialData.push(item.serial)
      })
      setCheckedInvoices(serialData);
    }else{
      setCheckedInvoices([]);
    }
    setIsCheckedAll(isChecked);
    
  }

  const handleOnCheckedInvoice = (serial: string, isChecked: any) => {
    let serialData = [...checkedInvoices];
    if(isChecked){
      if(serialData.indexOf(serial) < 0){
        serialData.push(serial)
      }
    }else{
      serialData = serialData.filter(item => item !== serial)
    }
    setCheckedInvoices(serialData)
  }

  const checkDocType = ({ docType, item }: { docType: string; item: any }) => {
    let obj = item.find((i: any) => i.doc_type === docType && i.status !== 'CREATED');
    if (!obj) {
      return "-";
    }
    return obj.holder_label;
  };

  const handleEvent = async ({
    message,
    params,
  }: {
    message: string;
    params: any;
  }) => {
    if (message === "clickMenu") {
      props.onEvent({ message, params });
    }
  };

  const handleUpdatedAllInvoiceSuccess = () => {
    setCheckedInvoices([]);
    setIsCheckedAll(false);
    setOpenModUpdateInvoiceList(false);
    handleGetInvoiceList();
  }

  const handleCancelAllInvoiceSuccess = () => {
    setCheckedInvoices([]);
    setIsCheckedAll(false);
    setOpenModCancelInvoiceList(false);
    handleGetInvoiceList();
  }

  const handleOpenModUpdateDocNote = () => {
    handleOpenMod();
    setOpenModUpdateDocNote(true);
  };

  const handleOnCloseModUpdateDocNote = useCallback(() => {
    setOpenModUpdateDocNote(false);
  }, []);

  const handleOnSuccessUpdateDocNote = useCallback(async (invoiceId: any) => {
    const invList = await handleGetInvoiceList();
    if (invList.hasOwnProperty("error")) {
      setOpenModUpdateDocNote(false);
    }
    let inv = invList.find((item: any) => item.id === invoiceId);
    if (inv) {
      setSelectedInvoice(inv);
      return inv;
    } else {
      setOpenModUpdateDocNote(false);
    }
  }, [handleGetInvoiceList]);

  const handleOnCloseModInvoiceDocumentImageList = useCallback(() => {
    setOpenModImageList(false);
  }, [])

  const handleOnCloseModInvoiceActionLogList = useCallback( () => {
    setOpenModActionLog(false);
  }, [])

  const handleOnChangeDropdown = useCallback((e: SyntheticEvent, { value } : DropdownProps) => {
    handleSetState({ key: "status", value })
  }, [handleSetState])

  // console.log("CardInvoideList REender", state)
  return (
    <MainLayout
      selectedCard="Invoice"
      login_employee={props.login_employee}
      onEvent={handleEvent}
    >
      <ModUpdateDocNote
        open={openModUpdateDocNote}
        onClose={handleOnCloseModUpdateDocNote}
        invoice={selectedInvoice}
        onSuccess={handleOnSuccessUpdateDocNote}
      />
      <ModUpdateInvoice
        apiToken={props.apiToken}
        open={openModDetail}
        onClose={() => {
          setOpenModDetail(false);
        }}
        invoice={selectedInvoice}
        onSuccess={handleUpdateInvoiceSuccess}
      />
      <ModEditSale
        apiToken={props.apiToken}
        open={openModEditSale}
        onClose={() => {
          setOpenModEditSale(false);
        }}
        invoice={selectedInvoice}
        onSuccess={handleUpdateInvoiceSuccess}
      />
      <ModInvoiceActionLogList
        apiToken={props.apiToken}
        onClose={handleOnCloseModInvoiceActionLogList}
        open={openModActionLog}
        invoice={selectedInvoice}
      />
      <ModUpdateAllInvoice 
        apiToken={props.apiToken}
        onClose={() => {
          setOpenModUpdateInvoiceList(false)
        }}
        open={openModUpdateInvoiceList}
        onSuccess={() => handleUpdatedAllInvoiceSuccess()}
        invoice_list={checkedInvoices}
      />
      <ModCancelAllInvoice 
        apiToken={props.apiToken}
        onClose={() => {
          setOpenModCancelInvoiceList(false)
        }}
        open={openModCancelInvoiceList}
        onSuccess={() => handleCancelAllInvoiceSuccess()}
        invoiceList={checkedInvoices}
      />
      <ModInvoiceDocumentImageList
        apiToken={props.apiToken}
        onClose={handleOnCloseModInvoiceDocumentImageList}
        open={openModImageList}
        invoice={selectedInvoice}
      />
      <Dimmer.Dimmable>
        <Dimmer active={state.loading} inverted>
          <Loader inverted />
        </Dimmer>
        <div className="main-invoice-list">
          <ErrorMessage error={state.error} />
          <div className="filter-row">
            <div className="filter-item">
              <div className="filter-label-wrapper">
                <b>Invoice No.</b>
              </div>
              <div className="filter-input-wrapper">
                <Input
                  fluid
                  value={state.serial}
                  onChange={(e) =>
                    handleSetState({ key: "serial", value: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="filter-item">
              <div className="filter-label-wrapper">
                <b>Customer</b>
              </div>
              <div className="filter-input-wrapper">
                <Input
                  fluid
                  value={state.customer}
                  onChange={(e) =>
                    handleSetState({
                      // key: "customerName",
                      key: "customer",
                      value: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="filter-item">
              <div className="filter-label-wrapper">
                <b>Shipper</b>
              </div>
              <div className="filter-input-wrapper">
                <Input
                  fluid
                  value={state.shipper}
                  onChange={(e) =>
                    handleSetState({
                      key: "shipper",
                      // key: "customerCode",
                      value: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="filter-item">
              <div className="filter-label-wrapper">
                <b>Status</b>
              </div>
              <div className="filter-input-wrapper">
                <Dropdown
                  clearable
                  error={invStatusOptions.error}
                  loading={invStatusOptions.loading}
                  search
                  selection
                  options={invStatusOptions.options}
                  fluid
                  value={state.status}
                  onChange={handleOnChangeDropdown}
                />
              </div>
            </div>
          </div>
          <div className="filter-row">
            <div className="filter-item">
              <div className="filter-label-wrapper">
                <b>From</b>
              </div>
              <div>
                <DayPickerInput
                  value={state.fromDate}
                  format={FORMATEDITABLE}
                  placeholder={"DD/MM/YYYY"}
                  formatDate={(date, format) => dateFnsFormat(date, format)}
                  parseDate={(str, format) => {
                    const parsed = dateFnsParse(str, FORMATEDITABLE, new Date());
                    if (DateUtils.isDate(parsed)) {
                      return parsed;
                    }
                    return undefined;
                  }}
                  inputProps={{style: state.fromDateStyle}}
                  onDayChange={(day) => {
                    console.log("DayPickerInput onDayChange day", day)
                    if(day) {
                      console.log(" day.getFullYear(): ", day.getFullYear())
                      if (day.getFullYear() < 100) {
                        handleSetState({ key: "fromDateStyle" , value: {backgroundColor: "#e56868"}})
                      } else {
                        handleSetState({ key: "fromDateStyle" , value: {}})
                      }
                      const parsedDate = dateFnsFormat(day, FORMATEDITABLE);
                      
                      handleSetState({ key: "fromDate", value: parsedDate });
                    }else{
                      handleSetState({ key: "fromDate", value: "" });
                    }
                  }}
                />
              </div>
              <div className="filter-label-wrapper">
                <b>To</b>
              </div>
              <div>
                <DayPickerInput
                  value={state.toDate}
                  format={FORMATEDITABLE}
                  placeholder={"DD/MM/YYYY"}
                  formatDate={(date, format) => dateFnsFormat(date, format)}
                  inputProps={{style: state.toDateStyle}}
                  parseDate={(str, format) => {
                    const parsed = dateFnsParse(str, format, new Date());
                    if (DateUtils.isDate(parsed)) {
                      return parsed;
                    }
                    return undefined;
                  }}
                  onDayChange={(day) => {
                    if (day) {
                      console.log("ToDate day.getFullYear(): ", day.getFullYear())
                      if (day.getFullYear() < 100) {
                        
                        handleSetState({ key: "toDateStyle" , value: {backgroundColor: "#e56868"}})
                      } else {
                        handleSetState({ key: "toDateStyle" , value: {}})
                      }
                      const parsedDate = dateFnsFormat(day, FORMATEDITABLE);
                      handleSetState({ key: "toDate", value: parsedDate });
                    }else{
                      handleSetState({ key: "toDate", value: "" });
                    }

                  }}
                />
              </div>
            </div>
            <div className="filter-item">
              <div className="filter-label-wrapper">
                <b>Sales</b>
              </div>
              <div className="filter-input-wrapper">
                <Input
                  fluid
                  value={state.saleName}
                  onChange={(e) =>
                    handleSetState({ key: "saleName", value: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="filter-item">
              <div className="filter-label-wrapper">
                <b>Address</b>
              </div>
              <div className="filter-input-wrapper">
                <Input
                  fluid
                  value={state.deliveryAddress}
                  onChange={(e) =>
                    handleSetState({
                      key: "deliveryAddress",
                      value: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="filter-item">
              <Button content="ค้นหา" onClick={handleGetInvoiceList} />
              <Popup
                basic
                on="click"
                position="bottom center"
                content={
                  <div>
                    <Checkbox
                      label="ตารางเอกสาร Invoice"
                      checked={showInvoiceTable}
                      onChange={(e, { checked }) =>
                        setShowInvoiceTable(checked)
                      }
                    />
                    <Checkbox
                      label="ตารางเอกสารอื่นๆ"
                      checked={showOthersTable}
                      onChange={(e, { checked }) => setShowOthersTable(checked)}
                    />
                  </div>
                }
                trigger={
                  <Dropdown
                    selection
                    text="เลือกการแสดงผล"
                    compact
                    options={[]}
                  />
                }
              />
            </div>
          </div>
          {state.invoiceList && checkedInvoices.length > 0 && (
          <div className="filter-row" style={{backgroundColor: "#242424"}}>
            <div className="filter-item" style={{flex:"1"}}>
              <div className="filter-label-wrapper" style={{color:"white", paddingLeft:"10px"}}>
                {checkedInvoices.length + " selected"}
              </div>
            </div>
            <div className="filter-item" style={{flex:"14", borderLeft:"solid 1px #ffffff"}}>
            <Button inverted content="อัพเดตสถานะ" className="update-button" 
              onClick={() => {setOpenModUpdateInvoiceList(true)}}
            />
            <Button inverted content="ยกเลิก Invoice" className="update-button" 
              onClick={() => {setOpenModCancelInvoiceList(true)}}
            />
              </div>
          </div>)}
          <div className="table-wrapper">
            <table className="invoice-table">
              <thead>
                <tr>
                  <th>
                    <Checkbox 
                    checked={isCheckedAll}
                    disabled={state.invoiceList.length === 0} 
                    onChange={(e, {checked}) => handleOnCheckedAllInvoices(checked)}/>
                  </th>
                  <th
                    onClick={() => handleClickColumnHeader({ key: "serial" })}
                  >
                    Invoice No.
                    <Icon name="sort" />
                  </th>
                  <th
                    onClick={() =>
                      handleClickColumnHeader({ key: "customer_code" })
                    }
                  >
                    Customer Code
                    <Icon name="sort" />
                  </th>
                  <th
                    onClick={() =>
                      handleClickColumnHeader({ key: "customer_name" })
                    }
                  >
                    Customer Name
                    <Icon name="sort" />
                  </th>
                  <th onClick={() => handleClickColumnHeader({ key: "date" })}>
                    Billing Date
                    <Icon name="sort" />
                  </th>
                  <th
                    onClick={() =>
                      handleClickColumnHeader({ key: "sale_name" })
                    }
                  >
                    Sale
                    <Icon name="sort" />
                  </th>
                  <th
                    onClick={() =>
                      handleClickColumnHeader({ key: "delivery_address" })
                    }
                  >
                    Address
                    <Icon name="sort" />
                  </th>
                  <th
                    onClick={() =>
                       handleClickColumnHeader({ key: "shipper" })
                    }
                  >
                    Shipper
                    <Icon name="sort" />
                  </th>
                  <th>Status</th>
                  <th
                    className="price"
                    onClick={() => handleClickColumnHeader({ key: "amount" })}
                  >
                    Total(Baht)
                    <Icon name="sort" />
                  </th>
                  {showInvoiceTable && (
                    <>
                      <th className="break" />
                      <th>ชุดที่ 1</th>
                      <th>2</th>
                      <th>3</th>
                      <th>4</th>
                      <th>5</th>
                      <th>6</th>
                      <th>7</th>
                    </>
                  )}
                  {showOthersTable && (
                    <>
                      <th className="break" />
                      <th>ใบสั่งซื้อ</th>
                      <th>ใบรับสินค้า</th>
                      <th>ใบวางบิล</th>
                      <th>ใบขนส่ง(Outsource)</th>
                      <th>ใบนำส่งสินค้า</th>
                      <th>เอกสารชำระเงิน</th>
                      <th>ใบลดหนี้</th>
                      <th>ใบเพิ่มหนี้</th>
                      <th>อื่นๆ(ระบุใน Note)</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {state.invoiceList.map((item: any, index: number) => {
                  // console.log("invoiceList", state.invoiceList)
                  return (
                    <tr
                          key={item.id}
                          className={activeRow === item.id ? "active" : ""}
                          onClick={() => {
                            setActiveRow(item.id);
                            // setOpenRowDetailPopup(item.id);
                          }}
                        >
                          <td>
                            <Checkbox 
                            checked={checkedInvoices.length > 0 && checkedInvoices.indexOf(item.serial) !== -1}
                            onChange={(e, {checked})=>{handleOnCheckedInvoice(item.serial, checked)}} 
                          />
                          </td>
                    <Popup
                      key={item.id}
                      on="click"
                      open={openRowDetailPopup === item.id}
                      onClose={() => setOpenRowDetailPopup(null)}
                      hideOnScroll
                      content={
                        <div style={{ display: "flex", whiteSpace: "nowrap" }}>
                          <Button
                            content="Action Log"
                            color="teal"
                            onClick={handleOpenModActionLog}
                          />
                          <Button
                            content="อัพเดตสถานะ"
                            color="blue"
                            onClick={handleOpenModDetail}
                          />
                          <Button
                            content="รายละเอียดเอกสาร"
                            color="violet"
                            onClick={handleOpenModUpdateDocNote}
                          />
                          <Button
                            content="แก้ไข Sale"
                            color="purple"
                            onClick={handleOpenModEditSale}
                          />
                          <Button
                            content="ดูรูปหลักฐาน"
                            color="pink"
                            onClick={handleOpenModImageList}
                          />
                        </div>
                      }
                      trigger={
                        <>
                          <td className="serial" 
                          onClick={() => {
                            setOpenRowDetailPopup(item.id)
                          }}>{item.serial}</td>
                          <td className="customer-code" onClick={() => {
                            setOpenRowDetailPopup(item.id)
                          }}>
                            {item.customer_code}
                          </td>
                          <td className="customer-name" onClick={() => {
                            setOpenRowDetailPopup(item.id)
                          }}>
                            {item.customer_name}
                          </td>
                          <td>{item.date}</td>
                          <td className="sale-name" 
                          onClick={() => {setOpenRowDetailPopup(item.id)}}>{item.sale_name}</td>
                          <td className="delivery-address"
                          onClick={() => {setOpenRowDetailPopup(item.id)}}>
                            {item.delivery_address}
                          </td>
                          <td className="shipper"
                          onClick={() => {setOpenRowDetailPopup(item.id)}}>
                            {item.shipper? item.shipper : item.transport_name}
                          </td>
                          <td className="invoice-status"
                          onClick={() => {setOpenRowDetailPopup(item.id)}}>
                            {item.status_label}
                            {item.is_out_standing? (
                              <Icon style={{paddingLeft:"5px"}} inverted  color="red" name="exclamation circle" />
                            ): ""} 
                          </td>
                          <td className="price" onClick={() => {setOpenRowDetailPopup(item.id)}}
                          >{formatComma(item.amount)}</td>
                          {showInvoiceTable && (
                            <>
                              <td className="break" />
                              <td className="related-documents">
                                {item.related_documents[0] && item.related_documents[0].doc_type === "GENERAL" ? item.related_documents[0].holder_label: "-"}
                              </td>
                              <td className="related-documents">
                                {item.related_documents[1] && item.related_documents[1].doc_type === "GENERAL" ? item.related_documents[1].holder_label: "-"}
                              </td>
                              <td className="related-documents">
                                {item.related_documents[2] && item.related_documents[2].doc_type === "GENERAL" ? item.related_documents[2].holder_label: "-"}
                              </td>
                              <td className="related-documents">
                                {item.related_documents[3] && item.related_documents[3].doc_type === "GENERAL" ? item.related_documents[3].holder_label: "-"}
                              </td>
                              <td className="related-documents">
                                {item.related_documents[4] && item.related_documents[4].doc_type === "GENERAL" ? item.related_documents[4].holder_label: "-"}
                              </td>
                              <td className="related-documents">
                                {item.related_documents[5] && item.related_documents[5].doc_type === "GENERAL" ? item.related_documents[5].holder_label: "-"}
                              </td>
                              <td className="related-documents">
                                {item.related_documents[6] && item.related_documents[6].doc_type === "GENERAL" ? item.related_documents[6].holder_label: "-"}
                              </td>
                            </>
                          )}
                          {showOthersTable && (
                            <>
                              <td className="break" />
                              <td
                                className="purchase"
                                style={{ textAlign: "center" }}
                              >
                                {checkDocType({
                                  docType: DOC_TYPE_TEXT.PURCHASE,
                                  item: item.related_documents,
                                })}
                              </td>
                              <td
                                className="receive"
                                style={{ textAlign: "center" }}
                              >
                                {checkDocType({
                                  docType: DOC_TYPE_TEXT.RECEIVE,
                                  item: item.related_documents,
                                })}
                              </td>
                              <td
                                className="bill"
                                style={{ textAlign: "center" }}
                              >
                                {checkDocType({
                                  docType: DOC_TYPE_TEXT.BILL,
                                  item: item.related_documents,
                                })}
                              </td>
                              <td
                                className="outsource"
                                style={{ textAlign: "center" }}
                              >
                                {checkDocType({
                                  docType: DOC_TYPE_TEXT.OUTSOURCE,
                                  item: item.related_documents,
                                })}
                              </td>
                              <td
                                className="transport"
                                style={{ textAlign: "center" }}
                              >
                                {checkDocType({
                                  docType: DOC_TYPE_TEXT.TRANSPORT,
                                  item: item.related_documents,
                                })}
                              </td>
                              <td
                                className="payment"
                                style={{ textAlign: "center" }}
                              >
                                {checkDocType({
                                  docType: DOC_TYPE_TEXT.PAYMENT,
                                  item: item.related_documents,
                                })}
                              </td>
                              <td
                                className="payment"
                                style={{ textAlign: "center" }}
                              >
                                {checkDocType({
                                  docType: DOC_TYPE_TEXT.CREDIT_NOTE,
                                  item: item.related_documents,
                                })}
                              </td>
                              <td
                                className="payment"
                                style={{ textAlign: "center" }}
                              >
                                {checkDocType({
                                  docType: DOC_TYPE_TEXT.DEBIT_NOTE,
                                  item: item.related_documents,
                                })}
                              </td>
                              <td
                                className="other"
                                style={{ textAlign: "center" }}
                              >
                                {checkDocType({
                                  docType: DOC_TYPE_TEXT.OTHER,
                                  item: item.related_documents,
                                })}
                              </td>
                            </>
                          )}
                        </>
                      }
                    />
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          { state.invoiceList && state.invoiceList.length > 0 &&
            <Pagination
              style={{ marginTop: "15px" }}
              activePage={state.currentPage}
              totalPages={state.totalPage}
              defaultActivePage={1}
              onPageChange={handleSetCurrentPage}
            />
          }
        </div>
      </Dimmer.Dimmable>
    </MainLayout>
  );
};

export default React.memo(CardInvoiceList);

import axios from 'axios'
import { to } from '../../../../utils'
import BaseService from  '../../../services/BaseService'
import invSettings from '../../../../../config/config';
import { Response, Error } from '../../common'
export const Choices : 
{
retrieve: ({ params, apiToken, extra}:
            {  params?: any,
              apiToken?: any,
              extra?: any,
          }) => any
} = 
{
retrieve: async ({ params, apiToken, extra}) => {
  const base = new BaseService()
  if (apiToken) {
      base.client.defaults.headers["Authorization"] = "Token " + apiToken
  }
  const result = await to(base.client.get(`${invSettings.API_HOST}/apis/core/choices/`,
    { params: params ? { ...params } : {},
        ...extra
    }
  )
  .then(base.handleResponse)
  .catch(base.throwErrorMessage))
  return [result[1], result[0], result[2]]
},
}
/*----------------------------------------------------------------------------------------------------*/


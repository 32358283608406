import React, { useState } from "react";
import SubLogin from "./Sub/INTLoginPage";
import SubVerify from "./Sub/INTVerifyEmployee";
import SubSetPassword from "./Sub/INTVerifySuccess";
import SubRegisSuccess from "./Sub/INTRegisterDone";
import { TRKVerifyEmployee } from "../react-lib/apis/issara/apps/TRK/INVUser";
import {
  SetPassword,
  RequestToken,
  createNotificationSubscription,
  getUserSubscription,
  getRegisterServiceWorker
} from "../react-lib/apis/issara/apps/TRK/INVmanual";
import * as serviceWorker from "../serviceWorker.js";
import { Modal } from "semantic-ui-react";

// async function registerServiceWorker() {
//   return await navigator.serviceWorker.register("/sw.js");
// }


interface ModalMessage {
  title: string;
  messages: string[];
}
const Card = (props: any) => {
  const [sub, setSub] = useState("SubLogin");
  const [Username, setUsername] = useState("");
  const [EmployeeNumber, setEmployeeNumber] = useState("");
  const [Password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState<ModalMessage>({
    title: "Error",
    messages: [],
  });
  const [showAlertText, setShowAlertText] = useState<boolean>(true);

  const [hidePassword, setHidePassword] = useState<boolean>(true);
  const [hideConfirmPassword, setHideConfirmPassword] = useState<boolean>(true);
  const [TRKVerifyEmployeeError, setTRKVerifyEmployeeError] = useState<any>(
    null
  );
  const [verifyLoading, setVerifyLoading] = useState<boolean>(false);
  const [localApiToken, setLocalApiToken] = useState<any>(null);

  const viewData = {
    Username: Username,
    EmployeeNumber: EmployeeNumber,
    Password: Password,
    ConfirmPassword: ConfirmPassword,
    hidePassword: hidePassword,
    hideConfirmPassword: hideConfirmPassword,
  };

  const getBrowserName = () => {
    const nAgt = navigator.userAgent;

    // In Opera, the true version is after "Opera" or after "Version"
    if (nAgt.indexOf("Opera") !== -1) {
      return "Opera";
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if (nAgt.indexOf("MSIE") !== -1) {
      return "Microsoft Internet Explorer";
    }
    // In Chrome, the true version is after "Chrome"
    else if (nAgt.indexOf("Chrome") !== -1) {
      return "Chrome";
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if (nAgt.indexOf("Safari") !== -1) {
      return "Safari";
    }
    // In Firefox, the true version is after "Firefox"
    else if (nAgt.indexOf("Firefox") !== -1) {
      return "Firefox";
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else {
      return navigator.appName;
    }
  };

  const getOSName = () => {
    const nAgt = navigator.userAgent;
    let os = "Unknown OS";
    const clientStrings = [
      { s: "Windows 3.11", r: /Win16/ },
      { s: "Windows 95", r: /(Windows 95|Win95|Windows_95)/ },
      { s: "Windows ME", r: /(Win 9x 4.90|Windows ME)/ },
      { s: "Windows 98", r: /(Windows 98|Win98)/ },
      { s: "Windows CE", r: /Windows CE/ },
      { s: "Windows 2000", r: /(Windows NT 5.0|Windows 2000)/ },
      { s: "Windows XP", r: /(Windows NT 5.1|Windows XP)/ },
      { s: "Windows Server 2003", r: /Windows NT 5.2/ },
      { s: "Windows Vista", r: /Windows NT 6.0/ },
      { s: "Windows 7", r: /(Windows 7|Windows NT 6.1)/ },
      { s: "Windows 8.1", r: /(Windows 8.1|Windows NT 6.3)/ },
      { s: "Windows 8", r: /(Windows 8|Windows NT 6.2)/ },
      { s: "Windows NT 4.0", r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
      { s: "Windows ME", r: /Windows ME/ },
      { s: "Android", r: /Android/ },
      { s: "Open BSD", r: /OpenBSD/ },
      { s: "Sun OS", r: /SunOS/ },
      { s: "Linux", r: /(Linux|X11)/ },
      { s: "iOS", r: /(iPhone|iPad|iPod)/ },
      { s: "Mac OS X", r: /Mac OS X/ },
      { s: "Mac OS", r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
      { s: "QNX", r: /QNX/ },
      { s: "UNIX", r: /UNIX/ },
      { s: "BeOS", r: /BeOS/ },
      { s: "OS/2", r: /OS\/2/ },
      {
        s: "Search Bot",
        r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
      },
    ];
    for (let id in clientStrings) {
      let cs = clientStrings[id];
      if (cs.r.test(nAgt)) {
        os = cs.s;
        break;
      }
    }
    return os;
  };

  const handleEvent = async ({
    message,
    params,
  }: {
    message: string;
    params: any;
  }) => {
    console.log(message, params);
    if (message === "clickButton" && params.name === "Register") {
      setSub("SubVerify");
    } else if (message === "inputChange" && params.name === "Username") {
      setUsername(params.value);
    } else if (message === "inputChange" && params.name === "EmployeeNumber") {
      setEmployeeNumber(params.value);
    } else if (message === "clickButton" && params.name === "Verify") {
      setVerifyLoading(true);
      setTRKVerifyEmployeeError(null);
      const [response, error, network] = await TRKVerifyEmployee.list({
        params: {
          username: Username,
          employee_number: EmployeeNumber,
        },
      });
      setVerifyLoading(false);
      if (!error && response.items.length > 0) {
        setLocalApiToken(response.items[0].api_token);
        // props.onEvent({
        //   message: "setApiToken",
        //   params: { value: response.items[0].api_token },
        // });
        setSub("SubSetPassword");
      } else {
        if (error.length > 200) {
          setTRKVerifyEmployeeError(
            `${network.response.status}: ${network.response.statusText}`
          );
        } else {
          setTRKVerifyEmployeeError(error);
        }
        console.log("verify failed");
      }
    } else if (message === "inputChange" && params.name === "Password") {
      setPassword(params.value);
      if (checkPasswordPolicy(ConfirmPassword, params.value)) {
        setShowAlertText(false);
      }
    } else if (message === "inputChange" && params.name === "ConfirmPassword") {
      setConfirmPassword(params.value);
      if (checkPasswordPolicy(ConfirmPassword, params.value)) {
        setShowAlertText(true);
      }
    } else if (
      message === "clickButton" &&
      params.name === "toggleHidePassword"
    ) {
      setHidePassword(!hidePassword);
    } else if (
      message === "clickButton" &&
      params.name === "toggleHideConfirmPassword"
    ) {
      setHideConfirmPassword(!hideConfirmPassword);
    } else if (message === "clickButton" && params.name === "SetPassword") {
      if (!checkPasswordPolicy(Password, ConfirmPassword)) {
        alert(
          "กรุณาระบุรหัสผ่าน เป็นตัวอักษรภาษาอังกฤษตัวใหญ่ ตัวเล็ก และตัวเลข ความยาวไม่น้อยกว่า 8 ตัวอักษร"
        );
        return;
      }
      const [response, error, network] = await SetPassword.post({
        data: {
          password: Password,
          confirm_password: ConfirmPassword,
        },
        apiToken: localApiToken,
      });
      if (!error) {
        console.log(response);
        // Clear Data
        setUsername("");
        setEmployeeNumber("");
        setPassword("");
        setConfirmPassword("");
        setModalMessage({ title: "Error", messages: [] });
        setHidePassword(true);
        setHideConfirmPassword(true);

        setSub("SubRegisSuccess");
      } else {
        console.log(error);
        console.log(network);
      }
    } else if (message === "clickButton" && params.name === "Home") {
      setSub("SubLogin");
    } else if (message === "clickButton" && params.name === "Login") {
      var error_message = [];
      if (Username === "") {
        error_message.push("กรุณาระบุชื่อผู้ใช้");
        setModalMessage({
          title: "มีข้อผิดพลาดในการ Login",
          messages: error_message,
        });
        setModalOpen(true);
        return;
      }
      if (Password === "") {
        error_message.push("กรุณาระบุรหัสผ่าน");
        setModalMessage({
          title: "มีข้อผิดพลาดในการ Login",
          messages: error_message,
        });
        setModalOpen(true);
        return;
      }
      await getRegisterServiceWorker()
      const Fingerprint2 = require("fingerprintjs2");
      let device_name;
      let deviceType = "";
      let deviceId = "";
      let subscription;
      try {
        subscription = await getUserSubscription();
        if (!subscription) {
          subscription = await createNotificationSubscription();
          console.log("LCB Success get notification subscription.");
        }
      } catch (err) {
        console.log("LCB error getSubScriptionObejct");
        console.error(
          "Couldn't create the notification subscription",
          err,
          "name:",
          err.name,
          "message:",
          err.message,
          "code:",
          err.code
        );
      }
      const options = {};
      let components = await Fingerprint2.getPromise(options);
      let values = components.map((component: any) => component.value);
      let device_id = Fingerprint2.x64hash128(values.join(""), 31);
      if (Fingerprint2) {
        deviceType = "webpush";
        deviceId = device_id;
        device_name = `${getBrowserName()} on ${getOSName()}`;
      }
      console.log(subscription)
      const [response, error, network] = await RequestToken.post({
        data: {
          username: Username,
          password: Password,
          application: "invtracking",
          ...(deviceType && { device_type: deviceType }),
          ...(deviceId && { device_name: deviceId }),
          ...(subscription && { device_token: JSON.stringify(subscription) }),
        },
        apiToken: props.apiToken,
      });
      if (!error) {
        console.log(response);
        props.onEvent({
          message: "login",
          params: { value: response.token },
        });
        // setSub('SubSetPassword')
      } else {
        setModalOpen(true);
        var messages = [];
        if (error) {
          messages.push("ชื่อผู้ใช้งาน หรือ รหัสผ่านไม่ถูกต้อง");
        } else {
          for (const name of Object.keys(error)) {
            messages.push(name);
            for (const msg of error[name]) {
              messages.push("- " + msg);
            }
          }
        }
        setModalMessage({
          title: "มีข้อผิดพลาดในการ Login",
          messages: messages,
        });
        console.log(error);
        console.log(network);
      }
    }
  };

  const checkPasswordPolicy = (another: string, password: string) => {
    var lowerCaseLetters = /[a-z]/g;
    var upperCaseLetters = /[A-Z]/g;
    var numbers = /[0-9]/g;
    var pass = !!password.match(lowerCaseLetters);
    pass = pass && !!password.match(upperCaseLetters);
    pass = pass && !!password.match(numbers);
    pass = pass && password.length >= 8;

    if (pass && !password.localeCompare(another)) {
      return true;
      // setShowAlertText(false)
    } else {
      return false;
      // setShowAlertText(true)
    }
  };

  return (
    <div style={{ height: "100%" }}>
      {sub === "SubLogin" ? (
        <SubLogin
          viewData={viewData}
          onEvent={handleEvent}
          onForgetPassword={props.onForgetPassword}
          onHostnameChange={props.onHostnameChange}
        />
      ) : sub === "SubVerify" ? (
        <SubVerify
          viewData={viewData}
          onEvent={handleEvent}
          verifyError={TRKVerifyEmployeeError}
          loading={verifyLoading}
        />
      ) : sub === "SubSetPassword" ? (
        <SubSetPassword
          viewData={viewData}
          onEvent={handleEvent}
          showPasswordValidationText={showAlertText}
        />
      ) : sub === "SubRegisSuccess" ? (
        <SubRegisSuccess viewData={viewData} onEvent={handleEvent} />
      ) : (
        <></>
      )}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        style={{ padding: "50px" }}
      >
        <h1>{modalMessage.title}</h1>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {modalMessage.messages.map((item, index) => (
            <div key={index} style={{ margin: "5px" }}>
              {item}
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default Card;

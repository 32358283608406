import React, { useState, useEffect } from "react";
import { Button, Dimmer, Loader, Input, Icon } from "semantic-ui-react";
import ErrorMessage from "../react-lib/apps/common/ErrorMessage";
import { TRKVerifyEmployee } from "../react-lib/apis/issara/apps/TRK/INVUser";
import {
  SetPassword
} from "../react-lib/apis/issara/apps/TRK/INVmanual";
import { withCookies } from "react-cookie";

const MPC = "/static/images/logo_mpc.png";
const ANB ="/static/images/logo_anb.png";

const CardForgetPassword = (props: any) => {
  const [phoneNo, setPhoneNo] = useState("");
  const [employeeNumber, setEmployeeNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [verifySuccess, setVerifySuccess] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [apiToken, setApiToken] = useState<string | null>(null)

  React.useEffect(() => {
    if(!apiToken){
      setVerifySuccess(false);  
    } else {
      setVerifySuccess(true);
    }
  }, [apiToken])

  const handleVerifyEmployee = async () => {
    setIsLoading(true);
    setError(null);
    const [response, error, network] = await TRKVerifyEmployee.list({
      params: {
        username: phoneNo,
        employee_number: employeeNumber
      }
    });
    setIsLoading(false);
    if (error) {
      if (error.length > 200) {
        setError(`${network.response.status}: ${network.response.statusText}`);
      } else {
        setError(error);
      }
    } else {
      if(response.items && response.items.length > 0){
        setApiToken(response.items[0].api_token)
      }
    }
  };

  const handleSetPassword = async () => {
    setIsLoading(true);
    setError(null);
    const [response, error, network] = await SetPassword.post({
      data: {
        password: password,
        confirm_password: confirmPassword
      },
      apiToken: apiToken
    });
    if (!error) {
      // window.location.reload()
      props.onSetPasswordSuccess()
      console.log("success")
    } else {
      setIsLoading(false);
      if (error.length > 200) {
        setError(`${network.response.status}: ${network.response.statusText}`);
      } else {
        setError(error);
      }
    }
  }

  return (
    <div style={{ padding: "20px" }} className="main-forget-password">
      <Dimmer.Dimmable>
        <Dimmer active={isLoading} inverted>
          <Loader inverted />
        </Dimmer>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <img style={{ height: "60px" }} src={props.cookies.get('hostName') === "ANB"? `${process.env.PUBLIC_URL +ANB}` : `${process.env.PUBLIC_URL+MPC}`} />
          <h4 style={{ color: "#00529C" }}>ลืมรหัสผ่าน</h4>
        </div>
        <div
          style={{
            margin: "auto",
            width: "70%",
            textAlign: "center",
            marginTop: "3%"
          }}
        >
          <h2 style={{ color: "#00529C" }}>กรุณาระบุข้อมูลเพื่อยืนยันตัวตน</h2>
          <br />
          <ErrorMessage error={error} />
          <br />
          <div style={{ display: "flex" }}>
            <div style={{ alignSelf: "center", color: "#00529C" }}>
              <b>
                เบอร์โทรศัพท์<span style={{ color: "red" }}>*</span>
              </b>
            </div>
            &ensp;
            <Input
              style={{ width: "100%" }}
              value={phoneNo}
              onChange={e => setPhoneNo(e.target.value)}
            />
          </div>
          <br />
          <div style={{ display: "flex" }}>
            <div style={{ alignSelf: "center", color: "#00529C" }}>
              <b>
                รหัสพนักงาน<span style={{ color: "red" }}>*</span>
              </b>
            </div>
            &ensp;
            <Input
              style={{ width: "100%" }}
              value={employeeNumber}
              onChange={e => setEmployeeNumber(e.target.value)}
            />
          </div>
          <br />
          {!verifySuccess && (
            <Button
              disabled={!phoneNo || !employeeNumber}
              size="big"
              style={{
                backgroundColor: "#D61A1A",
                color: "White"
              }}
              onClick={handleVerifyEmployee}
            >
              ตรวจสอบข้อมูล
            </Button>
          )}
          {verifySuccess && (
            <Button
              size="big"
              style={{
                backgroundColor: "#39B54A",
                color: "White"
              }}
            >
              ตรวจสอบข้อมูลสำเร็จ
            </Button>
          )}
          <br />
          <br />
          {verifySuccess && (
            <>
              <div
                style={{
                  backgroundColor: "rgba(40, 134, 189, 0.15)",
                  padding: "20px"
                }}
              >
                <h4 style={{ color: "#00529C" }}>กรุณาตั้งรหัสผ่าน</h4>
                <div style={{ color: "red" }}>
                  กรุณาระบุรหัสผ่าน เป็นตัวอักษรภาษาอังกฤษ ตัวใหญ่ ตัวเล็ก
                  และตัวเลข ความยาวไม่น้อยกว่า 8 ตัวอักษร
                </div>
                <br />
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      alignSelf: "center",
                      color: "#515151",
                      width: "100px"
                    }}
                  >
                    <b>
                      รหัสผ่าน<span style={{ color: "red" }}>*</span>
                    </b>
                  </div>
                  &ensp;
                  <Input
                    type={showPassword ? "text" : "password"}
                    className="white-input"
                    style={{ width: "100%" }}
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                  <div
                    style={{
                      alignSelf: "center",
                      color: "#BDBDBD",
                      marginLeft: "10px"
                    }}
                  >
                    <Icon
                      style={{ cursor: "pointer" }}
                      name={showPassword ? "eye" : "eye slash"}
                      color='grey'
                      size="large"
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  </div>
                </div>
                <br />
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      alignSelf: "center",
                      color: "#515151",
                      width: "100px"
                    }}
                  >
                    <b>
                      ยืนยันรหัสผ่าน<span style={{ color: "red" }}>*</span>
                    </b>
                  </div>
                  &ensp;
                  <Input
                    type={showConfirmPassword ? "text" : "password"}
                    className="white-input"
                    style={{ width: "100%" }}
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                  />
                  <div
                    style={{
                      alignSelf: "center",
                      color: "#BDBDBD",
                      marginLeft: "10px"
                    }}
                  >
                    <Icon
                      style={{ cursor: "pointer" }}
                      name={showConfirmPassword ? "eye" : "eye slash"}
                      color="grey"
                      size="large"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    />
                  </div>
                </div>
              </div>
              <br/>
              <Button
                onClick={handleSetPassword}
                disabled={!confirmPassword || !password || (confirmPassword !== password)}
                size="big"
                style={{
                  backgroundColor: "#00529C",
                  color: "White"
                }}
              >
                ยืนยันการแก้ไขรหัสผ่าน
              </Button>
            </>
          )}
        </div>
      </Dimmer.Dimmable>
    </div>
  );
};

CardForgetPassword.defaultProps = {
  onSetPasswordSuccess: () => {}
}

export default withCookies(React.memo(CardForgetPassword));

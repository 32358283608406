import React, { useState, useEffect, useRef, useContext, useReducer } from "react";
import {
  Modal,
  Dropdown,
  TextArea,
  Button,
  Dimmer,
  Loader,
  Accordion,
  Icon,
  Checkbox
} from "semantic-ui-react";
import {
  DOC_NAME,
  DOC_TYPE_TEXT,
  DOC_TYPE_TEXT_TH
} from "../../Common";
import {
  InvoiceDetailNoteList
} from "../../react-lib/apis/issara/apps/TRK/INVInvoice";
import ErrorMessage from "../../react-lib/apps/common/ErrorMessage";
import {
  RelatedReasonList,
  InvoiceRelatedDocumentDetail,
  CheckInvoiceChangeStateByPresentUser,
  InvoiceDetailInvoiceRelatedDocumentFromStatusView
} from "../../react-lib/apis/issara/apps/TRK/INVcommon";
import {
  optionsReducer,
  optionsReducerInitial
} from "../../react-lib/utils/reducer";
import { AuthenContext } from "../MainContext";
import moment from "moment";
import "./ModUpdateDocNote.css";

const ModUpdateDocNote = (props: any) => {
  const [documentId, setDocumentId] = useState<any>(null)
  const [note, setNote] = useState<any>("")
  const [reason, setReason] = useState<any>("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeAcc, setActiveAcc] = useState<number[]>([2]);
  const [documentOptions, setDocumentOptions] = useState([])
  const [tableDataUploadedList, setTableDataUploadedList] = useState<any>([]);
  const [tableDataRemarkList, setTableDataRemarkList] = useState<any>([]);
  const [docLost, setDocLost] = useState(false)
  const [reasonOptionsState, dispatchReasonOptions] = useReducer(
    optionsReducer,
    optionsReducerInitial
  );
  const isMounted = useRef(true);

  const authen = useContext<any>(AuthenContext)

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    // handleSetDocumentOptions()
    if(props.invoice){
      setIsLoading(true)
      createDocumentDetailList()
    }
  }, [props.invoice]);

  useEffect(() => {
    clear();
  }, [props.open]);

  // useEffect(() => {
  //   handleGetReasonChoices()
  // }, [authen?.apiToken])

  const clear = () => {
    setNote("");
    setReason("");
    setError(null);
    setDocumentId(null);
    setDocLost(false);
  };

  // const handleGetReasonChoices = async () => {
  //   if(!authen && !authen?.apiToken){
  //     return
  //   }
  //   dispatchReasonOptions({ type: "get" });
  //   const [res, err, net] = await RelatedReasonList.retrieve({
  //     apiToken: authen?.apiToken
  //   });
  //   if (isMounted.current) {
  //     if (res) {
  //       let newArr = res.items.map(
  //         (item: { id: number; value: string; name: string }) => ({
  //           key: item.id,
  //           value: item.value,
  //           text: item.name
  //         })
  //       );
  //       dispatchReasonOptions({ type: "success", value: newArr });
  //     } else {
  //       dispatchReasonOptions({ type: "error", value: "error" });
  //     }
  //   }
  // };

  // const handleSave = async () => {
  //   setIsLoading(true);
  //   setError(null);
  //   const [res, err, net] = await InvoiceRelatedDocumentDetail.patch({
  //     pk: documentId,
  //     apiToken: authen.apiToken,
  //     data: {
  //       note,
  //       action: "INFO",
  //       reason,
  //       lost: docLost
  //     }
  //   });
  //   if (isMounted.current) {
  //     if (res) {
  //       await props.onSuccess(props.invoice.id);
  //       setIsLoading(false);
  //     } else {
  //       setIsLoading(false);
  //       let errText = err;
  //       if (err.length > 200) {
  //         errText = `${net.response.status}: ${net.response.statusText}`;
  //       }
  //       setError(errText);
  //     }
  //   }
  // };

  const handleAccClick = (e: any, { index }: any) => {
    if (activeAcc.includes(index)) {
      setActiveAcc(activeAcc.filter((item: any) => item !== index));
    } else {
      let newArr = [...activeAcc];
      newArr.push(index);
      setActiveAcc(newArr);
    }
  };

  const prepareDetail = async (invoice: any) => {
    const [r, e, n] = await InvoiceDetailNoteList.retrieve({
      pk: invoice.id,
      apiToken: authen?.apiToken,
    });
    if (e) {
      console.log(
        "Error from props.invoice",
        props.invoice
      );
      console.log(" Error from e: ", Error, e);
      console.log("Error from n:", n);
      return ;
    } else {
      if(r.scanned_documents){
        setTableDataUploadedList(setTableDataScannedDocument(r.scanned_documents))
      }
      setTableDataRemarkList(setTableDataRemarkDocument(r.note_with_documents))
    }
  };

  const createDocumentDetailList = () => {
    setIsLoading(true)
    console.log(props.invoice.id)
    if(props.invoice.id && (props.invoice.status !== "CREATED" || props.invoice.status !== "TRANSPORTING")){
      prepareDetail(props.invoice);
    }
    setIsLoading(false)
  
  };

  const setTableDataScannedDocument = (doc: any) => {
    var duplicate = 0;
    var filterDuplicate = 0;
    doc.forEach((item: any) => {
      if (item.seq === 9 || item.seq === 10) {
        if (duplicate === 0) {
          duplicate = item.seq;
        } else {
          filterDuplicate = item.seq;
        }
      }
    });
    if (filterDuplicate !== 0) {
      doc = doc.filter(
        (item: any) => item.seq !== filterDuplicate
      );
    }

    return doc.filter((item: any) => item.status !== "CREATED").map((item: any) => {
      let docName = DOC_TYPE_TEXT_TH[item.doc_type];
      if (item.doc_type === DOC_TYPE_TEXT.GENERAL) {
        docName = `${item.seq}. ${DOC_NAME[item.seq]}`;
      }
      return (
        <tr key={item.id}>
          <td>{docName}</td>
          <td>{item.document_upload_status}</td>
          {/* <td>{item.holder_label}</td> */}
          {/* <td className="note">{item.reason}</td> */}
          {/* <td className="note">{item.note}</td> */}
          <td>{item.holder_label}</td>
          <td>{moment(item.edited).format("DD/MM/YYYY HH:mm")}</td>
        </tr>
      );
    });
  }

  const setTableDataRemarkDocument = (doc: any) => {
    var duplicate = 0;
    var filterDuplicate = 0;
    doc.forEach((item: any) => {
      if (item.seq === 9 || item.seq === 10) {
        if (duplicate === 0 && item.last_note) {
          duplicate = item.seq;
        } else {
          filterDuplicate = item.seq;
        }
      }
    });
    if (filterDuplicate !== 0) {
      doc = doc.filter(
        (item: any) => item.seq !== filterDuplicate
      );
    }

    return doc.map((item: any) => {
      let docName = DOC_TYPE_TEXT_TH[item.doc_type];
      if (item.doc_type === DOC_TYPE_TEXT.GENERAL) {
        docName = `${item.seq}. ${DOC_NAME[item.seq]}`;
      }
      return (
        <tr key={item.id}>
          <td>{docName}</td>
          <td>{item.last_note?item.last_note:"-"}</td>
          <td>{item.last_edited_user?item.last_edited_user:"-"}</td>
          <td>{item.last_time_note?moment(item.last_time_note).format("DD/MM/YYYY HH:mm"): "-"}</td>
        </tr>
      );
    });
  }


  // const handleSetDocumentOptions = () => {
  //   let doc = props.invoice.related_documents;
  //   if (!doc) {
  //     doc = [];
  //   }
  //   let options = doc.map((item: any) => {
  //     let docName = DOC_TYPE_TEXT_TH[item.doc_type];
  //     if (item.doc_type === DOC_TYPE_TEXT.GENERAL) {
  //       docName = `${item.seq}. ${DOC_NAME[item.seq]}`;
  //     }
  //     return ({
  //       key: item.id,
  //       value: item.id,
  //       text: docName
  //     })
  //   })
  //   setDocumentOptions(options)
  // }

  // const handleSelectDocument = (e: any, { value }: any) => {
  //   let doc = props.invoice.related_documents;
  //   if (!doc) {
  //     doc = [];
  //   }
  //   let selectedDoc = doc.find((item: any) => item.id === value)
  //   if(selectedDoc){
  //     if(selectedDoc.reason){
  //       setReason(selectedDoc.reason)
  //     } else {
  //       setReason("")
  //     } 
  //     if(selectedDoc.note){
  //       setNote(selectedDoc.note)
  //     } else {
  //       setNote("")
  //     }
  //     if(selectedDoc.lost){
  //       setDocLost(selectedDoc.lost)
  //     } else {
  //       setDocLost(false)
  //     }
  //     setDocumentId(selectedDoc.id)
  //   }
  // }

  return (
    <Modal
      className="mod-update-doc-note"
      size="large"
      open={props.open}
      onClose={props.onClose}
    >
      <Dimmer.Dimmable>
        <Dimmer active={isLoading} inverted>
          <Loader inverted />
        </Dimmer>
        <div>
          <ErrorMessage error={error} />
          <Accordion styled fluid>
            {/* <Accordion.Title
              index={0}
              active={activeAcc.includes(0)}
              onClick={handleAccClick}
            >
              <h3>
                <Icon name="dropdown" />
                กรอกรายละเอียดเอกสาร
              </h3>
            </Accordion.Title>
            <Accordion.Content active={activeAcc.includes(0)}>
              <div className="row">
                <div className="key-field">Invoice No.:</div>
                <div className="input-field">{props.invoice.serial}</div>
              </div>
              <div className="row">
                <div className="key-field">&nbsp;</div>
                <div className="input-field">
                  <Checkbox label="เอกสารสูญหาย" checked={docLost} onChange={() => setDocLost(!docLost)}/>
                </div>
              </div>
              <div className="row">
                <div className="key-field">เอกสาร</div>
                <div className="input-field">
                  <Dropdown 
                    fluid 
                    search 
                    selection 
                    value={documentId}
                    options={documentOptions}
                    onChange={handleSelectDocument}
                  />
                </div>
              </div>
              <div className="row">
                <div className="key-field">เหตุผล</div>
                <div className="input-field">
                  <Dropdown 
                    fluid
                    search 
                    selection 
                    clearable
                    options={reasonOptionsState.options}
                    error={!!reasonOptionsState.error}
                    loading={reasonOptionsState.loading}
                    value={reason}
                    onChange={(e, { value }) => setReason(value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="key-field">หมายเหตุ</div>
                <div className="input-field">
                  <TextArea
                    style={{ width: "100%" }}
                    rows={4}
                    value={note}
                    onChange={(e, { value }) => {
                      setNote(value as string);
                    }}
                  />
                </div>
              </div>
              <div className="last row">
                <Button
                  color="green"
                  disabled={!documentId}
                  onClick={handleSave}
                >
                  บันทึก
                </Button>
              </div>
            </Accordion.Content> */}
            <Accordion.Title
              index={1}
              active={activeAcc.includes(1)}
              onClick={handleAccClick}
            >
              <h3>
                <Icon name="dropdown" />
                รายละเอียดเอกสาร
              </h3>
            </Accordion.Title>
            <Accordion.Content active={activeAcc.includes(1)}>
              <div className="table-wrapper">
                <table>
                  <thead>
                    <tr>
                      <th>เอกสาร</th>
                      <th>สถานะ</th>
                      {/* <th>ผู้ถือเอกสาร</th>
                      <th>เหตุผล</th>
                      <th>หมายเหตุ</th> */}
                      <th>ผู้แก้ไข</th>
                      <th>เวลาแก้ไข</th>
                    </tr>
                  </thead>
                  <tbody>{tableDataUploadedList}</tbody>
                </table>
              </div>
            </Accordion.Content>
            <Accordion.Title
              index={2}
              active={activeAcc.includes(2)}
              onClick={handleAccClick}
            >
              <h3>
                <Icon name="dropdown" />
                รายการหมายเหตุ
              </h3>
            </Accordion.Title>
            <Accordion.Content active={activeAcc.includes(2)}>
              <div className="table-wrapper">
                <table>
                  <thead>
                    <tr>
                      <th>เอกสาร</th>
                      <th>หมายเหตุ</th>
                      <th>ผู้แก้ไข</th>
                      <th>เวลาแก้ไข</th>
                    </tr>
                  </thead>
                  <tbody>{tableDataRemarkList}</tbody>
                </table>
              </div>
            </Accordion.Content>
          </Accordion>
        </div>
      </Dimmer.Dimmable>
    </Modal>
  );
};

ModUpdateDocNote.defaultProps = {
  invoice: {},
  onSuccess: () => {},
  onClose: () => {},
  open: false
};

export default React.memo(ModUpdateDocNote);

import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  SyntheticEvent,
} from "react";
import MainLayout from "./Sub/MainLayout";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  Button,
  Modal,
  Input,
  TextArea,
  Dimmer,
  Loader,
  Dropdown,
  DropdownProps,
  Header,
  Icon,
} from "semantic-ui-react";
import {
  EmployeeList,
  EmployeeDetail,
  EmployeeSerializer,
  EmployeeCheckValid
} from "../react-lib/apis/issara/apps/TRK/INVEmployeeExtra";
import { EmployeeDelete } from "../react-lib/apis/issara/apps/TRK/INVcommon";
import { processEmployees, readFileAsync, checkEmployees } from "./Sub/Excel";
import ErrorMessage from "../react-lib/apps/common/ErrorMessage";
import * as CONSTANT from "../react-lib/utils/constant";
import { EmployeeDetailFromCode } from "../react-lib/apis/issara/apps/TRK/INVEmployee";
import { Choices } from "../react-lib/apis/issara/apps/TRK/INVChoices";
import { PositionList } from "../react-lib/apis/issara/apps/TRK/INVcommon";
interface SemanticUIReactTextArea extends TextArea {
  ref: React.MutableRefObject<HTMLTextAreaElement>;
}

export default class Card extends React.Component<
  {
    onEvent: ({ message, params }: { message: string; params: any }) => void;
    apiToken: string;
    login_employee: any;
  },
  {
    sub: string;
    employees: EmployeeSerializer[];
    employees_upload: any[];
    modalOpen: boolean;
    uploadFinish: boolean;
    extendEmployeeTable: boolean;
    openRemoveModal: boolean;
    selectedEmployee: EmployeeSerializer[];
    createEmployeeError?: any;
    isLoading: boolean;
    removeEmployeeError?: any;
    openAlertModal: boolean;
    alertModalMessage: string;
    openSuccessModal: boolean;
    successMessage: string;
  }
> {
  private employeeAddRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);
    this.state = {
      openRemoveModal: false,
      sub: "EmployeeSummary",
      employees: [],
      employees_upload: [],
      modalOpen: false,
      uploadFinish: false,
      extendEmployeeTable: false,
      selectedEmployee: [],
      createEmployeeError: null,
      isLoading: false,
      removeEmployeeError: null,
      openAlertModal: false,
      alertModalMessage: "",
      openSuccessModal: false,
      successMessage: "",
      
    };

    this.employeeAddRef = React.createRef();
  }

  componentDidMount = () => {
    this.getData();
  };

  handleEvent = async ({
    message,
    params,
  }: {
    message: string;
    params: any;
  }) => {
    console.log(message);
    if (message === "clickMenu") {
      this.setState({ sub: "EmployeeSummary" });
      this.props.onEvent({ message, params });
    } else if (message === "clickButton" && params.name === "AddEmployee") {
      this.setState({ sub: "EmployeeAdd" });
    } else if (message === "clickButton" && params.name === "chooseFile") {
      try {
        const arrayBuffer = (await readFileAsync(
          params.value.files[0]
        )) as ArrayBuffer;
        var employees_upload = processEmployees(arrayBuffer);
        employees_upload = await checkEmployees(
          employees_upload,
          this.props.apiToken
        );
        console.log("employees_upload", employees_upload);

        if (employees_upload && employees_upload.length > 2) {
          const duplicated = employees_upload.filter( item => {
            return ( employees_upload.filter( t1 => { 
                return (t1.employee_no === item.employee_no )}).length > 1 ) 
          })
          if (duplicated && duplicated.length >= 2) {
            console.log(' found Duplicate ', duplicated[0].employee_no)
            this.setState({
              openAlertModal: true,
              alertModalMessage: duplicated[0].employee_no.toString() 
            })
            return 
          }
        }
        

        this.setState({
          employees_upload: employees_upload,
          modalOpen: true,
          extendEmployeeTable: false,
        });
      } catch {
        console.log("error choosing file");
      }
    } else if (message === "clickButton" && params.name === "uploadEmployee") {
      console.log("uploading employee....");
      this.setState({ uploadFinish: false, extendEmployeeTable: true });
      for (var index = 0; index < this.state.employees_upload.length; index++) {
        const employee = this.state.employees_upload[index];
        if (employee.verified == true) {
          console.log("update", employee);
          const [r, e, n] = await EmployeeDetail.patch({
            pk: employee.id,
            apiToken: this.props.apiToken,
            data: {
              ...employee,
            },
          });
          if (e) {
            console.log("e", e);
            console.log("n", n);
            this.updateUploadResult(
              index,
              "ไม่สำเร็จ",
              e && e.detail ? e.detail : e && e.length > 0 && e[0] ? e[0] : "-"
            );
          } else {
            console.log(r);
            this.updateUploadResult(index, "สำเร็จ", "-");
            this.getData();
          }
        } else {
          console.log("create", employee);
          const [r, e, n] = await EmployeeList.create({
            apiToken: this.props.apiToken,
            data: {
              ...employee,
            },
          });
          if (e) {
            console.log("e", e);
            console.log("n", n);
            this.updateUploadResult(
              index,
              "ไม่สำเร็จ",
              e && e.detail ? e.detail : e && e.length > 0 && e[0] ? e[0] : "-"
            );
            // break
          } else {
            console.log(r);
            this.updateUploadResult(index, "สำเร็จ", "-");
          }
        }
      }
      this.setState({ uploadFinish: true });
    } else if (message === "clickButton" && params.name === "createEmployee") {
      console.log("creating employee....");
      this.setState({ isLoading: true, createEmployeeError: null });
      const [r, e, n] = await EmployeeList.create({
        apiToken: this.props.apiToken,
        data: {
          ...params.value,
        },
      });
      this.setState({ isLoading: false });
      if (e) {
        if (e.length > 200) {
          this.setState({
            createEmployeeError: `${n.response.status}: ${n.response.statusText}`,
          });
        } else {
          this.setState({ createEmployeeError: e });
        }
        console.log(e);
        console.log(n);
      } else {

        // TODO: Clear data
        this.setState({ openSuccessModal: true, successMessage: r.first_name + " " + r.last_name})
        // console.log(r);
        this.employeeAddRef.current.clearData()

      }
    } else if (message === "clickButton" && params.name === "BackToSummary") {
      this.setState({ sub: "EmployeeSummary", openSuccessModal: false });
    } else if (message === "clickButton" && params.name === "removeEmployee") {
      console.log("remove employee params: ", params);

      this.setState({ openRemoveModal: true, selectedEmployee: params.value });
    } else if (message === 'employeeSearch'){
      this.getData(params)
    }
  };

  clearFile = () => {
    this.employeeAddRef.current.fileRef.value = "";
    this.setState({ employees_upload: [] });
  };

  handleEmployeeModalClose = () => {
    this.setState({ modalOpen: false });
    this.clearFile();
  };

  getData = async (param?:any) => {
    console.log(param)
    const [response, error, network] = await EmployeeList.list({
      params: {
        limit:99999,
        ...param
      },
      apiToken: this.props.apiToken,
    });
    if (error) {
      console.log(error);
      console.log(network);
      return;
    }
    console.log(response.items);
    this.setState({ employees: response.items as EmployeeSerializer[] });
  };

  setModalOpen = (open: boolean) => {
    this.setState({ modalOpen: open, uploadFinish: open });
  };

  handleRemoveEmployee = async (
    userRemove: boolean,
    reason: string = "",
    employeeCode: string = ""
  ) => {
    this.setState({
      removeEmployeeError: null,
    });
    if (userRemove) {
      // Execute remove
      let arr = this.state.selectedEmployee.map((item) => item.employee);
      const [response, error, network] = await EmployeeDelete.update({
        // pk: pk,
        apiToken: this.props.apiToken,
        data: {
          inactive_reason: reason,
          employee_id_list: arr,
          user_code: employeeCode,
        },
      });
      if (response) {
        this.setState({ isLoading: false });
        this.setState({ selectedEmployee: [] });
        this.getData();
      } else {
        if (error.length > 200) {
          return this.setState({
            isLoading: false,
            removeEmployeeError: "เกิดข้อผิดพลาด",
          });
        } else {
          this.setState({
            isLoading: false,
            removeEmployeeError: error,
          });
        }
      }
      // let promiseArr = this.state.selectedEmployee.map(item => {
      //   let returnFuc = this.handleEmployeeDelete({ pk: item.employee, reason })
      //   return returnFuc;
      // });
      // //loading
      // this.setState({ isLoading: true })

      // Promise.allSettled(promiseArr).then((result: any) => {
      //   let success = result.filter((item: any) => item.status === "fulfilled");
      //   let fail = result.filter((item: any) => item.status === "rejected");
      //   if (success.length === promiseArr.length) {
      //     this.setState({ isLoading: false })
      //     this.setState({selectedEmployee: []})
      //     this.getData()
      //   } else {
      //     this.setState({ isLoading: false, removeEmployeeError: "เกิดข้อผิดพลาด" })
      //   }
      // });

      // const [response, error, network] = await EmployeeDelete.update({
      //   pk: this.state.selectedEmployee?.employee,
      //   apiToken: this.props.apiToken,
      //   data: {
      //     inactive_reason: reason,
      //   }
      // })
      // if (error) {
      //   console.log('handleRemoveEmployee error', error)
      //   return;
      // }
      // console.log('handleRemoveEmployee response', response)
    } else {
      // Execute remove
      console.log("ignore");
    }
    this.setState({ openRemoveModal: false });
  };

  updateUploadResult = (index: number, result: string, reason: string) => {
    console.log(this.state.employees_upload);
    const newEmployeeUpload = this.state.employees_upload.map(
      (item: any, item_index: number) => {
        if (index === item_index) {
          return { ...item, upload_result: result, reason: reason };
        } else {
          return item;
        }
      }
    );
    console.log(newEmployeeUpload);
    this.setState({ employees_upload: newEmployeeUpload });
  };

  handleOnCloseAlertModal = () => {
    this.setState({openAlertModal: false, alertModalMessage: ""})
  }

  handleOnCloseSuccessModal = () => {
    this.setState({openSuccessModal: false, successMessage: ""})
  }

  render() {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <Dimmer.Dimmable>
          <Dimmer active={this.state.isLoading} inverted>
            <Loader inverted />
          </Dimmer>
          <EmployeeRemove
            open={this.state.openRemoveModal}
            onClose={this.handleRemoveEmployee}
            apiToken={this.props.apiToken}
          />
          <AlertModal 
            open={this.state.openAlertModal}
            onClose={this.handleOnCloseAlertModal}
            message={this.state.alertModalMessage}
          />
          <SuccessModal 
            open={this.state.openSuccessModal}
            onClose={this.handleOnCloseSuccessModal}
            message={this.state.successMessage}
            onEvent={this.handleEvent}/>


          <EmployeeModal
            modalOpen={this.state.modalOpen}
            setModalOpen={this.setModalOpen}
            employees_upload={this.state.employees_upload}
            onEvent={this.handleEvent}
            uploadFinish={this.state.uploadFinish}
            extendEmployeeTable={this.state.extendEmployeeTable}
            onClose={this.handleEmployeeModalClose}
            onClearUploadFinish={() => this.setState({ uploadFinish: false })}
          />
          {this.state.sub === "EmployeeSummary" ? (
            <EmployeeSummary
              login_employee={this.props.login_employee}
              viewData={{
                EmployeeList: this.state.employees,
              }}
              onEvent={this.handleEvent}
              error={this.state.removeEmployeeError}
            />
          ) : this.state.sub === "EmployeeAdd" ? (
            <EmployeeAdd
              login_employee={this.props.login_employee}
              onEvent={this.handleEvent}
              ref={this.employeeAddRef}
              error={this.state.createEmployeeError}
              apiToken={this.props.apiToken}
            />
          ) : this.state.sub === "EmployeeSummary" ? (
            <EmployeeSummary
              login_employee={this.props.login_employee}
              viewData={{
                EmployeeList: this.state.employees,
              }}
              onEvent={this.handleEvent}
            />
          ) : this.state.sub === "EmployeeAdd" ? (
            <EmployeeAdd
              login_employee={this.props.login_employee}
              onEvent={this.handleEvent}
              error={this.state.createEmployeeError}
            />
          ) : (
            // sub === "SubUploadConfirm" ?
            // <SubUploadConfirm data={data} onEvent={handleEvent} />:

            // sub === "SubDeleteConfirm" ?
            // <SubDeleteConfirm data={data} onEvent={handleEvent} />:

            <></>
          )}
        </Dimmer.Dimmable>
      </div>
    );
  }
}

const SuccessModal = (props: any) => {
  return (
    <Modal
        size="small"
        open={props.open}
        onClose={props.onClose}
      >
        <Modal.Header>เพิ่มพนักงานเรียบร้อย</Modal.Header>
        <Modal.Content>
          <p>{props.message}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button positive onClick={props.onClose}>
            เพิ่มพนักงานอีกครั้ง
          </Button>
          <Button color="blue" onClick={() => props.onEvent({
              message: "clickButton",
              params: {
                name: "BackToSummary",
              },
            })}>
            กลับไปหน้ารายชื่อพนักงาน
          </Button>
        </Modal.Actions>
      </Modal>
  )
}

const AlertModal = (props:any) => {
  return (
    <Modal
      basic
      open={props.open}
      size='small'
    >
      <Header icon>
        <Icon name='user' />
        Employee No ซ้ำ เลขที่ {props.message}
      </Header>
      <Modal.Content>
        <p>
          พบรหัสพนักงานซ้ำในไฟล์ กรุณาแก้ไขก่อนอัพโหลดอีกครั้ง
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color='red' inverted onClick={props.onClose}>
          <Icon name='remove' /> OK
        </Button>
       
      </Modal.Actions>
    </Modal>
  )
}


const ConfirmEmployeeUpdateModal = (props:any) => {
  return <Modal
      open={props.open}
      size='small'
    >
      <Header>ตรวจพบพนักงานซ้ำในระบบ กดยืนยันดำเนินการอัพเดทข้อมูล</Header>
      <Modal.Actions>
      <Button positive onClick={props.onConfirm}>
          ยืนยัน
        </Button>
        <Button negative onClick={props.onClose}>
          ยกเลิก
        </Button>
      </Modal.Actions>
    </Modal>
}
const EmployeeRemove = (props: any) => {
  const [reason, setReason] = useState<string>("");
  const reasonRef = useRef<SemanticUIReactTextArea>(null);
  const [employeeCode, setEmployeeCode] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [verifyFailed, setVerifyFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validate, setValidate] = useState(false);
  const [typingTimeout, setTypingTimeOut] = useState(0);

  React.useEffect(() => {
    setEmployeeCode("");
    setValidate(false);
    setReason("");
  }, [props.open]);

  const handleTextChange = (e: any) => {
    const { value } = e.target;
    setReason(value);
  };

  const getEmployeeCode = async (code: String) => {
    setIsLoading(true);
    const [r, e, n] = await EmployeeDetailFromCode.retrieve({
      code: code,
      apiToken: props.apiToken,
      params: {
        check_for: "user_delete"
      }
    });
    setIsLoading(false);
    if (r) {
    //   if (r.position_code === "manager" || r.position_code === "acc_fin" || r.position_code === "mana_trans" ||
    //   r.position_code === "officetran") {
        setVerifyFailed(false);
        setValidate(true);
        setEmployeeName(r.first_name + " " + r.last_name);
      // } else {
      //   setVerifyFailed(true);
      //   setValidate(false);
      //   setEmployeeName("ผู้ใช้งานนี้ไม่มีสิทธิ์ในการดำเนินการ");
      // }
    } else {
      setValidate(false);
      setVerifyFailed(true);
      if(e && e.length < 80){
        setEmployeeName(e);
      }else{
        setEmployeeName("รหัสเจ้าหน้าที่ไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง");
      }
    }
  }

  const handleGetUserCode = async () => {
    getEmployeeCode(employeeCode);
  };

  const handleAutoGetEmployeeCode = (e:any) => {
    let code = e.target.value
    setEmployeeCode(code)
    if (typingTimeout) {
      window.clearTimeout(typingTimeout);
    }
    setTypingTimeOut(
      window.setTimeout(() => {
        getEmployeeCode(code)
      }, 1000)
    );

  };

  React.useEffect(() => {
    setValidate(false);
    setVerifyFailed(false);
  }, [employeeCode]);

  return (
    <Modal
      size="small"
      onclose={() => {
        props.onClose(false);
      }}
      open={props.open}
    >
      <Dimmer active={isLoading} inverted>
        <Loader inverted />
      </Dimmer>
      <Modal.Header>กรุณาระบุเหตุผลการลบพนักงาน</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <div style={{ display: "flex" }}>
            <p style={{ fontWeight: "bolder", paddingRight: "20px" }}>
              เหตุผล<span style={{ color: "red" }}>*</span>:
            </p>
            <TextArea
              ref={reasonRef}
              style={{ width: "100%" }}
              value={reason}
              onChange={handleTextChange}
            />
          </div>
          <br />
          <div style={{ display: "flex", alignItems: "center" }}>
            <b
              style={{
                fontWeight: "bolder",
                paddingRight: "20px",
                color: verifyFailed ? "#DB4632" : "#00529C",
              }}
            >
              รหัสเจ้าหน้าที่<span style={{ color: "red" }}>*</span>:
            </b>
            <Input
              type="password"
              value={employeeCode}
              onKeyDown={(e: any) => {
                if (e.keyCode === CONSTANT.KEY_CODE.ENTER) {
                  handleGetUserCode();
                }
              }}
              onChange={(e) => handleAutoGetEmployeeCode(e)}
            >
              <input
                style={{
                  color: validate ? "#00529C" : verifyFailed ? "#DB4632" : "",
                  border: verifyFailed ? "1px solid #DB4632" : "",
                  backgroundColor: verifyFailed ? "#FFDEDA" : "",
                }}
              />
            </Input>
            {validate && (
            <div style={{ paddingLeft: "10px", color: "#00529C" }}>{employeeName}</div>
          )}
          {verifyFailed && (
            <div style={{ width: "50%", paddingLeft: "10px" , color: "#DB4632", fontSize: "14px" }}>{employeeName}</div>
          )}
          </div>
          
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="red"
          onClick={() => {
            props.onClose(false);
          }}
        >
          ยกเลิก
        </Button>
        <Button
          disabled={
            reason.length === 0 || employeeCode.length === 0 || !validate || verifyFailed
          }
          color="green"
          onClick={() => {
            props.onClose(true, reason, employeeCode);
          }}
          positive
        >
          บันทึก
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const EmployeeSummary = (props: any) => {
  const gridApi = useRef<any>([]);

  const [employeeName, setEmployeeName] = useState<string>("");
  const [employeeCode, setEmployeeCode] = useState<string>("")

  const width = 150;

  const handleEvent = ({
    message,
    params,
  }: {
    message: string;
    params: any;
  }) => {
    console.log(message, params);
    if (message === "clickMenu") {
      props.onEvent({ message, params });
    }else if (message == 'filter'){
      props.onEvent({message: 'employeeSearch', params: {
        'code': employeeCode,
        'name': employeeName
      }});
    }
  };

  return (
    <MainLayout
      selectedCard="Employee"
      onEvent={handleEvent}
      login_employee={props.login_employee}
      className="main-employee"
    >
      <div style={{ padding: "20px 20px 10px 20px" }}>
        <ErrorMessage error={props.error} />
        <div className="main-employee-list">
            <div className="filter-row">
              <div className="filter-item">
                <div className="filter-label-wrapper">
                  <b>ชื่อพนักงาน</b>
                </div>
                <div className="filter-input-wrapper">
                  <Input
                    value={employeeName}
                    onChange={(e) =>
                      setEmployeeName(e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="filter-item">
                <div className="filter-label-wrapper">
                  <b>รหัสพนักงาน</b>
                </div>
                <div className="filter-input-wrapper">
                  <Input
                    value={employeeCode}
                    onChange={(e) =>
                      setEmployeeCode(e.target.value)
                    }
                  />
                </div>
                <div className="filter-item">
                  <Button content="Submit" color="blue"
                  onClick={(e) => {handleEvent({message:'filter', params: ""})}} 
                  />
              </div>
              </div>
            </div>
          </div>
        <div
          className="main-employee ag-theme-alpine"
          style={{ width: "100%", marginTop: "20px" }}
        >
          <AgGridReact
            rowMultiSelectWithClick
            rowSelection="multiple"
            gridOptions={{
              defaultColDef: {
                resizable: true,
                sortable: true,
                unSortIcon: true,
              },
            }}
            columnDefs={[
              { headerName: "รหัสพนักงาน", width: width, field: "employee_no" },
              { headerName: "ชื่อ", width: width, field: "first_name" },
              { headerName: "นามสกุล", width: width, field: "last_name" },
              {
                headerName: "วันที่ลงทะเบียน",
                width: width,
                field: "register_date",
              },
              { headerName: "เบอร์โทรศัพท์", width: width, field: "phone_no" },
              { headerName: "ตำแหน่งงาน", width: width, field: "position" },
              { headerName: "ทะเบียนรถ", width: width, field: "license_plate" },
              { headerName: "ประเภทรถ", width: width, field: "car_type" },
              { headerName: "เส้นทาง", width: width, field: "route" },
              { headerName: "E-mail", width: width, field: "email" },
              {
                headerName: "เลือกพนักงาน",
                width: width,
                checkboxSelection: true,
              },
            ]}
            rowData={props.viewData.EmployeeList}
            // onGridReady={ (params) => { gridApi.current = params.api }}
            onGridReady={(params) => {
              gridApi.current = params.api;
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "10px",
          }}
        >
          <Button
            color="red"
            onClick={(e) => {
              let node = gridApi.current.getSelectedNodes();
              let dataList = [];
              if (node && node.length > 0) {
                for (let item in node) {
                  dataList.push(node[item].data);
                }
              }
              props.onEvent({
                message: "clickButton",
                params: {
                  name: "removeEmployee",
                  value: dataList,
                },
              });
            }}
          >
            ลบรายชื่อพนักงาน
          </Button>
          <Button
            color="blue"
            onClick={(e) => {
              props.onEvent({
                message: "clickButton",
                params: {
                  name: "AddEmployee",
                },
              });
            }}
          >
            เพิ่มพนักงานเข้าใช้งาน
          </Button>
        </div>
      </div>
    </MainLayout>
  );
};

const EmployeeAdd = React.forwardRef((props: any, ref) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");
  const [employeeNo, setEmployeeNo] = useState("");
  const [position, setPosition] = useState<string>("");
  const [username, setUsername] = useState("");
  const [licensePlate, setLicensePlate] = useState("");
  const [carType, setCarType] = useState("ไม่ระบุ");
  const [route, setRoute] = useState("");
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  // const [roleList, setRoleList] = useState([])
  const [positionList, setPositionList] = useState<
    Array<{ id: string; code: string; name: string }>
  >([]);

  const fileRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  React.useImperativeHandle(ref, () => ({
    fileRef: fileRef.current,
    clearData: () => {
      setFirstName("")
      setLastName("")
      setPhoneNo("")
      setEmail("")
      setEmployeeNo("")
      setPosition("")
      setUsername("")
      setLicensePlate("")
      setCarType("")
      setRoute("")
      setConfirmModal(false)
    },
  }));

  React.useEffect(() => {
    handleGetPositionList();
  }, [props.apiToken]);

  const handleGetPositionList = async () => {
    const [res, err, network] = await PositionList.retrieve({
      apiToken: props.apiToken,
    });
    if (res) {
      setPositionList(res);
    } else {
      setPositionList([]);
    }
  };

  const positionOptions = useMemo(() => {
    const option = positionList.map(
      (item: { id: string; code: string; name: string }) => {
        let opt = {
          key: item.id,
          text: item.name,
          value: item.name,
        };
        return opt;
      }
    );
    return option;
  }, [positionList]);

  const carTypeOption = useMemo(() => {
    return  [
      { key: 3, text: "ไม่ระบุ", value: "ไม่ระบุ" },
      { key: 1, text: "รถ 4 ล้อ", value: "รถ 4 ล้อ" }, 
      { key: 2, text: "รถ 6 ล้อ", value: "รถ 6 ล้อ" }];
  }, [])

  const isTransport = useMemo(() => {
    return (
      positionList.find((item) => item.name === position)?.code === "transport"
    );
  }, [position, positionList]);

  const handleConfirmAddEmployee = () => {
    props.onEvent({
      message: "clickButton",
      params: {
        name: "createEmployee",
        value: {
          first_name: firstName,
          last_name: lastName,
          phone_no: phoneNo,
          email: email,
          employee_no: employeeNo,
          position: position,
          username: username,
          license_plate: licensePlate,
          car_type: carType,
          route: route,
        },
      },
    });
  }

  const handleOnClickAddEmployee = async(e: SyntheticEvent) => {
    const [response, error, network] = await EmployeeCheckValid.get({
      apiToken: props.apiToken,
      params: {
       first_name: firstName,
        last_name: lastName,
        code: employeeNo,
        username: username
      },
    });
    if (response){
      if(!response.is_employee_valid){
        handleConfirmAddEmployee()
      }else{
        setConfirmModal(true);
      }
    }else{
      console.log("Employee Add Error", error)
    }
  }

  return (
    <MainLayout onEvent={props.onEvent} login_employee={props.login_employee}>
      <div style={{ padding: "50px", height: "100%", width: "100%" }}>
        <h2>เพิ่มพนักงานเข้าใช้งาน</h2>
        <div style={{ display: "flex", height: "100%" }}>
          <div
            style={{
              flex: 1,
              backgroundColor: "#fcf5db",
              padding: "20px",
              marginRight: "20px",
            }}
          >
            <h3>เพิ่มพนักงาน</h3>
            <p>Import File รายชื่อพนักงาน</p>
            <p>
              <span style={{ color: "red" }}>
                *Format File (xls, xlsx, csv)
              </span>
            </p>
            <input
              accept=".xlsx,.xls,.csv"
              type="file"
              ref={fileRef}
              onChange={(e) => {
                props.onEvent({
                  message: "clickButton",
                  params: {
                    name: "chooseFile",
                    value: e.target,
                  },
                });
              }}
            />
          </div>
          <div style={{ flex: 3, backgroundColor: "#eff6fa", padding: "20px" }}>
            <h3>เพิ่มพนักงานรายบุคคล</h3>
            <ErrorMessage error={props.error} />
            <div style={{ display: "flex", margin: "20px" }}>
              <div style={{ flex: 1, alignSelf: "center" }}>
                ชื่อ <span style={{ color: "red" }}>*</span>
              </div>
              <div style={{ flex: 8 }}>
                <Input
                  fluid
                  value={firstName}
                  onChange={(e, v) => setFirstName(v.value)}
                />
              </div>
            </div>
            <div style={{ display: "flex", margin: "20px" }}>
              <div style={{ flex: 1, alignSelf: "center" }}>
                นามสกุล <span style={{ color: "red" }}>*</span>
              </div>
              <div style={{ flex: 8 }}>
                <Input
                  fluid
                  value={lastName}
                  onChange={(e, v) => setLastName(v.value)}
                />
              </div>
            </div>
            <div style={{ display: "flex", margin: "20px" }}>
              <div style={{ flex: 1, alignSelf: "center" }}>
                เบอร์โทรศัพท์ <span style={{ color: "red" }}>*</span>
              </div>
              <div style={{ flex: 8 }}>
                <Input
                  fluid
                  value={phoneNo}
                  onChange={(e, v) => setPhoneNo(v.value)}
                />
              </div>
            </div>
            <div style={{ display: "flex", margin: "20px" }}>
              <div style={{ flex: 1, alignSelf: "center" }}>Email</div>
              <div style={{ flex: 8 }}>
                <Input
                  fluid
                  value={email}
                  onChange={(e, v) => setEmail(v.value)}
                />
              </div>
            </div>
            <div style={{ display: "flex", margin: "20px" }}>
              <div style={{ flex: 1, alignSelf: "center" }}>
                รหัสพนักงาน <span style={{ color: "red" }}>*</span>
              </div>
              <div style={{ flex: 8 }}>
                <Input
                  fluid
                  value={employeeNo}
                  onChange={(e, v) => setEmployeeNo(v.value)}
                />
              </div>
            </div>
            <div style={{ display: "flex", margin: "20px" }}>
              <div style={{ flex: 1, alignSelf: "center" }}>
                ตำแหน่งงาน <span style={{ color: "red" }}>*</span>
              </div>
              <div style={{ flex: 8 }}>
                <Dropdown
                  search
                  selection
                  fluid
                  value={position}
                  options={positionOptions}
                  onChange={(e: SyntheticEvent, data: DropdownProps) => {
                    setPosition(data.value as string);
                  }}
                />
              </div>
            </div>
            <div style={{ display: "flex", margin: "20px" }}>
              <div style={{ flex: 1, alignSelf: "center" }}>
                Username <span style={{ color: "red" }}>*</span>
              </div>
              <div style={{ flex: 8 }}>
                <Input
                  fluid
                  value={username}
                  onChange={(e, v) => setUsername(v.value)}
                />
              </div>
            </div>
            <div style={{ display: "flex", margin: "20px" }}>
              <div style={{ flex: 1, alignSelf: "center" }}>
                ทะเบียนรถ{" "}
                {isTransport ? <span style={{ color: "red" }}>*</span> : null}{" "}
              </div>
              <div style={{ flex: 8 }}>
                <Input
                  fluid
                  value={licensePlate}
                  onChange={(e, v) => setLicensePlate(v.value)}
                />
              </div>
            </div>
            <div style={{ display: "flex", margin: "20px" }}>
              <div style={{ flex: 1, alignSelf: "center" }}>
                ประเภทรถ{" "}
                {isTransport ? <span style={{ color: "red" }}>*</span> : null}
              </div>
              <div style={{ flex: 8 }}>
                {/* <Input
                  fluid
                  value={carType}
                  onChange={(e, v) => setCarType(v.value)}
                /> */}
                <Dropdown
                  search
                  selection
                  fluid
                  value={carType}
                  options={carTypeOption}
                  onChange={(e: SyntheticEvent, data: DropdownProps) => {
                    setCarType(data.value as string);
                  }}
                />
              </div>
            </div>
            <div style={{ display: "flex", margin: "20px" }}>
              <div style={{ flex: 1, alignSelf: "center" }}>
                เส้นทางเดินรถ{" "}
                {isTransport ? <span style={{ color: "red" }}>*</span> : null}
              </div>
              <div style={{ flex: 8 }}>
                <Input
                  fluid
                  value={route}
                  onChange={(e, v) => setRoute(v.value)}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                margin: "20px",
                justifyContent: "flex-end",
              }}
            >
              <Button
                onClick={(e) => {
                  setFirstName("");
                  setLastName("");
                  setPhoneNo("");
                  setEmail("");
                  setEmployeeNo("");
                  setPosition("");
                  setUsername("");
                  setLicensePlate("");
                  setCarType("");
                  setRoute("");
                }}
              >
                เคลียร์ข้อมูล
              </Button>
              <Button
                color="blue"
                disabled={
                  isTransport
                    ? !firstName ||
                      !lastName ||
                      !phoneNo ||
                      !(employeeNo && employeeNo.length === 6) ||
                      !position ||
                      !username ||
                      !licensePlate ||
                      !carType ||
                      !route
                    : !(employeeNo && employeeNo.length === 6) ||
                      !position ||
                      !username ||
                      !firstName ||
                      !lastName ||
                      !phoneNo
                }
                onClick={handleOnClickAddEmployee}
              >
                เพิ่มพนักงานเข้าระบบ
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ConfirmEmployeeUpdateModal 
          open={confirmModal}
          onConfirm={()=>handleConfirmAddEmployee()}
          onClose={()=> setConfirmModal(false)}
      />
    </MainLayout>
  );
});

const EmployeeModal = (props: any) => {
  const width = 150;

  React.useEffect(() => {
    props.onClearUploadFinish();
  }, [props.modalOpen]);

  const employeeTableColumnDef = useMemo(() => {
    console.log(
      "employeeTableColumnDef create extendEmployeeTable: ",
      props.extendEmployeeTable,
      "width",
      width
    );
    console.log("employeeTableColumnDef ");
    let column = [
      {
        headerName: "การอัพโหลด",
        width: width * 0.7,
        cellStyle: function (params: any) {
          // console.log("params", params)
          if (params.value === "ไม่สำเร็จ") {
            //mark police cells as red
            return { whiteSpace: "nowrap", width: width * 0.7 };
          } else {
            return { whiteSpace: "nowrap", width: width * 0.7 };
          }
        },
        field: "upload_result",
      },
      {
        headerName: "เหตุผล",
        width: width * 2,
        field: "reason",
        resizable: true,
        wrapText: true,
        autoHeight: true,
      },
      { headerName: "ลำดับ", width: width / 2, field: "number" },
      { headerName: "รหัสพนักงาน", width: width, field: "employee_no" },
      {
        headerName: "มีข้อมูลในฐานข้อมูลแล้ว",
        width: width,
        field: "verified_label",
      },
      { headerName: "คำนำหน้า", width: width * 0.7, field: "prename" },
      { headerName: "ชื่อ", width: width * 0.7, field: "first_name" },
      { headerName: "สกุล", width: width, field: "last_name" },
      { headerName: "E-mail", width: width * 2, field: "email" },
      { headerName: "Username", width: width, field: "username" },
      { headerName: "เบอร์โทรศัพท์", width: width, field: "phone_no" },
      { headerName: "ตำแหน่งงาน", width: width, field: "position" },
      { headerName: "ทะเบียนรถ", width: width, field: "license_plate" },
      { headerName: "ประเภทรถ", width: width, field: "car_type" },
      { headerName: "เส้นทาง", width: width, field: "route" },
    ];

    if (!props.extendEmployeeTable) {
      column.shift();
      column.shift();
    }
    return column;
  }, [props.employees_upload, props.extendEmployeeTable]);

  console.log("props.employees_upload", props.employees_upload);
  return (
    <Modal
      open={props.modalOpen}
      closeIcon={false}
      closeOnEscape={true}
      closeOnDimmerClick={true}
      onClose={props.onClose}
      // onClose={() =>  props.setModalOpen(false)}
      size="fullscreen"
      style={{ padding: "20px 50px" }}
    >
      <h1>
        รายละเอียดข้อมูลพนักงาน&nbsp;&nbsp;
        {props.uploadFinish && (
          <span style={{ color: "green", fontSize: "18px" }}>
            &gt;&gt;การอัพโหลดเสร็จสิ้น กรุณาตรวจสอบรายการและปิดหน้าต่าง
          </span>
        )}
      </h1>
      <div
        className="employee-modal ag-theme-alpine"
        style={{ height: "500px", width: "100%" }}
      >
        <AgGridReact
          gridOptions={{
            rowClassRules: {
              // row style function
              "upload-fail": function (params) {
                var uploadFail = params.data.upload_result;
                return uploadFail === "ไม่สำเร็จ";
              },
              // row style expression
            },
            defaultColDef: {
              resizable: true,
            },
          }}
          columnDefs={employeeTableColumnDef}
          rowData={props.employees_upload}
        ></AgGridReact>
      </div>
      <br />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button color="red" onClick={props.onClose}>
          ยกเลิก
        </Button>
        <Button
          color="green"
          onClick={() => {
            props.onEvent({
              message: "clickButton",
              params: {
                name: "uploadEmployee",
              },
            });
          }}
        >
          ยืนยัน
        </Button>
      </div>
    </Modal>
  );
};

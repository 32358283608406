import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Modal,
  Dropdown,
  TextArea,
  Button,
  Dimmer,
  Loader,
  Form,
  Icon,
} from "semantic-ui-react";
import { createTrue } from "typescript";
import { InvoiceUpdateAll } from "../../react-lib/apis/issara/apps/TRK/INVInvoice";
import ErrorMessage from "../../react-lib/apps/common/ErrorMessage";
import "./ModUpdateInvoice.css";

const ModUpdateAllInvoice = (props: any) => {
  const [action, setAction] = useState<string>("INFO");
  const [error, setError] = useState<any>("");
  const [documentSeq, setDocumentSeq] = useState<number>(1);
  const [documentPage, setDocumentPage] = useState<number>(1);
  const [remark, setRemark] = useState<string>("");
  const [isConfirmed, setIsConfirmed] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [invoiceStatus, setInvoiceStatus] = useState<string>("SENDGOODS");
  const [pictureBase64, setPictureBase64] = useState<any>(null);

  const fileRef = useRef<any>(null);

  const documentTypeMap: { [id: number]: number } = useMemo(() => {
    return {
      8: 6,
      9: 3,
      10: 3,
      11: 1,
      12: 2,
      13: 4,
      14: 5,
      15: 7,
      16: 8,
      17: 9,
    };
  }, []);

  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  // const invoiceDocumentOptions = useMemo(() => {
  //   return [
  //     { key: 1, value: 1, text: " เอกสาร invoice ชุดที่ (1)  " },
  //     { key: 6, value: 6, text: " เอกสาร invoice ชุดที่ (6)  " },
  //     { key: 7, value: 7, text: " เอกสาร invoice ชุดที่ (7)  " },
  //     { key: 8, value: 8, text: " เอกสารการชำระเงิน  " },
  //     { key: 9, value: 9, text: " ใบวางบิล กรณีได้รับการชำระเงิน  " },
  //     { key: 10, value: 10, text: " ใบวางบิล กรณีไม่ได้รับการชำระเงิน  " },
  //     { key: 11, value: 11, text: " ใบสั่งซื้อ  " },
  //     { key: 12, value: 12, text: " ใบรับสินค้า  " },
  //     { key: 13, value: 13, text: " ใบขนส่ง  " },
  //     { key: 14, value: 14, text: " ใบนำส่งสินค้า  " },
  //     { key: 15, value: 15, text: " อื่ืนๆ(ระบุใน Note)  " },
  //     { key: 16, value: 16, text: " ใบลดหนี้ (Credit Note) " },
  //     { key: 17, value: 17, text: " ใบเพิ่มหนี้ (Debit Note) " },
  //   ];
  // }, []);
  const invoiceStatusToOptions = useMemo(() => {
    return [
      { key: "SENDGOODS", value: "SENDGOODS", text: " ส่งสินค้าแล้ว  " },
    ]
  }, [])

  const invoiceDocumentPageOptions = useMemo(() => {
    let pagesOptions = [];
    for (var i = 1; i < 6; i++) {
      pagesOptions.push({ key: i, value: i, text: i });
    }
    return pagesOptions;
  }, []);

  const invoiceDocumentUploadOptions = useMemo(() => {
    return [
      { key: 8, value: 8, text: " เอกสารการชำระเงิน  " },
      { key: 10, value: 10, text: " ใบวางบิล  " },
      { key: 11, value: 11, text: " ใบสั่งซื้อ  " },
      { key: 12, value: 12, text: " ใบรับสินค้า  " },
      { key: 13, value: 13, text: " ใบขนส่ง  " },
      { key: 14, value: 14, text: " ใบนำส่งสินค้า  " },
      { key: 15, value: 15, text: " อื่นๆ(ระบุใน Note)  " },
      { key: 16, value: 16, text: " ใบลดหนี้ (Credit Note) " },
      { key: 17, value: 17, text: " ใบเพิ่มหนี้ (Debit Note) " },
    ];
  }, []);

  const invoiceDocumentInfoOptions = useMemo(() => {
    return [
      { key: 1, value: 1, text: " เอกสาร Invoice ชุดที่ (1)  " },
      { key: 6, value: 6, text: " เอกสาร Invoice ชุดที่ (6)  " },
      { key: 7, value: 7, text: " เอกสาร Invoice ชุดที่ (7)  " },
      { key: 8, value: 8, text: " เอกสารการชำระเงิน  " },
      { key: 10, value: 10, text: " ใบวางบิล  " },
      { key: 11, value: 11, text: " ใบสั่งซื้อ  " },
      { key: 12, value: 12, text: " ใบรับสินค้า  " },
      { key: 13, value: 13, text: " ใบขนส่ง  " },
      { key: 14, value: 14, text: " ใบนำส่งสินค้า  " },
      { key: 15, value: 15, text: " อื่นๆ(ระบุใน Note)  " },
      { key: 16, value: 16, text: " ใบลดหนี้ (Credit Note) " },
      { key: 17, value: 17, text: " ใบเพิ่มหนี้ (Debit Note) " },
    ];
  }, []);

  const invoiceActionValidOptions = useMemo(() => {
    return [
      { key: "UPLOAD", value: "UPLOAD", text: "นำเข้าเอกสาร" },
      { key: "INFO", value: "INFO", text: "ตรวจรับเอกสาร (ไม่ต้องแนบรูป)" },
      { key: "UPDATE", value: "UPDATE", text: "อัพเดตสถานะ" },
    ];
  }, []);

  const validateData = () => {
    let validateComplete = true;
    if (action === "INFO"){
       if(!documentSeq){
         validateComplete = false;
         alert("กรุณาระบุ ชุดเอกสาร Invoice");
       }
      }
    if (action === "UPLOAD"){
      if(!documentSeq){
        validateComplete = false;
        alert("กรุณาเลือกชนิดเอกสาร Invoice");
      }else if(!documentTypeMap[documentSeq]){
        validateComplete = false;
         alert("กรุณาเลือกชนิดเอกสาร Invoice ที่มีอยู่");
      }else if(!documentPage){
        validateComplete = false;
         alert("กรุณาระบุ เลขหน้าของเอกสาร");
      }else if(!pictureBase64){
        validateComplete = false;
         alert("กรุณาเลือกรูปภาพที่จะอัพโหลด");
      }
    }
    if (action === "UPDATE"){
      if(!invoiceStatus){
        validateComplete = false;
         alert("กรุณาเลือกสถานะต่อไปของเอกสาร");
      }
    }


    return validateComplete
  }

  const handleSave = async () => {
    setIsLoading(true);
    let data: any = {};
    let checkValidate = validateData();
    if (!checkValidate){
      setIsLoading(false);
      return;
    }

    if(action === "INFO"){
      if(documentSeq < 8){
        data['doc_seq'] = documentSeq;
        data['doc_type'] = 0;
      }else{
        data['doc_seq'] = 99;
        data['doc_type'] = documentTypeMap[documentSeq];
      }
      
    }
    if(action === "UPLOAD"){
      data['doc_seq'] = 99;
      data['doc_type'] = documentTypeMap[documentSeq];
      data['doc_page'] = documentPage;
      data['doc_image'] = pictureBase64;
    }
    if(action === "UPDATE"){
      data['to_status'] = invoiceStatus;
    }

    if(action === "UPDATE" && (!remark || remark === "")){
      setIsLoading(false);
      setError("กรุณาระบุหมายเหตุของการอัพเดทสถานะของ Invoice")
      return;
    }

    const [r, e, n] = await InvoiceUpdateAll.patch({
      apiToken: props.apiToken,
      data: {
        ...data,
        invoice_list: props.invoice_list,
        request_type: action,
        note: remark,
      },
    });
    if (e || n) {
      setIsLoading(false);
      setError(e);
    }
    if (r) {
      setIsLoading(false);
      setIsConfirmed(false);
    }
  };

  return (
    <Modal
      className="mod-update-invoice"
      size="tiny"
      open={props.open}
      closeOnDimmerClick={false}
      onClose={props.onClose}
    >
      <Dimmer.Dimmable>
        <Dimmer active={isLoading} inverted>
          <Loader inverted />
        </Dimmer>
        <Dimmer active={!isConfirmed} inverted>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span
              style={{
                color: "#696969",
                fontSize: "20px",
                fontWeight: "bold",
                paddingBottom: "30px",
              }}
            >
              บันทึกเสร็จสิ้น
            </span>
            <div style={{ paddingBottom: "30px", fontSize: "50px" }}>
              <Icon name="check circle" color="green" />
            </div>
            <Button
              color="green"
              onClick={() => {
                setIsConfirmed(true);
                setError("");
                setRemark("");
                setDocumentSeq(1);
              }}
            >
              ตกลง
            </Button>
          </div>
        </Dimmer>
        <div>
          <h2>กรุณาระบุรายละเอียดเอกสาร</h2>
          <ErrorMessage error={error} />
          <div className="row">
            <div className="key-field" style={{ alignSelf: "center" }}>
              Action<span style={{ color: "red" }}>*</span>:
            </div>
            <div className="input-field">
              <Dropdown
                style={{ width: "100%" }}
                search
                selection
                value={action}
                options={invoiceActionValidOptions}
                onChange={(e, { value }) => {
                  setAction(value as string);
                  if (value === "INFO") {
                    setDocumentSeq(1);
                  } else if (value === "UPLOAD") {
                    setDocumentSeq(8);
                  }
                }}
              />
            </div>
          </div>
          {action === "INFO" && (
            <>
              <div className="row">
                <div className="key-field" style={{ alignSelf: "center" }}>
                  เอกสารชุดที่<span style={{ color: "red" }}>*</span>:
                </div>
                <div className="input-field">
                  <Dropdown
                    style={{ width: "100%" }}
                    value={documentSeq}
                    search
                    selection
                    options={invoiceDocumentInfoOptions}
                    onChange={(e, { value }) => {
                      setDocumentSeq(value as number);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="key-field">หมายเหตุ:</div>
                <div className="input-field">
                  <Form>
                    <TextArea
                      style={{ width: "100%", resize: "none" }}
                      rows={4}
                      value={remark}
                      onChange={(e, { value }) => {
                        setRemark(value as string);
                      }}
                    />
                  </Form>
                </div>
              </div>
            </>
          )}

          {action === "UPLOAD" && (
            <>
              <div className="row">
                <div className="key-field" style={{ alignSelf: "center" }}>
                  เอกสารชุดที่<span style={{ color: "red" }}>*</span>:
                </div>
                <div className="input-field">
                  <Dropdown
                    style={{ width: "100%" }}
                    value={documentSeq}
                    search
                    selection
                    options={invoiceDocumentUploadOptions}
                    onChange={(e, { value }) => {
                      setDocumentSeq(value as number);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="key-field" style={{ alignSelf: "center" }}>
                  หน้าที่<span style={{ color: "red" }}>*</span>:
                </div>
                <div className="input-field">
                  <Dropdown
                    style={{ width: "100%" }}
                    value={documentPage}
                    search
                    selection
                    options={invoiceDocumentPageOptions}
                    onChange={(e, { value }) => {
                      setDocumentPage(value as number);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="key-field">หมายเหตุ:</div>
                <div className="input-field">
                  <Form>
                    <TextArea
                      style={{ width: "100%", resize: "none" }}
                      rows={4}
                      value={remark}
                      onChange={(e, { value }) => {
                        setRemark(value as string);
                      }}
                    />
                  </Form>
                </div>
              </div>
              <div className="row">
                <div className="key-field" style={{ alignSelf: "center" }}>
                  รูปถ่าย/รูปสแกนเอกสาร :
                </div>
                <div className="input-field">
                  <input
                    type="file"
                    accept=".jpg,.png"
                    ref={fileRef}
                    onClick={(event: any) => {
                      console.log(" onClick");
                      setPictureBase64(null);
                    }}
                    onChange={async (event: any) => {
                      if (event.target.files && event.target.files.length > 0) {
                        console.log(" event.target.files", event.target.files);
                        const a = await toBase64(event.target.files[0]);
                        if (a) {
                          console.log("picture base64 image");
                          setPictureBase64(a);
                        } else {
                          console.log("picture base64 null");
                          setPictureBase64(null);
                        }
                      }
                    }}
                  />
                </div>
              </div>
            </>
          )}

          {action === "UPDATE" && (
            <>
              <div className="row">
                <div className="key-field" style={{ alignSelf: "center" }}>
                  สถานะ<span style={{ color: "red" }}>*</span>:
                </div>
                <div className="input-field">
                  <Dropdown
                    style={{ width: "100%" }}
                    value={invoiceStatus}
                    search
                    selection
                    options={invoiceStatusToOptions}
                    onChange={(e, { value }) => {
                      setInvoiceStatus(value as string);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="key-field">หมายเหตุ:</div>
                <div className="input-field">
                  <Form>
                    <TextArea
                      style={{ width: "100%", resize: "none" }}
                      rows={4}
                      value={remark}
                      onChange={(e, { value }) => {
                        setRemark(value as string);
                      }}
                    />
                  </Form>
                </div>
              </div>
            </>
          )}

          <div className="last row">
            <Button
              color="red"
              onClick={() => {
                setIsConfirmed(true);
                setError("");
                setRemark("");
                setDocumentSeq(1);
                setAction("INFO");
                props.onSuccess();
              }}
            >
              ยกเลิก
            </Button>
            <Button
              color="green"
              // disabled={!action || (showReason && !reason)}
              onClick={() => {
                handleSave();
              }}
            >
              บันทึก
            </Button>
          </div>
        </div>
      </Dimmer.Dimmable>
    </Modal>
  );
};

ModUpdateAllInvoice.defaultProps = {
  invoice_list: [],
  onClose: () => {},
  onSuccess: () => {},
  open: false,
};

export default React.memo(ModUpdateAllInvoice);

import React, { useState, useEffect } from "react";
import MainLayout from "./Sub/MainLayout";
import { STATUS_COLOR } from "../Common";
import { Icon, Modal } from "semantic-ui-react";
import SubDetail from "../Main/Sub/SubDetail";
import {
  InvoiceList,
  InvoiceDetail,
  InvoiceSerializer,
} from "../react-lib/apis/issara/apps/TRK/INVInvoice"

export default (props: any) => {
  const [sub, setSub] = useState("SubLogin");
  const [invoiceDetail, setInvoiceDetail] = useState({})
  const [openModal, setOpenModal] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  var notiData: any = {};

  const handleEvent = ({
    message,
    params
  }: {
    message: string;
    params: any;
  }) => {
    console.log(message);
    props.onEvent({ message, params });
  };

  for (const item of props.noti) {
    const [y, m, d] = item.date.split("T")[0].split("-");
    const date_str = `${d}/${m}/${y}`;
    if (!(date_str in notiData)) {
      notiData[date_str] = [];
    }
    notiData[date_str].push(item);
  }
  console.log(props, "props.noti");

  const handleSelectInvoice = async (invoiceId: any) => {
    setOpenModal(true)
    setIsModalLoading(true)
    const [r, e, n] = await InvoiceDetail.retrieve({
      apiToken: props.apiToken,
      pk: invoiceId,
    });
    setIsModalLoading(false)
    if (e) {
      console.log(e);
      console.log(n);
      return;
    }
    console.log(r);
    setInvoiceDetail(r)
  }

  useEffect(() => {
    if(!openModal){
      setInvoiceDetail({})
    }
  }, [openModal])

  return (
    <div style={{ height: "100%", overflow: "auto" }}>
      <MainLayout
        selectedCard="Noti"
        onEvent={handleEvent}
        login_employee={props.login_employee}
      >
        <Modal
          size="large"
          open={openModal}
          onClose={() => setOpenModal(false)}
        >
          <SubDetail invoiceDetail={invoiceDetail} loading={isModalLoading}/>
        </Modal>
        <div style={{ padding: "20px" }}>
          <h1>การแจ้งเตือน</h1>
          {Object.keys(notiData).map((date: string, date_index: number) => (
            <div key={date_index} style={{ lineHeight: "2em" }}>
              <h2>วันที่ {date}</h2>
              <hr />
              {notiData[date].map((item: any, item_index: number) => (
                <div
                  style={{
                    margin: "10px",
                    fontSize: "20px",
                    color: STATUS_COLOR[item.status],
                    cursor: "pointer"
                  }}
                  key={item_index}
                  onClick={() => handleSelectInvoice(item.invoice)}
                >
                  <Icon name="briefcase" />
                  &ensp;{item.message}
                </div>
              ))}
            </div>
          ))}
        </div>
      </MainLayout>
    </div>
  );
};

// this iframe component is created for using with react ref (30/09/2020)
// normal iframe ---> iframeRef.current.contentDocument = nullimport React, { useState } from 'react'
import { createPortal } from 'react-dom'
import React, { useState } from "react"

const IFrame = React.forwardRef(({
  children,
  ...props
}: any, ref) => {
  const [iframeRef, setIframeRef] = useState<any>(null);
  const mountNode = iframeRef?.contentWindow?.document?.body;

  React.useImperativeHandle(ref, () => ({
    iframeRef: iframeRef,
  }));

  return (
    <iframe {...props} ref={setIframeRef}>
      {mountNode && createPortal(children, mountNode)}
    </iframe>
  )
})

export default React.memo(IFrame)
import React from "react";
import { withCookies } from "react-cookie";
import { Button } from "semantic-ui-react";
import config from "../config/config"

const MPC = "/static/images/logo_mpc.png";
const ANB ="/static/images/logo_anb.png";

const CardSetPasswordSuccess = (props: any) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: "20px"
      }}
    >
    <div/>
      <div style={{ textAlign: "center" }}>
          <img style={{ height: "100px" }} src={props.cookies.get('hostName') === "ANB"? `${process.env.PUBLIC_URL +ANB}` : `${process.env.PUBLIC_URL +MPC}`} />
        </div>
      <div style={{ textAlign: "center" }}>
        <h1 style={{ color: "#00529C" }}>ระบบ Invoice Tracking</h1>
      </div>
      <div style={{ fontSize: "100px", color: "#00529C" }}>&#10003;</div>
      <div style={{ textAlign: "center" }}>
        <h3 style={{ color: "#00529C" }}>แก้ไขรหัสผ่านสำเร็จ</h3>
      </div>
      <Button
        size="big"
        style={{ backgroundColor: "#00529C", color: "white" }}
        onClick={props.goToLogin}
      >
        กลับสู่หน้าหลัก
      </Button>
      <div></div>
    </div>
  );
};

CardSetPasswordSuccess.defaultProps = {
  goToLogin: () => {}
}

export default withCookies(React.memo(CardSetPasswordSuccess));

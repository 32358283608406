import axios from 'axios'
import { to } from '../../../../utils'
import BaseService from  '../../../services/BaseService'
import invSetting from '../../../../../config/config';
import { Response, Error } from '../../common'

export interface InvoiceRelatedDocumentActionLogSerializer {
    id?: any,
    statable?: any,
    user?: any,
    action?: any,
    datetime?: any,
    from_status?: any,
    to_status?: any,
    note?: any,
    latitude?: any,
    longitude?: any,
    doc?: any,
    invoice?: any,
    edited_by?: any,
    code?: any,
    name?: any,
    from_status_label?: any,
    to_status_label?: any,
}


/* ['generics.ListAPIView'] */
/* params:  */
/* data:  */
export const DocActionLog : 
{
  list:     ({params, apiToken, extra}:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${invSetting.API_HOST}/apis/TRK/doc-action-log/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}
/*----------------------------------------------------------------------------------------------------*/


import React from "react";
import { Cookies, withCookies } from "react-cookie";
import CardLogin from "./CardLogin";
// import CardInvoice from "./CardInvoice";
import CardInvoiceList from "./CardInvoiceList";
import CardShipping from "./CardShipping";
import CardAnalytics from "./CardAnalytics";
import CardEmployee from "./CardEmployee";
import CardForgetPassword from "./CardForgetPassword";
import CardSetPasswordSuccess from "./CardSetPasswordSuccess";
import CardNoti from "./CardNoti";
import { LoginEmployee } from "../react-lib/apis/issara/apps/TRK/INVEmployeeExtra";
import { InvNotificationList } from "../react-lib/apis/issara/apps/TRK/INVInvNotification";
import { AuthenProvider } from "./MainContext";
import { Modal, Button } from "semantic-ui-react";
import "../Main/Sub/VerifyEmployee.css";
import "../Main/Sub/SubSummaryTable.css";
import "./CardEmployee.css";
import "./InvoiceSummary.css";
import "./CardForgetPassword.css";
import "semantic-ui-css/semantic.min.css";
import "../react-lib/css/fixedSemantic.css";
import { SignOut } from "../react-lib/apis/issara/apps/TRK/INVcommon";
import config from "../config/config";
import {
  askUserPermission,
  getRegisterServiceWorker
} from "../react-lib/apis/issara/apps/TRK/INVmanual";
import * as ServiceWorker from "../serviceWorker";
import Debug from "./Debug";

class MainInv extends React.Component<
  {
    cookies: Cookies;
  },
  {
    card: string;
    apiToken: string | null;
    viewData: any;
    login_employee: any;
    noti: any[];
    openNoPermission: boolean;
    hostName: string | null;
  }
> {

  guard = true
  constructor(props: any) {
    super(props);
    this.state = {
      hostName: props.cookies.get("hostName"),
      card: "CardLogin",
      apiToken: null,
      viewData: {},
      login_employee: {
        first_name: "",
        last_name: ""
      },
      noti: [],
      openNoPermission: false
    };

  }

  componentDidMount() {

    const apiToken = this.getApiToken();
    // console.log(apiToken);
    if (apiToken) {
      this.setState({
        apiToken: apiToken,
        card: "CardInvoiceList"
      });
      console.log(" CDM ")
      this.getLoginEmployee();
      this.getInvNotification();
    }
    askUserPermission().then(consent => {
      if (consent !== "granted") {
        console.error({
          name: "Consent denied",
          message: "You denied the consent to receive notifications",
          code: 0
        });
        window.alert("กรุณาอนุญาตให้ใช้งาน Notification เพื่อให้สามารถรับการแจ้งเตือนได้")
      }
    });
    getRegisterServiceWorker();
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    const apiToken = this.getApiToken();
    if(prevState.hostName !== this.state.hostName){
      config.changeDomain(this.state.hostName)
    }

    if (prevState.apiToken !== apiToken && apiToken && this.guard) {
      this.guard = false
      var success = await this.getLoginEmployee();
      this.guard = true
      // console.log(" CDU success", success)
      if (success) {
        this.getInvNotification();
        this.setState({
          apiToken: apiToken,
          card: "CardInvoiceList"
        });
      } else {
        
        this.logout()
        this.setState({openNoPermission: true})
      }
    }
  }

  handleEvent = ({ message, params }: { message: string; params: any }) => {
    console.log(message);
    if (message === "setApiToken" && params.value) {
      this.setApiToken(params.value);
    } else if (message === "login" && params.value) {
      this.setApiToken(params.value);
    } else if (message === "clickMenu" && params.name === "Analytics") {
      this.setState({ card: "CardAnalytics" });
    } else if (message === "clickMenu" && params.name === "Noti") {
      this.setState({ card: "CardNoti" });
    } else if (message === "clickMenu" && params.name === "InvoiceList") {
      this.setState({ card: "CardInvoiceList" });
    } else if (message === "clickMenu" && params.name === "Shipping") {
      this.setState({ card: "CardShipping" });
    } else if (message === "clickMenu" && params.name === "Employee") {
      this.setState({ card: "CardEmployee" });
    } else if (message === "clickMenu" && params.name == "Debug") {
      this.setState({ card: "Debug" });
    } else if (message === "clickMenu" && params.name == "Logout") {
      console.log("this.logout()");
      this.logout();
    }
  };

  getApiToken = () => {
    return this.props.cookies.get("apiToken");
  };

  setApiToken = (apiToken: string) => {
    this.props.cookies.set("apiToken", apiToken);
    // console.log( 'check ' , this.props.cookies.get("apiToken") )
    this.setState({ apiToken: apiToken });
    // console.log('setApiToken', apiToken)
  };

  getLoginEmployee = async () => {
    // console.log('getLoginEmployee :: ' ,  this.getApiToken())
    const [response, error, network] = await LoginEmployee.list({
      params: {},
      apiToken: this.getApiToken()
    });
    if (error) {
      console.log(error);
      console.log(network);
      return false;
    }
    console.log(response);
    if (response.items.length > 0) {
      // Check position_code       
      // let allow = ["manager", "officetran", "mana_sale", "mana_bill", "acc_fin", "admin", "mana_trans"]
      if (!response.items[0].website_allow){
        // Not Allown to login
        return false
      }

      this.setState({
        login_employee: {
          first_name: response.items[0].first_name,
          last_name: response.items[0].last_name,
          position: response.items[0].position
        }
      });
      return true
    }
    return false
  };

  getInvNotification = async () => {
    const [response, error, network] = await InvNotificationList.list({
      params: {},
      apiToken: this.getApiToken()
    });
    if (error) {
      console.log(error);
      console.log(network);
      return;
    }
    console.log(response);
    this.setState({
      noti: response.items
    });
  };

  logout = () => {
    SignOut.get({ apiToken: this.state.apiToken });
    this.setApiToken("");
    this.setState({
      apiToken: "",
      card: "CardLogin"
    });
    ServiceWorker.unregister();
  };

  handleForgetPassword = () => {
    this.setState({ card: "ForgetPassword" });
  };

  handleSetPasswordSuccess = () => {
    this.setState({ card: "SetPasswordSuccess" });
  };

  handleGoToLogin = () => {
    this.setState({ card: "CardLogin" });
  };

  render() {
    const apiToken = this.getApiToken();
    return (
      <AuthenProvider value={{ apiToken: this.state.apiToken }}>
        <div style={{ height: "100%" }}>
          {this.state.openNoPermission && (
            <Modal size="small" open={this.state.openNoPermission} onClose={() => this.setState({openNoPermission: false})} >
            <Modal.Content> 
              <p style={{fontWeight:"bolder", color:"#00529C", textAlign: "center"}}> 
              ขออภัย Username ของท่าน ไม่สามารถเข้าใช้งานเว็บ Invoice Tracking ได้ เนื่องจากไม่สิทธิในการเข้าใช้งาน
              </p> 
              <p style={{color:"#4C4C4C", textAlign: "center"}}> 
                กรุณาติดต่อหัวหน้าแผนกหรือฝ่ายสารสนเทศ (IT) เพื่อดำเนินการต่อ 
              </p>
         
 
            </Modal.Content>
       
            <Modal.Actions>
              <Button color='blue' onClick={() => this.setState({openNoPermission: false})}>
                OK
              </Button>
            </Modal.Actions>
          </Modal>
          )}
          {this.state.card === "CardLogin" ? (
            <CardLogin
              {...this.state}
              onEvent={this.handleEvent}
              onForgetPassword={this.handleForgetPassword}
              onHostnameChange={(hostName: string) => this.setState({ hostName })}
            />
          ) : this.state.card === "CardNoti" ? (
            <>
              <CardNoti
                {...this.state}
                login_employee={this.state.login_employee}
                onEvent={this.handleEvent}
                apiToken={apiToken}
              />
            </>
          ) : this.state.card === "CardInvoiceList" ? (
            <CardInvoiceList
              login_employee={this.state.login_employee}
              onEvent={this.handleEvent}
              apiToken={apiToken}
            />
          ) : this.state.card === "CardShipping" ? (
            <CardShipping
              {...this.state}
              login_employee={this.state.login_employee}
              onEvent={this.handleEvent}
              getApiToken={this.getApiToken}
            />
          ) : this.state.card === "CardAnalytics" ? (
            <CardAnalytics
              {...this.state}
              login_employee={this.state.login_employee}
              onEvent={this.handleEvent}
              getApiToken={this.getApiToken}
            />
          ) : this.state.card === "CardEmployee" ? (
            <CardEmployee
              {...this.state}
              login_employee={this.state.login_employee}
              apiToken={apiToken}
              onEvent={this.handleEvent}
            />
          ) : this.state.card === "Debug" ? (
            <Debug
              {...this.state}
              login_employee={this.state.login_employee}
              apiToken={apiToken}
              onEvent={this.handleEvent}
            />
          ) : this.state.card === "ForgetPassword" ? (
            <CardForgetPassword
              {...this.state}
              onSetPasswordSuccess={this.handleSetPasswordSuccess}
            />
          ) : this.state.card === "SetPasswordSuccess" ? (
            <CardSetPasswordSuccess
              {...this.state}
              goToLogin={this.handleGoToLogin}
            />
          ) : (
            <></>
          )}
        </div>
      </AuthenProvider>
    );
  }
}

export default withCookies(MainInv);

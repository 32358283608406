import React from "react";
import { Step, Dimmer, Loader } from "semantic-ui-react";

const DOCNAME: any = {
  "1": "ต้นฉบับใบเสร็จรับเงิน",
  "2": "สำเนาใบกำกับภาษี/ใบส่งสินค้า/ใบแจ้งหนี้",
  "3": "ต้นฉบับใบแจ้งหนี้",
  "4": "สำเนาใบกำกับภาษี/ใบส่งสินค้า/ใบแจ้งหนี้",
  "5": "ต้นฉบับใบกำกับภาษี/ใบส่งสินค้า",
  "6": "สำเนาใบกำกับภาษี/ใบส่งสินค้า/ใบแจ้งหนี้",
  "7": "สำเนาใบเสร็จรับเงิน"
};

const DetailField = (props: any) => (
  <div style={{ display: "flex", width: "100%", margin: "10px" }}>
    <DetailName>
      <h3 style={{ margin: 0 }}>{props.name}</h3>
    </DetailName>
    <DetailValue>{props.value}</DetailValue>
  </div>
);

const DetailName = (props: any) => (
  <div style={{ flex: 1 }}>{props.children}</div>
);

const DetailValue = (props: any) => (
  <div style={{ flex: 3, alignSelf: "center" }}>{props.children}</div>
);

const SubDetail = (props: any) => (
  <div style={{ margin: "20px", width: "100%", display: "flex" }}>
      <Dimmer inverted active={props.loading}>
        <Loader inverted/>
      </Dimmer>
      <div
        style={{
          flex: 4,
          display: "flex",
          width: "100%",
          flexDirection: "column"
        }}
      >
        <h2 style={{ color: "#4183C4" }}>Detail Invoice</h2>
        <DetailField name="Invoice No." value={props.invoiceDetail.serial} />
        <DetailField
          name="Customer No."
          value={props.invoiceDetail.customer_code}
        />
        <DetailField
          name="Customer Name"
          value={props.invoiceDetail.customer_name}
        />
        <DetailField
          name="Type"
          value={props.invoiceDetail.billing_type_label}
        />
        {/* TODO Billing Date, Due Date*/}
        <DetailField name="Billing Date" value={props.invoiceDetail.date} />
        <DetailField name="Due Date" value={props.invoiceDetail.date} />
        <DetailField name="Total (Baht)" value={props.invoiceDetail.amount} />
        <DetailField
          name="Shipper"
          value={props.invoiceDetail.transport_name}
        />
        <DetailField name="Sales" value={props.invoiceDetail.sale_name} />
        <DetailField name="หมายเหตุ" value={props.invoiceDetail.remark} />
      </div>
      <div style={{ flex: 3, borderLeft: "solid grey 2px" }}>
        {/* <div style={{ marginLeft: "20px", marginBottom: "30px", width: "100%" }}>
        <h3>Status</h3>
        <span>&gt; {props.invoiceDetail.status_label}</span>
      </div> */}
        <div style={{ marginRight: "40px", textAlign: "right" }}>
          <Step.Group>
            <Step style={{ padding: "1em" }}>Status</Step>
            <Step
              style={{
                padding: "1em",
                color: "white",
                fontWeight: "bold",
                backgroundColor: "#185070"
              }}
              active
            >
              {props.invoiceDetail.status_label}
            </Step>
          </Step.Group>
        </div>
        {props.invoiceDetail.related_documents
          ? props.invoiceDetail.related_documents.map(
              (doc: any, doc_index: number) => (
                <div
                  key={doc_index}
                  style={{ fontSize: "15px", margin: "20px", display: "flex" }}
                >
                  <h3 style={{ marginRight: "10px" }}>{doc.seq}.</h3>
                  <div>
                    <h3>{DOCNAME[doc.seq]}</h3>
                    <div>{doc.status_label}</div>
                  </div>
                </div>
              )
            )
          : null}
      </div>
  </div>
);

SubDetail.defaultProps = {
  loading: false
}

export default React.memo(SubDetail);

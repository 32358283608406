import React from "react";
import MainLayout from "./MainLayout";
import { Select, Button } from "semantic-ui-react";

const options = [
  { key: 0, text: "สถิติขนส่งประจำวันภายใน", value: "ShippingInternal" },
  { key: 1, text: "สถิติขนส่งประจำวันภายนอก", value: "ShippingExternal" },
  { key: 2, text: "รายงานขนส่งรายเดือน", value: "ShippingMonthly" },
  { key: 3, text: "รายงาน Billing", value: "Billing" },
  { key: 4, text: "รายงานแก้ไขเอกสาร Manual", value: "ManualEdit" },
  { key: 5, text: "หน้าสรุป Invoice", value: "InvoiceSummary" },
];

const AnalyticsLayout = (props: any) => {
  return (
    <MainLayout onEvent={props.onEvent} login_employee={props.login_employee} selectedCard="Analytics">
      <div>
        <div
          style={{
            width: "100%",
            height: "50px",
            backgroundColor: "#cccccc",
            display: "flex",
          }}
        >
          <div style={{ padding: "0px 20px", margin: "auto" }}>เลือก Report:</div>
          <Select
            style={{ margin: "5px", width: "300px" }}
            value={props.sub}
            options={options}
            onChange={(e: any, v: any) => {
              console.log(v.value);
              props.onEvent({
                message: "choose",
                params: {
                  name: "report",
                  value: v.value,
                },
              });
            }}
          />
          <div style={{ flex: 1 }}></div>
          <Button
            loading={props.exportExcelLoading}
            disabled={props.exportExcelLoading}
            style={{ margin: "5px", backgroundColor: "#79C000", color: "white" }}
            onClick={(e: any) => {
              if(props.onExportToExcel){
                props.onExportToExcel()
              } else {
                props.onEvent({
                  message: "clickButton",
                  params: {
                    name: "export",
                  },
                });
              }
            }}
          >
            Export to Excel
          </Button>
          <Button
            loading={props.exportAllLoading}
            disabled={props.exportAllLoading}
            color="green"
            style={{ margin: "5px" }}
            onClick={props.onExportAll}
          >
            Export ทั้งหมด
          </Button>
        </div>
        <div>{props.children}</div>
      </div>
    </MainLayout>
  );
};

AnalyticsLayout.defaultProps = {
  onEvent: () => {},
  onExportAll: () => {},
  exportExcelLoading: false,
  exportAllLoading: false,
}

export default React.memo(AnalyticsLayout);
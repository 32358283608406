import React, { useState, useEffect } from "react";
import { Button, Dimmer, Loader, Modal, TextArea } from "semantic-ui-react";
import { InvoiceBillNote } from "../../react-lib/apis/issara/apps/TRK/INVInvoice";
import "./ModBillingNote.css";

const ModBillingNote = (props: any) => {
  const [billingNoteList, setBillingNoteList] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [newNote, setNewNote] = useState<string>("");

  useEffect(() => {
    if (props.invoiceId && props.open) {
      getBillingNote();
    }
  }, [props.invoiceId, props.open]);

  const addBillingNote = async () => {
    setLoading(true);
    if (newNote === "" && typeof(newNote) !== "string") {
      return;
    }
    const [r, e, n] = await InvoiceBillNote.patch({
      apiToken: props.apiToken,
      data: {
        invoice: props.invoiceId,
        billing_note: newNote,
      },
    });
    if (e) {
      console.log(e);
      console.log(n);
      return;
    }
    console.log(r);
    getBillingNote();
  };

  const getBillingNote = async () => {
    setLoading(true)
    const [r, e, n] = await InvoiceBillNote.list({
      apiToken: props.apiToken,
      params: {
        invoice: props.invoiceId,
      },
    });
    if (e) {
      console.log(e);
      console.log(n);
      return;
    }else{
        console.log(r);
        setLoading(false)
        setBillingNoteList(r.items);
    }
  };

  const createTableBillingNote = () => {
    if (billingNoteList && billingNoteList.length !== 0) {
      return billingNoteList.map((item: any, index: number) => {
        return (
          <tr>
            <td>{item.billing_note}</td>
            <td>{item.user_full_name}</td>
            <td>{item.datetime}</td>
          </tr>
        );
      });
    }
    return;
  };

  return (
    <Modal
      className="mod-billing-note"
      open={props.open}
      closeIcon
      onClose={() => props.onClose()}
    >
      <Dimmer.Dimmable>
        <Dimmer active={loading} inverted>
          <Loader inverted />
        </Dimmer>
        <div className="row">
          <h3>หมายเหตุ :</h3>
        </div>
        <div className="row">
          <div className="item">
            <TextArea
              className="text-area"
              value={newNote}
              onChange={(e:any) => {
                setNewNote(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="item-end">
            <Button
              content="บันทึก"
              color="green"
              onClick={() => {
                addBillingNote();
              }}
            />
          </div>
        </div>
        <div className="row">
          <h3>ประวัติการแก้หมายเหตุ :</h3>
        </div>
        <div className="table-wraper">
          <table>
            <thead>
              <tr>
                <th>หมายเหตุ</th>
                <th>ผู้แก้ไข</th>
                <th>วันเวลาที่แก้ไข</th>
              </tr>
            </thead>
            <tbody>{createTableBillingNote()}</tbody>
          </table>
        </div>
      </Dimmer.Dimmable>
    </Modal>
  );
};

ModBillingNote.defaultProps = {
  open: false,
  invoiceId: 0,
  onClose: () => {},
};

export default React.memo(ModBillingNote);

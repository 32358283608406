import React, { createRef } from "react";
import SubShippingChart from "./Sub/SubShippingChart";
import SubSummaryTable from "./Sub/SubSummaryTable";
import {
  InvoiceList,
  InvoiceDetail,
  InvoiceSerializer,
} from "../react-lib/apis/issara/apps/TRK/INVInvoice";
import { DocActionLog } from "../react-lib/apis/issara/apps/TRK/INVInvoiceRelatedDocumentActionLog";
import { SummaryShipping } from "../react-lib/apis/issara/apps/TRK/INVmanual";
import { exportExcel } from "./Sub/Excel";
import dateFnsFormat from "date-fns/format";
import InvoiceSummary from "./Sub/InvoiceSummary";
import { Step } from "semantic-ui-react";
import { ExportExcel } from "../react-lib/apis/issara/apps/TRK/INVcommon";
import { downloadFile } from "../react-lib/utils";
import SubDetail from "../Main/Sub/SubDetail";
import ModInvoiceActionLogList from "./Sub/ModInvoiceActionLogList";
import ModBillingNote from "./Sub/ModBillingNote";
import ModProgressReport from "./Sub/ModProgressReport";
import { timeStamp } from "console";

const FORMAT = "dd/MM/yyyy";
const YMFORMAT = "yyyy-MM";
const page_size = 10;
const d = new Date();
const currentYm =
  d.getFullYear().toString() +
  "-" +
  (d.getMonth() + 1).toString().padStart(2, "0");

export default class Card extends React.Component<
  {
    onEvent: ({ message, params }: { message: string; params: any }) => void;
    getApiToken: () => string;
    login_employee: any;
  },
  {
    sub: string;
    filterParams: any;
    viewData: any[];
    numPage: number;
    openModal: boolean;
    openProgressModal: boolean;
    taskId: number,
    openBillingNoteModal: boolean;
    selectedInvoice: number;
    billingInvoice: number;
    invoiceDetail: InvoiceSerializer;
    getDataLoading: boolean;
    exportAllLoading: boolean;
    exportExcelLoading: boolean;
    searchFilterParams: any;
  }
> {
  downloadRef = createRef<HTMLTableElement>();

  constructor(props: any) {
    super(props);
    const sub = "ShippingInternal";
    this.state = {
      sub: sub,
      filterParams: META[sub].initialFilterParams,
      viewData: [],
      numPage: 1,
      openModal: false,
      openProgressModal: false,
      taskId: 0,
      openBillingNoteModal: false,
      selectedInvoice: 0,
      billingInvoice: 0,
      invoiceDetail: initialInvoiceDetail,
      getDataLoading: false,
      exportAllLoading: false,
      exportExcelLoading: false,
      searchFilterParams: META[sub].initialFilterParams,
    };
  }

  componentDidMount = async () => {
    this.getData(this.state.sub, META[this.state.sub].initialFilterParams);
  };

  handleEvent = async ({ message, params }: { message: string; params: any }) => {
    console.log(message, params);
    if (message === "clickMenu") {
      this.props.onEvent({ message, params });
    } else if (message === "choose" && params.name === "report") {
      // Select Report query data base on init fileter params
      this.getData(params.value, META[params.value].initialFilterParams);
    } else if (message === "choose" && params.name === "fromDate") {
      // For SubShippingChart 

      this.setState({filterParams: { ...this.state.filterParams, ...({start_date: params.value}) }})
      // this.getData(this.state.sub, {
      //   ...this.state.filterParams,
      //   start_date: params.value,
      // });
    } else if (message === "choose" && params.name === "toDate") {
      this.setState({filterParams: { ...this.state.filterParams, ...({end_date: params.value}) }})
       // For SubShippingChart 
      // this.getData(this.state.sub, {
      //   ...this.state.filterParams,
      //   end_date: params.value,
      // });
    } else if (message  === "clickButton" && params.name === "search") {

      this.getData(this.state.sub, {...this.state.filterParams}) 

    } else if (message === "filterChange") {
      console.log("filterChange");
      this.setState({ filterParams: { ...params } });
    } else if (message === "filterSubmit") {
      console.log("filterSubmit");
      this.getData(this.state.sub, params);
    } else if (message === "clickButton" && params.name === "export") {
      this.setState({ exportExcelLoading: true })
      let data = [...this.state.viewData]
      if (this.state.sub === "Billing"){
        data.map((item: any) => item.id = "")
      }
      // .filter((item: any) => item.name !== "สรุปของรอบก่อนหน้า")
      await exportExcel(
        META[this.state.sub].columns.filter((item: any) => item.name !== "Action").map((item: any) => item.name),
        data,
        this.downloadRef,
        this.state.sub +
          "_" +
          Object.values(this.state.filterParams)
            .filter((item) => (item ? true : false))
            .join("_")
      );
      this.setState({ exportExcelLoading: false })
    } else if (message === "detail" && params.name === "invoice") {
      console.log("get invoice detail", params.value);
      this.getDetail(params.value);
      this.setState({ openModal: true});
    } else if (message === "modalBillingNote" && params.name === "invoice"){
      console.log("get invoice billing note", params.value);
      this.setState({ openBillingNoteModal: true, billingInvoice: params.value});
    }
  };

  getData = async (sub: string, filterParams: any) => {
    if (["ShippingInternal", "ShippingExternal"].includes(sub)) {
      this.setState({ getDataLoading: true })
      const [response, error, network] = await SummaryShipping.get({
        apiToken: this.props.getApiToken(),
        params: {
          external: sub === "ShippingExternal",
          ...( filterParams.start_date && {start_date: filterParams.start_date}),
          ...( filterParams.end_date && {end_date: filterParams.end_date}),
        },
      });
      this.setState({ getDataLoading: false })
      if (error) {
        console.log(error);
        console.log(network);
        return;
      }
      console.log(response);
      this.setState({
        viewData: Object.values(response),
        sub: sub,
        filterParams: filterParams,
        searchFilterParams: filterParams
      });
    } else if (["ShippingMonthly", "Billing", "InvoiceSummary"].includes(sub)) {
      this.setState({ getDataLoading: true })
      const [r, e, n] = await InvoiceList.list({
        apiToken: this.props.getApiToken(),
        params: {
          ...filterParams,
          page_size: page_size,
          ...(sub.includes("Billing") && {report: true})
        },
      });
      this.setState({ getDataLoading: false })
      if (e) {
        console.log(e);
        console.log(n);
        return;
      }
      console.log(r);
      const data = r.results.map((item: any) =>
        META[sub].columns.reduce((obj: any, column: any) => {
          var related_keys = column.key.split('.')
          if (column.number) {
            obj[column.key] = Number(item[column.key]);
          } else if (typeof item[column.key] === "string") {
            obj[column.key] = item[column.key].trim();
          } else if(related_keys.length === 2){
            var related_item = item[related_keys[0]][related_keys[1]]
             if (related_item){
              obj[column.key] = related_item
             }else{
              obj[column.key] = ""
             }
          }else{
            obj[column.key] = item[column.key];
          }
          return obj;
          
        }, {})
      );
      console.log(data);
      console.log(r);
      this.setState({
        viewData: data,
        sub: sub,
        filterParams: filterParams,
        searchFilterParams: filterParams,
        numPage: r.count <= 0 ? 1 : Math.ceil(r.count / page_size),
      });
    } else if (["ManualEdit"].includes(sub)) {
      const [r, e, n] = await DocActionLog.list({
        apiToken: this.props.getApiToken(),
        params: {
          ...filterParams,
          page_size: page_size,
        },
      });
      if (e) {
        console.log(e);
        console.log(n);
        return;
      }
      console.log(r);
      const data = r.results.map((item: any) => ({
        ...item,
        doc_name: `ชุดที่ ${item.doc.seq}: ${DOCNAME[item.doc.seq]}`,
      }));
      this.setState({
        viewData: data,
        sub: sub,
        filterParams: filterParams,
        numPage: r.count <= 0 ? 1 : Math.ceil(r.count / page_size),
        searchFilterParams: filterParams,
      });
    }
  };

  getDetail = async (id: number) => {
    const [r, e, n] = await InvoiceDetail.retrieve({
      apiToken: this.props.getApiToken(),
      pk: id,
    });
    if (e) {
      console.log(e);
      console.log(n);
      return;
    }
    console.log(r);
    this.setState({ invoiceDetail: r });
  };

  handleCloseBillingModal = async () => {
    this.setState({openBillingNoteModal: false})
    this.getData(this.state.sub, {...this.state.filterParams}) 
  }

  handleExportAll = async ({ exportAll, typeCode } = { exportAll: false, typeCode: "" }) => {
    this.setState({ exportAllLoading: true })
    let pageSize = page_size
    if(exportAll){
      pageSize = 99999
    }
    const [res, err, network] = await ExportExcel.retrieve({
      apiToken: this.props.getApiToken(),
      params: {
        ...this.state.searchFilterParams,
        type_code: typeCode,
        page_size: pageSize,
      },
      // extra: {
      //   responseType: "blob"
      // }
    });
    this.setState({ exportAllLoading: false })
    if (res) {
      this.setState({
        taskId:res.task_id,
        openProgressModal: true
      })
      // downloadFile(network);
    } else {

    }
  }

  render() {
    const apiToken = this.props.getApiToken()
    // console.log('CardAnalytics this.state', this.state)
    console.log(this.state.filterParams);
    return (
      <div style={{ height: "100%" }}>
        <ModInvoiceActionLogList
          invoice={this.state.invoiceDetail}
          apiToken={apiToken}
          size="large"
          open={this.state.openModal}
          onClose={() => this.setState({ openModal: false })}
        />
        <ModBillingNote 
          invoiceId={this.state.billingInvoice}
          apiToken={apiToken}
          open={this.state.openBillingNoteModal}
          onClose={() => this.handleCloseBillingModal()}
        />
        <ModProgressReport 
          open={this.state.openProgressModal}
          taskId={this.state.taskId}
          apiToken={apiToken}
          onClose={() => this.setState({ 
            openProgressModal: false,
            taskId: 0
          })}
        />
        <table
          id="data-table"
          ref={this.downloadRef}
          style={{ display: "none" }}
        ></table>
        {this.state.sub === "ShippingInternal" ? (
          <SubShippingChart
            filters={this.state.filterParams}
            sub={this.state.sub}
            login_employee={this.props.login_employee}
            viewData={this.state.viewData}
            plotStyle={{ margin: 10, width: 1600, height: 700 }}
            onEvent={this.handleEvent}
            apiToken={apiToken}
            searchFilter={this.state.searchFilterParams}
          />
        ) : this.state.sub === "ShippingExternal" ? (
          <SubShippingChart
            filters={this.state.filterParams}
            sub={this.state.sub}
            login_employee={this.props.login_employee}
            viewData={this.state.viewData}
            plotStyle={{ margin: 10, width: 1600, height: 700 }}
            onEvent={this.handleEvent}
            apiToken={apiToken}
            searchFilter={this.state.searchFilterParams}
          />
        ) : this.state.sub === "ShippingMonthly" ? (
          <SubSummaryTable
            sub={this.state.sub}
            title="รายงานขนส่งรายเดือน"
            login_employee={this.props.login_employee}
            viewData={this.state.viewData}
            columns={META.ShippingMonthly.columns}
            filters={META.ShippingMonthly.filters}
            filterParams={this.state.filterParams}
            numPage={this.state.numPage}
            onEvent={this.handleEvent}
            onExportAll={() => this.handleExportAll({ exportAll: true, typeCode: "INVOICE_LIST" })}
            exportExcelLoading={this.state.exportExcelLoading}
          />
        ) : this.state.sub === "Billing" ? (
          <SubSummaryTable
            sub={this.state.sub}
            title="รายงาน Billing"
            login_employee={this.props.login_employee}
            viewData={this.state.viewData}
            columns={META.Billing.columns}
            filters={META.Billing.filters}
            filterParams={this.state.filterParams}
            numPage={this.state.numPage}
            onEvent={this.handleEvent}
            exportAllLoading={this.state.exportAllLoading}
            onExportAll={() => this.handleExportAll({ exportAll: true, typeCode: "BILLING" })}
            exportExcelLoading={this.state.exportExcelLoading}
          />
        ) : this.state.sub === "ManualEdit" ? (
          <SubSummaryTable
            sub={this.state.sub}
            title="รายงานแก้ไขเอกสาร Manual"
            login_employee={this.props.login_employee}
            viewData={this.state.viewData}
            columns={META.ManualEdit.columns}
            filters={META.ManualEdit.filters}
            filterParams={this.state.filterParams}
            numPage={this.state.numPage}
            onEvent={this.handleEvent}
            exportAllLoading={this.state.exportAllLoading}
            onExportAll={() => this.handleExportAll({ exportAll: true, typeCode: "MANUAL" })}
            exportExcelLoading={this.state.exportExcelLoading}
          />
        ) : this.state.sub === "InvoiceSummary" ? (
          <InvoiceSummary
            sub={this.state.sub}
            login_employee={this.props.login_employee}
            viewData={this.state.viewData}
            columns={META.InvoiceSummary.columns}
            numPage={this.state.numPage}
            filterParams={this.state.filterParams}
            onEvent={this.handleEvent}
            loading={this.state.getDataLoading}
            exportAllLoading={this.state.exportAllLoading}
            apiToken={this.props.getApiToken()}
            searchFilter={this.state.searchFilterParams}
            onExportAll={() => this.handleExportAll({ exportAll: true, typeCode: "INV_SUMMARY" })}
          />
          // <SubSummaryTable
          //   sub={this.state.sub}
          //   title="หน้าสรุป Invoice"
          //   login_employee={this.props.login_employee}
          //   viewData={this.state.viewData}
          //   columns={META.InvoiceSummary.columns}
          //   filters={META.InvoiceSummary.filters}
          //   filterParams={this.state.filterParams}
          //   numPage={this.state.numPage}
          //   onEvent={this.handleEvent}
          // />
        ) : (
          <></>
        )}
      </div>
    );
  }
}

// const SubDetail = (props: any) => (
//   <div style={{ margin: "20px", width: "100%", display: "flex" }}>
//     <div
//       style={{
//         flex: 4,
//         display: "flex",
//         width: "100%",
//         flexDirection: "column",
//       }}
//     >
//       <h2 style={{ color: "#4183C4" }}>Detail Invoice</h2>
//       <DetailField name="Invoice No." value={props.invoiceDetail.serial} />
//       <DetailField
//         name="Customer No."
//         value={props.invoiceDetail.customer_code}
//       />
//       <DetailField
//         name="Customer Name"
//         value={props.invoiceDetail.customer_name}
//       />
//       <DetailField name="Type" value={props.invoiceDetail.billing_type_label} />
//       {/* TODO Billing Date, Due Date*/}
//       <DetailField name="Billing Date" value={props.invoiceDetail.date} />
//       <DetailField name="Due Date" value={props.invoiceDetail.date} />
//       <DetailField name="Total (Baht)" value={props.invoiceDetail.amount} />
//       <DetailField name="Shipper" value={props.invoiceDetail.transport_name} />
//       <DetailField name="Sales" value={props.invoiceDetail.sale_name} />
//       <DetailField name="หมายเหตุ" value={props.invoiceDetail.remark} />
//     </div>
//     <div style={{ flex: 3, borderLeft: "solid grey 2px" }}>
//       {/* <div style={{ marginLeft: "20px", marginBottom: "30px", width: "100%" }}>
//         <h3>Status</h3>
//         <span>&gt; {props.invoiceDetail.status_label}</span>
//       </div> */}
//       <div style={{ marginRight: "40px", textAlign: "right" }}>
//         <Step.Group>
//           <Step style={{ padding: "1em" }}>Status</Step>
//           <Step style={{ padding: "1em", color: "white", fontWeight: "bold", backgroundColor: "#185070" }} active>{props.invoiceDetail.status_label}</Step>
//         </Step.Group>
//       </div>
//       {props.invoiceDetail.related_documents.map(
//         (doc: any, doc_index: number) => (
//           <div
//             key={doc_index}
//             style={{ fontSize: "15px", margin: "20px", display: "flex" }}
//           >
//             <h3 style={{ marginRight: "10px" }}>{doc.seq}.</h3>
//             <div>
//               <h3>{DOCNAME[doc.seq]}</h3>
//               <div>{doc.status_label}</div>
//             </div>
//           </div>
//         )
//       )}
//     </div>
//   </div>
// );

const DOCNAME: any = {
  "1": "ต้นฉบับใบเสร็จรับเงิน",
  "2": "สำเนาใบกำกับภาษี/ใบส่งสินค้า/ใบแจ้งหนี้",
  "3": "ต้นฉบับใบแจ้งหนี้",
  "4": "สำเนาใบกำกับภาษี/ใบส่งสินค้า/ใบแจ้งหนี้",
  "5": "ต้นฉบับใบกำกับภาษี/ใบส่งสินค้า",
  "6": "สำเนาใบกำกับภาษี/ใบส่งสินค้า/ใบแจ้งหนี้",
  "7": "สำเนาใบเสร็จรับเงิน",
};

// const DetailField = (props: any) => (
//   <div style={{ display: "flex", width: "100%", margin: "10px" }}>
//     <DetailName><h3 style={{ margin: 0 }}>{props.name}</h3></DetailName>
//     <DetailValue>{props.value}</DetailValue>
//   </div>
// );

// const DetailName = (props: any) => (
//   <div style={{ flex: 1 }}>{props.children}</div>
// );

// const DetailValue = (props: any) => (
//   <div style={{ flex: 3, alignSelf: "center" }}>{props.children}</div>
// );

const initialInvoiceDetail = {
  id: 0,
  edit_user: "",
  created: "",
  edited: "",
  status: "",
  serial: "",
  customer: "",
  date: "",
  delivery_address: "",
  amount: "",
  sale: 0,
  transport: 0,
  billing_type: "",
  box_count: 0,
  active: "",
  external: false,
  shipping_company: "",
  customer_name: "",
  customer_code: "",
  related_documents: [],
  sale_name: "",
  transport_name: "",
  status_label: "",
  action: 0,
  loss_reason: "",
  allowed_actions: [],
  billing_type_label: "",
};

const META: any = {

  ShippingInternal: {
    // For Export 
    columns: [
      { key: "serial", name: "Driver" },
      { key: "count", name: "Number of invoices" },
      { key: "sum_box_count", name: "Number of boxes" },
    ],
    // UI use internal
    // filters: [{ label: "Billing Date", type: "DayPickerInput", key: "start_date" },
    //           { label: null, type: "DayPickerInput", key: "to_date" },
    //           { buttonLabel: "ค้นหา", type: "Submit", key: "submit", color: "green" }],
    initialFilterParams: {
      start_date: dateFnsFormat(new Date(), FORMAT),
      end_date: dateFnsFormat(new Date(), FORMAT),
    },
  },

  ShippingExternal: {
    // For Export 
    columns: [
      { key: "serial", name: "Shipping Company" },
      { key: "count", name: "Number of invoices" },
      { key: "sum_box_count", name: "Number of boxes" },
    ],
    // UI use internal
    // filters: [{ label: "Billing Date", type: "DayPickerInput", key: "start_date" },
    //           { label: null, type: "DayPickerInput", key: "to_date" },
    //           { buttonLabel: "ค้นหา", type: "Submit", key: "submit", color: "green" }],
    initialFilterParams: {
      start_date: dateFnsFormat(new Date(), FORMAT),
      end_date: dateFnsFormat(new Date(), FORMAT),
    },
  },

  ShippingMonthly: {
    columns: [
      { key: "serial", name: "Invoice", number: false },
      { key: "date", name: "Billing Date", number: false },
      { key: "customer_name", name: "Customer", number: false },
      { key: "delivery_address", name: "Address", number: false },
      { key: "transport_name", name: "Shipper", number: false },
      { key: "box_count", name: "Boxes", number: false },
      { key: "cost", name: "Cost", number: true },
    ],
    filters: [
      { label: null, type: "MonthPickerSubmit", key: "ym" },
      { label: "Shipper", type: "Input", key: "shipper" },
      { buttonLabel: "Submit", type: "Submit", key: "submit", color: "green" },
      { label: null, type: "hidden", key: "page" },
    ],
    initialFilterParams: {
      ym: currentYm,
      transport_name: "",
      page: 1,
      sortby: "",
      ascending: true,
    },
  },

  Billing: {
    columns: [
      { key: "customer_code", name: "Customer No.", number: false },
      { key: "customer_name", name: "Customer", number: false },
      { key: "sale_name", name: "Current Salesperson", number: false },
      { key: "delivery_address", name: "Address", number: false },
      { key: "serial", name: "Invoice No.", number: false },
      { key: "assignment", name: "Assignment", number: false },
      { key: "po_number", name: "PO No.", number: false },
      { key: "date", name: "Billing Date", number: false },
      { key: "due_date", name: "Due Date", number: false },
      { key: "amount", name: "Amount", number: true },
      { key: "billing_type_label", name: "Type", number: false},
      { key: "report_document.sent_goods_documents", name: "ส่งสินค้าแล้ว", number: false},
      { key: "report_document.sent_goods_datetime", name: "วันเวลา", number: false},
      { key: "report_document.ready_collect_documents", name: "billing รับเอกสารจากธุรการขนส่ง / บัญชี", number: false},
      { key: "report_document.ready_collect_datetime", name: "วันเวลา", number: false},
      { key: "report_document.collecting_documents", name: "ผู้แทนรับเอกสารจาก Billing", number: false},
      { key: "report_document.collecting_datetime", name: "วันเวลา", number: false},
      { key: "report_document.document_return_documents", name: "ผู้แทนส่งเอกสารคืน Billing", number: false},
      { key: "report_document.document_return_datetime", name: "วันเวลา", number: false},
      { key: "report_document.document_received_documents", name: "พนักงาน Billing Scan รับเอกสารคืนจากผู้แทน", number: false},
      { key: "report_document.document_received_datetime", name: "วันเวลา", number: false},
      { key: "report_document.is_complete", name: "สรุป", number: false},
      { key: "billing_note", name: "หมายเหตุ", modal: true},
      { key: "report_document.previous_round_result", name: "สรุปของรอบก่อนหน้า", number: false},
      { key: "id", name: "Action", detail: true},
      // { key: "delivery_address", name: "กำลังส่งสินค้า", twoline: true},
      // { key: "delivery_address", name: "ส่งสินค้าแล้ว", twoline: true},
      // { key: "delivery_address", name: "billing รับเอกสารจากธุรการขนส่ง", twoline: true},
      // { key: "delivery_address", name: "ผู้แทนรับเอกสารจาก Billing", twoline: true},
      // { key: "delivery_address", name: "ผู้แทนส่งเอกสารคืน Billing", twoline: true},
      // { key: "delivery_address", name: "พนักงาน Billing Scan รับเอกสารคืนจากผู้แทน", twoline: true},
      // { key: "delivery_address", name: "สรุป", twoline: true},
      // { key: "delivery_address", name: "สรุปของรอบก่อนหน้า", detail: true},
      // { key: "delivery_address", name: "", expand: true},
    ],
    filters: [
      { label: "Billing Date", type: "DayPickerInput", key: "from_date" },
      { label: null, type: "DayPickerInput", key: "to_date" },
      { label: "Customer No.", type: "Input", key: "customer_code" },
      { label: "Customer Name", type: "Input", key: "customer_name" },
      { label: "Invoice No.", type: "Input", key: "serial" },
      { label: "Address", type: "Input", key: "delivery_address" },
      { label: "Scan Date", type: "DayPickerInput", key: "scan_from_date" },
      { label: null, type: "DayPickerInput", key: "scan_to_date" },
      { label: "Current Salesperson", type: "Input", key: "sale_name" },
      { buttonLabel: "Submit", type: "Submit", key: "submit", color: "green" },
    ],
    initialFilterParams: {
      from_date: dateFnsFormat(new Date(), FORMAT),
      to_date: dateFnsFormat(new Date(), FORMAT),
      scan_from_date: "",
      scan_to_date: "",
      customer_code: "",
      customer_name: "",
      serial: "",
      delivery_address: "",
      sale_name: "",
      page: 1,
      sortby: "",
      ascending: true,
    },
  },

  ManualEdit: {
    columns: [
      { key: "invoice.serial", name: "Invoice", number: false },
      { key: "invoice.customer_code", name: "Customer No.", number: false },
      { key: "invoice.customer_name", name: "Customer Name", number: false },
      { key: "datetime", name: "Edit Datetime", number: false },
      { key: "doc_name", name: "Edit Document", number: false },
      { key: "from_status_label", name: "Edit From", number: false },
      { key: "to_status_label", name: "Edit To", number: false },
      { key: "edited_by", name: "Edit By", number: false },
    ],
    filters: [{ label: null, type: "MonthPickerSubmit", key: "ym" }],
    initialFilterParams: {
      ym: currentYm,
      page: 1,
      sortby: "",
      ascending: true,
    },
  },

  InvoiceSummary: {
    columns: [
      { key: "serial", name: "Invoice No.", number: false },
      { key: "customer_name", name: "Customer Name", number: false },
      { key: "date", name: "Billing Date", number: false },
      { key: "status_label", name: "Status", number: false },
      { key: "billing_type_label", name: "Type", number: false },
      { key: "amount", name: "Total (Baht)", number: true },
      { key: "transport_name", name: "Shipper", number: false },
      { key: "id", name: "Details", number: false, detail: true },
    ],
    filters: [
      { label: "Invoice No.", type: "Input", key: "serial" },
      { label: "Customer No.", type: "Input", key: "customer_code" },
      { label: "Customer Name", type: "Input", key: "customer_name" },
      { label: "Sale", type: "Input", key: "sale_name" },
      { label: "From", type: "DayPickerInput", key: "from_date" },
      { label: "To", type: "DayPickerInput", key: "to_date" },
      { label: "Status", type: "Input", key: "status" },
      { label: "Type", type: "Input", key: "type" },
      { buttonLabel: "ค้นหา", type: "Submit", key: "submit", color: "blue" },
    ],
    initialFilterParams: {
      serial: "",
      customer_code: "",
      customer_name: "",
      sale_name: "",
      from_date: dateFnsFormat(new Date(), FORMAT),
      to_date: dateFnsFormat(new Date(), FORMAT),
      status: "",
      type: "",
      page: 1,
      sortby: "",
      ascending: true,
    },
  },
};

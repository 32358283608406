import { ta } from "date-fns/locale";
import React, { useState, useEffect } from "react";
import { Button, Dimmer, Loader, Modal, TextArea } from "semantic-ui-react";
import {
  GetExcelReport,
  CancelReport,
  ReportTracking,
} from "../../react-lib/apis/issara/apps/TRK/INVInvoiceReport";
import { downloadFile } from "../../react-lib/utils";

const ModProgressReport = (props: any) => {
  const [progress, setProgress] = useState(0);
  const [statusTask, setStatusTask] = useState("");
  const [textError, setTextError] = useState("");
  const [isLoadData, setIsLoadData] = useState(false);

  useEffect(() => {
    if (props.taskId && progress !== 100 && 
      !(statusTask.includes("FAILED") || 
    statusTask.includes("CANCELED") || 
    statusTask.includes("COMPLETED"))) {
      var task = setInterval(() => {
        getReportTrackingResult(props.taskId);
      }, 2000);
    }
    return () => clearInterval(task);
  }, [props.taskId]);

  useEffect(() => {
    if (statusTask === "FAILED") {
      setTextError("เกิดข้อผิดพลาด กรุณากด Cancel");
    }
    if(statusTask === "COMPLETED" && !isLoadData){
      setIsLoadData(true);
      handleOnComplete();
    }
  }, [statusTask, progress]);
  const getReportTrackingResult = async (taskId: number) => {
    const [res, err, net] = await ReportTracking.retrieve({
      params: {
        task_id: taskId,
      },
      apiToken: props.apiToken,
    });
    if (res) {
      setProgress(res.progress);
      setStatusTask(res.status);
    } else {
      console.log(err);
      setProgress(0);
      setStatusTask("");
      props.onClose();
    }
  };

  const handleOnCancel = async () => {
    const [res, err, net] = await CancelReport.patch({
      data: {
        task_id: props.taskId,
      },
      apiToken: props.apiToken,
    });
    if (res) {
      setProgress(0);
      setStatusTask("");
      setTextError("");
      setIsLoadData(false);
      props.onClose();
    } else {
      console.log(err);
      setProgress(0);
      setStatusTask("");
      setTextError("");
      setIsLoadData(false);
      props.onClose();
    }
  };

  const handleOnComplete = async () => {
    const [res, err, net] = await GetExcelReport.retrieve({
      params: {
        task_id: props.taskId,
      },
      apiToken: props.apiToken,
      extra: {
        responseType: "blob",
      },
    });
    if (res) {
      downloadFile(net);
      setProgress(0);
      setStatusTask("");
      setIsLoadData(false);
      props.onClose();
    } else {
      console.log(err, net);
      setProgress(0);
      setStatusTask("");
      setIsLoadData(false);
      props.onClose();
    }
  };

  return (
    <Modal
      open={props.open}
      dimmer
      style={{ width: "350px", padding: "10px" }}
      closeOnDimmerClick={false}
      onClose={() => props.onClose()}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {statusTask === "FAILED" ? (
          <h4 style={{ color: "red" }}>{textError}</h4>
        ) : (
          <>
            <h4>กำลังดำเนินการ</h4>
            <h4>{progress + "%"}</h4>
          </>
        )}
        <div>
          <Button
            color="red"
            content="Cancel"
            onClick={() => {
              handleOnCancel();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

ModProgressReport.defaultProps = {
  open: false,
  taskId: 0,
  apiToken: "",
  onClose: () => {},
};

export default React.memo(ModProgressReport);

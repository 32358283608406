import React, { JSXElementConstructor, useEffect, useState } from "react";
import AnalyticsLayout from "./AnalyticsLayout";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { DateUtils } from "react-day-picker";
import { Button, Popup } from "semantic-ui-react";
import "react-day-picker/lib/style.css";
import dateFnsFormat from "date-fns/format";
import dateFnsParse from "date-fns/parse";
import { ExportExcel } from "../../react-lib/apis/issara/apps/TRK/INVcommon";
import { downloadFile } from "../../react-lib/utils";
import ModProgressReport from "./ModProgressReport";
interface PlotData {
  name: string;
  sum_box_count: number;
  total_box_count: number;
  total_delay_count: number;
  total_success_count: number;
  total_transport_count: number;
  total_bill_count: number;
}

interface PlotParam {
  x: number;
  y: number;
  height: number;
  width: number;
  value: number;
}

interface PlotStyle {
  margin: number;
  width: number;
  height: number;
}

export default (props: {
  sub: string;
  login_employee: any;
  viewData: PlotData[];
  onEvent: ({ message, params }: { message: string; params: any }) => void;
  plotStyle: PlotStyle;
  filters: { start_date: string; end_date: string };
  searchFilter: any;
  apiToken?: string;
}) => {
  const [exportExcelLoading, setExportExcelLoading] = useState(false);
  const [exportAllLoading, setExportAllLoading] = useState(false);
  const [openProgress, setOpenProgress] = useState(false);
  const [taskId, setTaskId] = useState(0);

  const handleEvent = ({
    message,
    params
  }: {
    message: string;
    params: any;
  }) => {
    console.log(message, params);
    if (message === "clickMenu") {
      props.onEvent({ message, params });
    } else {
      props.onEvent({ message, params });
    }
  };

  const FORMAT = "dd/MM/yyyy";
  const FORMATEDITABLE = "dd/MM/y";

  const handleExportToExcel = async ({ exportAll } = { exportAll: false }) => {
    let typeCode = "SUMMARY_SHIPPING_IN";
    if (props.sub === "ShippingExternal") {
      typeCode = "SUMMARY_SHIPPING_EX";
    }
    if (exportAll) {
      setExportAllLoading(true);
    } else {
      setExportExcelLoading(true);
    }
    const [res, err, network] = await ExportExcel.retrieve({
      apiToken: props.apiToken,
      params: {
        type_code: typeCode,
        ...(props.searchFilter.start_date && {
          start_date: props.searchFilter.start_date
        }),
        ...(props.searchFilter.end_date && {
          end_date: props.searchFilter.end_date
        }),
        external: (props.sub === "ShippingExternal")
      },
      // extra: {
      //   responseType: "blob"
      // }
    });
    if (exportAll) {
      setExportAllLoading(false);
    } else {
      setExportExcelLoading(false);
    }
    if (res) {
      // downloadFile(network);
      setOpenProgress(true);
      setTaskId(res.task_id);
    } else {
    }
  };
  console.log(props.viewData, "viewData");
  const getMaxDataValue = () => {
    if (Object.keys(props.viewData).length === 0) {
      return 0;
    }
    var maxValue = Math.max(
      ...props.viewData.map(item => item.total_box_count),
      ...props.viewData.map(item => item.total_bill_count),
      ...props.viewData.map(item => item.total_delay_count),
      ...props.viewData.map(item => item.total_success_count),
      ...props.viewData.map(item => item.total_transport_count)
    );
    console.log(maxValue, "maxValue");
    return Math.ceil(maxValue / 10) * 10;
    // var maxDigit = maxValue.toString().length;
    // return Math.ceil(maxValue / 10 ** (maxDigit - 1)) * 10 ** (maxDigit - 1);
  };

  // const widthInnerRect = props.plotStyle.width - 20 * props.plotStyle.margin;
  var eachPersonWidth = 140
  if(props.viewData.length < 10){
    eachPersonWidth = 1300 / props.viewData.length
  }
  if(props.viewData.length === 0){
    eachPersonWidth = 1300
  }
  const widthInnerRect = eachPersonWidth * (props.viewData.length ? props.viewData.length : 1)
  // widthInnerRect / Object.keys(props.viewData).length;
  // if(eachPersonWidth < 140){
  //   eachPersonWidth = 140
  // }
  
  const y1InnerRect = 4 * props.plotStyle.margin;
  const x1InnerRect = 10 * props.plotStyle.margin;
  const heightInnerRect = props.plotStyle.height - 16 * props.plotStyle.margin;
  const gridHeight = heightInnerRect / 10;
  const maxDataValue = getMaxDataValue();

  console.log(props.viewData, "props.viewData");
  console.log(widthInnerRect, "widthInnerRect")
  return (
    <AnalyticsLayout
      sub={props.sub}
      onEvent={handleEvent}
      login_employee={props.login_employee}
      onExportToExcel={handleExportToExcel}
      exportExcelLoading={exportExcelLoading}
      exportAllLoading={exportAllLoading}
      onExportAll={() => handleExportToExcel({ exportAll: true })}
    >
      <div style={{ display: "flex" }}>
        <ModProgressReport
          apiToken={props.apiToken}
          taskId={taskId}
          open={openProgress}
          onClose={()=>{
            setOpenProgress(false);
            setTaskId(0);
          }}
        />
        <div style={{ margin: "20px", alignSelf: "center" }}>
          <h3>
            {" "}
            {props.sub === "ShippingExternal"
              ? "สถิติขนส่งประจำวันภายนอก"
              : "สถิติขนส่งประจำวันภายใน"}
          </h3>
        </div>
        <div style={{ margin: "20px", alignSelf: "center" }}>
          {" "}
          Billing Date{" "}
        </div>
        <div style={{ margin: "20px", alignSelf: "center" }}>
          <DayPickerInput
            formatDate={(date, format) => dateFnsFormat(date, format)}
            format={FORMATEDITABLE}
            value={props.filters.start_date}
            parseDate={(str, format) => {
              const parsed = dateFnsParse(str, format, new Date());
              if (DateUtils.isDate(parsed)) {
                return parsed;
              }
              return undefined;
            }}
            placeholder={"From..."}
            onDayChange={day => {
              console.log("day", day);
              props.onEvent({
                message: "choose",
                params: {
                  name: "fromDate",
                  value: day? dateFnsFormat(day, FORMATEDITABLE): ""
                }
              });
            }}
          />
        </div>
        <div style={{ margin: "20px", alignSelf: "center" }}>
          <DayPickerInput
            formatDate={(date, format) => dateFnsFormat(date, format)}
            format={FORMATEDITABLE}
            value={props.filters.end_date}
            parseDate={(str, format) => {
              const parsed = dateFnsParse(str, format, new Date());
              if (DateUtils.isDate(parsed)) {
                return parsed;
              }
              return undefined;
            }}
            placeholder={"To..."}
            onDayChange={day => {
              console.log("day", day);
              props.onEvent({
                message: "choose",
                params: {
                  name: "toDate",
                  value: day? dateFnsFormat(day, FORMATEDITABLE): ""
                }
              });
            }}
          />
        </div>
        <div style={{ margin: "20px", alignSelf: "center" }}>
          <Button
            color={"blue"}
            onClick={() => {
              props.onEvent({
                message: "clickButton",
                params: {
                  name: "search"
                }
              });
            }}
          >
            ค้นหา
          </Button>
        </div>
      </div>
      <div style={{ width: "100%", height: "700px", display: "flex", overflow: "auto", textAlign: "center", marginBottom: "20px" }}>
        <div
          style={{
            flex: 1,
            marginTop: "10px",
            justifyContent: "center",
            alignItems: "center",
            width: widthInnerRect + (20 * props.plotStyle.margin),
            marginLeft: "20px",
          }}
        >
          <svg
            width={widthInnerRect + (20 * props.plotStyle.margin)}
            height="100%"
            // viewBox={`0 0 ${props.plotStyle.width} ${props.plotStyle.height}`}
          >
            <rect
              // x={props.plotStyle.margin}
              y={props.plotStyle.margin}
              width={widthInnerRect + (20 * props.plotStyle.margin) }
              // width={props.plotStyle.width - 2 * props.plotStyle.margin}
              height={props.plotStyle.height - 2 * props.plotStyle.margin}
              style={{ fillOpacity: 0, stroke: "grey" }}
            />
            <rect
              x={x1InnerRect}
              y={y1InnerRect}
              width={widthInnerRect}
              height={heightInnerRect}
              style={{ fillOpacity: 0, stroke: "grey" }}
            />
            <g>
              <g>
                <text y={y1InnerRect + gridHeight} x={x1InnerRect - 50}>
                  {Math.ceil(maxDataValue * 0.9)}
                </text>
                <line
                  x1={x1InnerRect - 10}
                  y1={y1InnerRect + gridHeight}
                  x2={x1InnerRect + widthInnerRect}
                  y2={y1InnerRect + gridHeight}
                  stroke="grey"
                />
              </g>
              <g>
                <text y={y1InnerRect + gridHeight * 2} x={x1InnerRect - 50}>
                  {Math.ceil(maxDataValue * 0.8)}
                </text>
                <line
                  x1={x1InnerRect - 10}
                  y1={y1InnerRect + gridHeight * 2}
                  x2={x1InnerRect + widthInnerRect}
                  y2={y1InnerRect + gridHeight * 2}
                  stroke="grey"
                />
              </g>
              <g>
                <text y={y1InnerRect + gridHeight * 3} x={x1InnerRect - 50}>
                  {Math.ceil(maxDataValue * 0.7)}
                </text>
                <line
                  x1={x1InnerRect - 10}
                  y1={y1InnerRect + gridHeight * 3}
                  x2={x1InnerRect + widthInnerRect}
                  y2={y1InnerRect + gridHeight * 3}
                  stroke="grey"
                />
              </g>
              <g>
                <text y={y1InnerRect + gridHeight * 4} x={x1InnerRect - 50}>
                  {Math.ceil(maxDataValue * 0.6)}
                </text>
                <line
                  x1={x1InnerRect - 10}
                  y1={y1InnerRect + gridHeight * 4}
                  x2={x1InnerRect + widthInnerRect}
                  y2={y1InnerRect + gridHeight * 4}
                  stroke="grey"
                />
              </g>
              <g>
                <text y={y1InnerRect + gridHeight * 5} x={x1InnerRect - 50}>
                  {Math.ceil(maxDataValue * 0.5)}
                </text>
                <line
                  x1={x1InnerRect - 10}
                  y1={y1InnerRect + gridHeight * 5}
                  x2={x1InnerRect + widthInnerRect}
                  y2={y1InnerRect + gridHeight * 5}
                  stroke="grey"
                />
              </g>
              <g>
                <text y={y1InnerRect + gridHeight * 6} x={x1InnerRect - 50}>
                  {Math.ceil(maxDataValue * 0.4)}
                </text>
                <line
                  x1={x1InnerRect - 10}
                  y1={y1InnerRect + gridHeight * 6}
                  x2={x1InnerRect + widthInnerRect}
                  y2={y1InnerRect + gridHeight * 6}
                  stroke="grey"
                />
              </g>
              <g>
                <text y={y1InnerRect + gridHeight * 7} x={x1InnerRect - 50}>
                  {Math.ceil(maxDataValue * 0.3)}
                </text>
                <line
                  x1={x1InnerRect - 10}
                  y1={y1InnerRect + gridHeight * 7}
                  x2={x1InnerRect + widthInnerRect}
                  y2={y1InnerRect + gridHeight * 7}
                  stroke="grey"
                />
              </g>
              <g>
                <text y={y1InnerRect + gridHeight * 8} x={x1InnerRect - 50}>
                  {Math.ceil(maxDataValue * 0.2)}
                </text>
                <line
                  x1={x1InnerRect - 10}
                  y1={y1InnerRect + gridHeight * 8}
                  x2={x1InnerRect + widthInnerRect}
                  y2={y1InnerRect + gridHeight * 8}
                  stroke="grey"
                />
              </g>
              <g>
                <text y={y1InnerRect + gridHeight * 9} x={x1InnerRect - 50}>
                  {Math.ceil(maxDataValue * 0.1)}
                </text>
                <line
                  x1={x1InnerRect - 10}
                  y1={y1InnerRect + gridHeight * 9}
                  x2={x1InnerRect + widthInnerRect}
                  y2={y1InnerRect + gridHeight * 9}
                  stroke="grey"
                />
              </g>
            </g>
            {props.viewData.map((item, index) => {
              var xBar1 =
                x1InnerRect + eachPersonWidth / 7 + eachPersonWidth * index;
              var barWidth = eachPersonWidth / 7;
              return (
                <g key={index}>
                  <rect height={25} width={25+((item.total_transport_count.toString().length-1)*5)} x={xBar1+barWidth/3} y={
                          y1InnerRect +
                          heightInnerRect -
                          (item.total_transport_count * heightInnerRect) /
                            maxDataValue - 30
                        } rx={5} style={{ fill:"rgb(0,0,0,0.87)"}}/>
                  <text x={xBar1+barWidth/2.5} y={
                          y1InnerRect + heightInnerRect -
                          (item.total_transport_count * heightInnerRect) /
                            maxDataValue - 10
                  } font-size="14" fill="#fff" >{item.total_transport_count}</text>
                  {/* <Popup
                    inverted
                    size="mini"
                    basic
                    trigger={ */}
                      <rect
                        x={xBar1}
                        y={
                          y1InnerRect +
                          heightInnerRect -
                          (item.total_transport_count * heightInnerRect) /
                            maxDataValue
                        }
                        width={barWidth}
                        height={
                          (item.total_transport_count * heightInnerRect) /
                          maxDataValue
                        }
                        style={{ fill: "#81DB66" }}
                      />
                    {/* }
                    content={item.total_transport_count}
                  /> */}
                  <rect height={25} width={25+((item.total_box_count.toString().length-1)*5)} 
                  x={xBar1+barWidth+barWidth/3} y={
                          y1InnerRect +
                          heightInnerRect -
                          (item.total_box_count * heightInnerRect) /
                            maxDataValue - 30
                        } rx={5} style={{ fill:"rgb(0,0,0,0.87)"}}/>
                  <text x={xBar1+barWidth+barWidth/2.5} y={
                          y1InnerRect + heightInnerRect -
                          (item.total_box_count * heightInnerRect) /
                            maxDataValue - 10
                  } font-size="14" fill="#fff" >{item.total_box_count}</text>
                  {/* <Popup
                    inverted
                    size="mini"
                    basic
                    trigger={ */}
                      <rect
                        x={xBar1 + barWidth}
                        y={
                          y1InnerRect +
                          heightInnerRect -
                          (item.total_box_count * heightInnerRect) /
                            maxDataValue
                        }
                        width={barWidth}
                        height={
                          (item.total_box_count * heightInnerRect) /
                          maxDataValue
                        }
                        style={{ fill: "#F78356" }}
                      />
                    {/* }
                    content={item.total_box_count}
                  /> */}
                  <rect height={25} width={25+((item.total_bill_count.toString().length-1)*5)} 
                  x={xBar1+2*barWidth+barWidth/3} y={
                          y1InnerRect +
                          heightInnerRect -
                          (item.total_bill_count * heightInnerRect) /
                            maxDataValue - 30
                        } rx={5} style={{ fill:"rgb(0,0,0,0.87)"}}/>
                  <text x={xBar1+2*barWidth+barWidth/2.5} y={
                          y1InnerRect + heightInnerRect -
                          (item.total_bill_count * heightInnerRect) /
                            maxDataValue - 10
                  } font-size="14" fill="#fff" >{item.total_bill_count}</text>
                  {/* <Popup
                    inverted
                    size="mini"
                    basic
                    trigger={ */}
                      <rect
                        x={xBar1 + 2 * barWidth}
                        y={
                          y1InnerRect +
                          heightInnerRect -
                          (item.total_bill_count * heightInnerRect) /
                            maxDataValue
                        }
                        width={barWidth}
                        height={
                          (item.total_bill_count * heightInnerRect) /
                          maxDataValue
                        }
                        style={{ fill: "#EEEF2D" }}
                      />
                    {/* }
                    content={item.total_bill_count}
                  /> */}
                  <rect height={25} width={25+((item.total_success_count.toString().length-1)*5)} 
                  x={xBar1+3*barWidth+barWidth/3} y={
                          y1InnerRect +
                          heightInnerRect -
                          (item.total_success_count * heightInnerRect) /
                            maxDataValue - 30
                        } rx={5} style={{ fill:"rgb(0,0,0,0.87)"}}/>
                  <text x={xBar1+3*barWidth+barWidth/2.5} y={
                          y1InnerRect + heightInnerRect -
                          (item.total_success_count * heightInnerRect) /
                            maxDataValue - 10
                  } font-size="14" fill="#fff" >{item.total_success_count}</text>
                  {/* <Popup
                    inverted
                    size="mini"
                    basic
                    trigger={ */}
                      <rect
                        x={xBar1 + 3 * barWidth}
                        y={
                          y1InnerRect +
                          heightInnerRect -
                          (item.total_success_count * heightInnerRect) /
                            maxDataValue
                        }
                        width={barWidth}
                        height={
                          (item.total_success_count * heightInnerRect) /
                          maxDataValue
                        }
                        style={{ fill: "#4BBFD1" }}
                      />
                    {/* }
                    content={item.total_success_count}
                  /> */}
                  <rect height={25} width={25+((item.total_delay_count.toString().length-1)*5)} 
                  x={xBar1+4*barWidth+barWidth/3} y={
                          y1InnerRect +
                          heightInnerRect -
                          (item.total_delay_count * heightInnerRect) /
                            maxDataValue - 30
                        } rx={5} style={{ fill:"rgb(0,0,0,0.87)"}}/>
                  <text x={xBar1+4*barWidth+barWidth/2.5} y={
                          y1InnerRect + heightInnerRect -
                          (item.total_delay_count * heightInnerRect) /
                            maxDataValue - 10
                  } font-size="14" fill="#fff" >{item.total_delay_count}</text>
                  {/* <Popup
                    inverted
                    size="mini"
                    basic
                    trigger={ */}
                      <rect
                        x={xBar1 + 4 * barWidth}
                        y={
                          y1InnerRect +
                          heightInnerRect -
                          (item.total_delay_count * heightInnerRect) /
                            maxDataValue
                        }
                        width={barWidth}
                        height={
                          (item.total_delay_count * heightInnerRect) /
                          maxDataValue
                        }
                        style={{ fill: "#A337B3" }}
                      />
                    {/* }
                    content={item.total_delay_count}
                  /> */}
                  <foreignObject
                    key={index}
                    x={x1InnerRect + eachPersonWidth * index}
                    y={
                      props.plotStyle.height - 12 * props.plotStyle.margin + 30
                    }
                    width={eachPersonWidth}
                    height="50px"
                    style={{
                      fill: "black",
                      fontSize: "20px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textAlign: "center"
                    }}
                  >
                    <span>{item.name}</span>
                  </foreignObject>
                </g>
              );
            })}
            <g style={{ transform: "translate(300px, 0px)" }}>
              <rect
                width="25px"
                height="25px"
                style={{ fill: "#81DB66" }}
                x={x1InnerRect}
                y={props.plotStyle.height - y1InnerRect - 20}
              />
              <text
                style={{ fontSize: "20px" }}
                x={x1InnerRect + 30}
                y={props.plotStyle.height - y1InnerRect}
              >
                จำนวนสถานที่ส่ง
              </text>
              <rect
                width="25px"
                height="25px"
                style={{ fill: "#F78356" }}
                x={x1InnerRect + 200}
                y={props.plotStyle.height - y1InnerRect - 20}
              />
              <text
                style={{ fontSize: "20px" }}
                x={x1InnerRect + 230}
                y={props.plotStyle.height - y1InnerRect}
              >
                จำนวนกล่อง
              </text>
              <rect
                width="25px"
                height="25px"
                style={{ fill: "#EEEF2D" }}
                x={x1InnerRect + 360}
                y={props.plotStyle.height - y1InnerRect - 20}
              />
              <text
                style={{ fontSize: "20px" }}
                x={x1InnerRect + 390}
                y={props.plotStyle.height - y1InnerRect}
              >
                จำนวนบิล
              </text>
              <rect
                width="25px"
                height="25px"
                style={{ fill: "#4BBFD1" }}
                x={x1InnerRect + 500}
                y={props.plotStyle.height - y1InnerRect - 20}
              />
              <text
                style={{ fontSize: "20px" }}
                x={x1InnerRect + 530}
                y={props.plotStyle.height - y1InnerRect}
              >
                ส่งได้
              </text>
              <rect
                width="25px"
                height="25px"
                style={{ fill: "#A337B3" }}
                x={x1InnerRect + 600}
                y={props.plotStyle.height - y1InnerRect - 20}
              />
              <text
                style={{ fontSize: "20px" }}
                x={x1InnerRect + 630}
                y={props.plotStyle.height - y1InnerRect}
              >
                ค้างส่ง
              </text>
            </g>
          </svg>
        </div>
      </div>
    </AnalyticsLayout>
  );
};

import axios from 'axios'
import { to } from '../../../../utils'
import BaseService from  '../../../services/BaseService'
import invSettings from '../../../../../config/config';

export const ReportTracking : 
{
  retrieve: ({params, apiToken, extra}:
             {
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
  retrieve: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${invSettings.API_HOST}/apis/TRK/excel-report-tracking/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export const CancelReport: 
{
  patch: ({pk, params, data, apiToken, extra}:
             {
                pk?: any,
                params?: any,
                data?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
    patch: async ({pk, params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.patch(`${invSettings.API_HOST}/apis/TRK/cancel-excel-report/`,
      {  ...data,
        params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export const GetExcelReport: 
{
    retrieve: ({params, apiToken, extra}:
             {
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
} = 
{
    retrieve: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken) {
        base.client.defaults.headers["Authorization"] = "Token " + apiToken
    }
    const result = await to(base.client.get(`${invSettings.API_HOST}/apis/TRK/get-excel-report/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}